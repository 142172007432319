import React from 'react'
import { Container, Form, Input } from 'semantic-ui-react'
import { useDocumentQuery, useRenameDocumentMutation } from 'generated/graphql'
import { hideModal } from 'store/modalStore'
import useTranslate from 'hooks/useTranslate'
import Error from 'components/common/Error'
import { Controller, useForm } from 'react-hook-form'
import DocumentStore, { LacoDocument } from 'store/documentStore'
import { useStore } from 'laco-react'

export default function RenameDocument() {
  const translate = useTranslate()
  const { currentFolder, selectedDocumentInFolder }: LacoDocument = useStore(
    DocumentStore
  )
  const selected = selectedDocumentInFolder[currentFolder]
  const [renameDocument, { error, loading }] = useRenameDocumentMutation()
  const { data } = useDocumentQuery({
    variables: { id: selected as string },
  })
  const { handleSubmit, control, formState } = useForm({
    mode: 'onChange',
  })
  const oldName: any = data?.document?.name

  async function onSubmit({ name }: any) {
    const newName =
      data?.document?.type === 'File'
        ? name + '.' + data?.document?.name.split('.').reverse()[0]
        : name

    try {
      await renameDocument({
        variables: { name: newName, id: selected as string },
      })

      hideModal()
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)} loading={loading}>
        <Form.Field>
          <Controller
            as={<Input placeholder={translate('name')} />}
            rules={{
              required: true,
            }}
            name="name"
            control={control}
            defaultValue={
              oldName.includes('.')
                ? oldName.substring(0, oldName.lastIndexOf('.'))
                : oldName
            }
            // oldName.split('.').reverse()[(oldName.match(/\./g) || []).length]
            autoFocus
          />
        </Form.Field>

        <Form.Button
          color="blue"
          disabled={!formState.isValid}
          fluid
          size="big"
        >
          {translate('rename-document')}
        </Form.Button>
      </Form>
      <Error error={error}></Error>
    </Container>
  )
}
