import { ROOT_DOCUMENT_ID } from 'constants/document'

export const ROUTES = {
  LOGIN: '/login',
  DOCUMENTS: '/folders',
  HOME: `/folders/${ROOT_DOCUMENT_ID}`,
  USERS: '/users',
  USERGROUPS: '/usergroups',
  USERGROUP: '/usergroup',
  LOCALIZATION: '/localization',
  LOGS: '/logs',
}
