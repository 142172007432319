import React, { useState, useEffect } from 'react'
import { Container, Button, Loader, Tab } from 'semantic-ui-react'
import useTranslate from 'hooks/useTranslate'
import {
  TagFragment,
  useSetTagsOnDocumentMutation,
  useOwnTagsQuery,
  useDocumentQuery,
} from 'generated/graphql'
import DataTable, { IDataTableColumn } from 'react-data-table-component'
import { hideModal } from 'store/modalStore'
import DocumentStore, { LacoDocument } from 'store/documentStore'
import { useStore } from 'laco-react'
import Tag from 'components/common/Tag'
import OwnTags from 'components/OwnTags'

const tagColumns: (translate: Function) => IDataTableColumn<TagFragment>[] = (
  translate
) => [
  {
    name: translate('tag'),
    cell: function TagCell(row) {
      return <Tag label key={row.id} tag={row}></Tag>
    },
  },
]

export default function SetTagsOnDocument() {
  const translate = useTranslate()
  const { currentFolder, selectedDocumentInFolder }: LacoDocument = useStore(
    DocumentStore
  )
  const selectedDocument = selectedDocumentInFolder[currentFolder]
  const [setTagsOnDocument] = useSetTagsOnDocumentMutation()

  // tags which are already stored
  const { data: ownTagsQuery, loading } = useOwnTagsQuery({
    // todo: improve, use cache
    fetchPolicy: 'network-only',
  })
  const {
    data: documentQuery,
    loading: documentQueryLoading,
  } = useDocumentQuery({
    variables: { id: selectedDocument as string },
    fetchPolicy: 'network-only',
  })

  // show already selected tags
  const [selectedTags, setSelectedTags] = useState<TagFragment['id'][]>([])
  const [tags, setTags] = useState<TagFragment[]>([])

  useEffect(() => {
    if (documentQueryLoading || !ownTagsQuery || !documentQuery) {
      return
    }
    // We know the tags already selected
    setSelectedTags([
      ...(documentQuery?.document.tags.map((tag) => tag.id) as string[]),
    ])
    setTags(ownTagsQuery?.userTags)
  }, [documentQueryLoading, documentQuery, ownTagsQuery])

  function handleSetingTags() {
    setTagsOnDocument({
      variables: {
        documentId: selectedDocument as string,
        tagIds: selectedTags,
      },
    })
    hideModal()
  }

  return (
    <Container>
      <Tab
        menu={{ pointing: true, secondary: true }}
        panes={[
          {
            menuItem: translate('set-document-tags'),
            render: function ManageUserTagsPane() {
              return (
                <>
                  <DataTable
                    data={tags as TagFragment[]}
                    columns={tagColumns(translate)}
                    progressPending={loading || documentQueryLoading}
                    progressComponent={Loader}
                    noHeader
                    highlightOnHover
                    selectableRowsHighlight
                    selectableRows
                    selectableRowSelected={(row) => {
                      return selectedTags.includes(row.id)
                    }}
                    onSelectedRowsChange={(selectedRows) => {
                      setSelectedTags(
                        selectedRows.selectedRows.map((row) => row.id)
                      )
                    }}
                  />

                  <Button fluid primary onClick={handleSetingTags}>
                    {translate('set-tags')}
                  </Button>
                </>
              )
            },
          },
          {
            menuItem: translate('manage-user-tags'),
            render: function ManageOwnTags() {
              return <OwnTags></OwnTags>
            },
          },
        ]}
      ></Tab>
    </Container>
  )
}
