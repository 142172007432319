import React, { useState } from 'react'
import {
  DocumentType,
  useFindDocumentsLazyQuery,
  useOwnTagsQuery,
} from 'generated/graphql'
import { hideModal } from 'store/modalStore'
import useTranslate from 'hooks/useTranslate'
import { selectDocumentInFolder } from 'store/documentStore'
import { useHistory } from 'react-router-dom'
import { ROUTES } from 'components/Routes'
import useLazyQuery from 'hooks/useLazyQuery'
import { DocumentDocument } from 'generated/graphql'
import DataTable from 'react-data-table-component'
import { Form, Input, Button, Select, Checkbox } from 'semantic-ui-react'
import { Controller, useForm } from 'react-hook-form'
import MimeImage from 'components/common/MimeImage'
import fileSize from 'filesize'
import Tag from 'components/common/Tag'
import { pollInterval } from 'constants/pollInterval'

export default function JumpToDocument() {
  const translate = useTranslate()
  const documentQuery = useLazyQuery(DocumentDocument)

  const defaultPaginationPerPage = 15

  const [lazyFetch, { data, loading, variables }] = useFindDocumentsLazyQuery({
    fetchPolicy: 'network-only',
    pollInterval: pollInterval,
    variables: { count: defaultPaginationPerPage, page: 0 },
  })

  const { handleSubmit, control, register, setValue } = useForm({
    mode: 'onChange',
  })

  const history = useHistory()
  const { data: ownTagsQuery, loading: tagsLoading } = useOwnTagsQuery()

  const [paginationReset, setPaginationReset] = useState(false)

  const resetPagination = async () => {
    await lazyFetch({ variables: { ...variables, page: 0 } })
    setPaginationReset((val) => !val)
  }

  const handleRowsPerPageChange = async (newPerPage, page) => {
    await lazyFetch({
      variables: { ...variables, count: newPerPage, page: page - 1 },
    })
  }
  const handlePageChange = (page) =>
    lazyFetch({ variables: { ...variables, page: page - 1 } })

  async function jumpToDocument(id: string) {
    try {
      const { data } = await documentQuery({
        id,
      })
      if (data?.document?.type === DocumentType.Folder) {
        hideModal()
        history.push(`${ROUTES.DOCUMENTS}/${data.document.id}`)
      }
      if (data?.document?.type === DocumentType.File) {
        history.push(`${ROUTES.DOCUMENTS}/${data.document.parent?.id}`)
        selectDocumentInFolder(data.document.id, data.document.parent.id)
        hideModal()
      }
    } catch (err) {
      console.error(err)
    }
  }

  const tags = (ownTagsQuery?.userTags || []).map((tag) => ({
    key: tag.id,
    value: tag.id,
    text: <Tag label tag={tag} />,
  }))

  React.useEffect(() => {
    register('tag') // custom register Antd input
    register('locked')
  }, [register])

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleTagChange = (_, data) => {
      resetPagination()
      setValue('tag', data.value)
      handleSubmit(onSubmit)()
    }

    const handleCheckBoxChange = (_, data) => {
      setValue('locked', data.checked)
      handleSubmit(onSubmit)()
    }
    async function onSubmit(formValues: any) {
      try {
        lazyFetch({
          variables: {
            ...variables,
            name: formValues.name,
            tagId: formValues.tag,
            isLocked: formValues.locked,
          },
        })
      } catch (err) {
        console.error(err)
      }
    }
    return (
      <Form
        onSubmit={handleSubmit(onSubmit)}
        onBlur={handleSubmit(onSubmit)}
        className="find-document-form"
      >
        <Form.Field>
          <Controller
            as={<Input placeholder={translate('name')} icon="search" />}
            name="name"
            control={control}
            defaultValue=""
          />
        </Form.Field>
        <Form.Field>
          <Select
            loading={tagsLoading}
            name="tag"
            clearable
            options={tags}
            onChange={handleTagChange}
            placeholder={translate('select-tag')}
          ></Select>
        </Form.Field>
        <Form.Field>
          <Checkbox
            icon="locked"
            label={translate('show-only-locked-by-user')}
            onChange={handleCheckBoxChange}
            name="locked"
          />
        </Form.Field>
      </Form>
    )
  }, [
    translate,
    lazyFetch,
    handleSubmit,
    setValue,
    control,
    tags,
    variables,
    tagsLoading,
    resetPagination,
  ])

  const documentsBasicColumns = [
    {
      name: translate('type'),
      selector: 'mimeType',
      // eslint-disable-next-line react/display-name
      format: (row) => <MimeImage row={row}></MimeImage>,
    },
    {
      name: translate('name'),
      selector: 'name',
    },
    {
      name: translate('size'),
      selector: 'size',
      format: (row) =>
        row.entry?.size ? fileSize(parseInt(row.entry?.size)) : '',
    },
    {
      name: translate('owner'),
      selector: 'owner.name',
    },
    {
      name: translate('jump-to-document'),
      cell: function DownloadButton(row) {
        return (
          <Button
            color="blue"
            compact
            onClick={() => {
              jumpToDocument(row.id)
            }}
          >
            {translate('jump-to-document')}
          </Button>
        )
      },
    },
  ]

  return (
    <DataTable
      style={{ overflowY: 'visible', overflowX: 'scroll', minHeight: '100%' }}
      highlightOnHover
      data={data?.findDocuments.documents || []}
      onRowDoubleClicked={(row) => jumpToDocument(row.id)}
      columns={documentsBasicColumns}
      subHeaderComponent={subHeaderComponentMemo}
      onChangeRowsPerPage={handleRowsPerPageChange}
      onChangePage={handlePageChange}
      pagination
      paginationServer
      paginationPerPage={defaultPaginationPerPage}
      paginationRowsPerPageOptions={[15, 50, 100, 150, 200]}
      paginationTotalRows={data?.findDocuments.total}
      paginationResetDefaultPage={paginationReset}
      paginationComponentOptions={{
        rowsPerPageText: translate('rows-per-page'),
        rangeSeparatorText: translate('of-operator-pagination'),
      }}
      noHeader
      subHeader
      progressPending={loading}
    ></DataTable>
  )
}
