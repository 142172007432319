import { Store } from 'laco'
import { UserFragment } from 'generated/graphql'

export type LacoUsers = {
  selected: UserFragment['id'] | null
}

const initialState: LacoUsers = {
  selected: null,
}

const UsersStore = new Store(initialState, 'UsersStore')

export default UsersStore

export const selectUser = (userId: UserFragment['id'] | null) =>
  UsersStore.set(() => ({
    selected: userId,
  }))
