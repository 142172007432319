import React, { useState } from 'react'
import { Container, Form, Input } from 'semantic-ui-react'
import { DocumentType } from 'generated/graphql'
import { hideModal } from 'store/modalStore'
import useTranslate from 'hooks/useTranslate'
import { Controller, useForm } from 'react-hook-form'
import { selectDocumentInFolder } from 'store/documentStore'
import { useHistory } from 'react-router-dom'
import { ROUTES } from 'components/Routes'
import useLazyQuery from 'hooks/useLazyQuery'
import { DocumentDocument } from 'generated/graphql'
import Error from '../../common/Error'

export default function JumpToDocument() {
  const translate = useTranslate()
  const documentQuery = useLazyQuery(DocumentDocument)
  const [error, setError] = useState<
    { name?: string; message: string } | undefined
  >(undefined)

  const { handleSubmit, control, formState } = useForm({
    mode: 'onChange',
  })
  const history = useHistory()

  async function onSubmit({ id }: any) {
    try {
      const { data } = await documentQuery({
        id,
      })
      if (!data.document) {
        setError(() => ({ message: translate('no-document-found') }))
        return
      }
      if (data?.document?.type === DocumentType.Folder) {
        hideModal()
        history.push(`${ROUTES.DOCUMENTS}/${data.document.id}`)
      }
      if (data?.document?.type === DocumentType.File) {
        history.push(`${ROUTES.DOCUMENTS}/${data.document.parent?.id}`)
        selectDocumentInFolder(data.document.id, data.document.parent.id)
        hideModal()
      }
    } catch (err) {
      console.error(err)
      setError(err)
    }
  }
  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Field>
          <Controller
            as={<Input placeholder={translate('id')} />}
            rules={{
              required: true,
            }}
            name="id"
            control={control}
            defaultValue=""
            autoFocus
          />
        </Form.Field>

        <Form.Button
          color="blue"
          disabled={!formState.isValid}
          fluid
          size="big"
        >
          {translate('jump-to-document')}
        </Form.Button>
      </Form>
      <Error error={error}></Error>
    </Container>
  )
}
