import client from 'apolloClient'
import { RecordLogoutDocument, FindDocumentsDocument } from 'generated/graphql'

declare global {
  interface Window {
    getNumberOfLockedFiles: Function
    handleLogout: Function
  }
}

window.getNumberOfLockedFiles = async function () {
  if (!sessionStorage.getItem('jwt')) {
    return 0
  }

  const { data } = await client.query({
    query: FindDocumentsDocument,
    fetchPolicy: 'network-only',
    variables: {
      isLocked: true,
    },
  })

  return data.findDocuments.total
}

window.handleLogout = async function () {
  if (!sessionStorage.getItem('jwt')) {
    return
  }

  const { data } = await client.mutate({
    mutation: RecordLogoutDocument,
  })

  return data
}
