import React from 'react'
import { Modal as SModal, ModalProps, Icon } from 'semantic-ui-react'
import useTranslate from 'hooks/useTranslate'

import './modal.scss'

interface Props extends Omit<ModalProps, 'size' | 'closeOnDimmerClick'> {
  title?: string
  actions?: React.ComponentType<any>[]
}

const Modal = ({ children, title, actions, ...props }: Props) => {
  const translate = useTranslate()
  return (
    <SModal
      size="large"
      closeOnDimmerClick={true}
      closeIcon={<Icon name="close"></Icon>}
      {...props}
    >
      {title && <SModal.Header>{translate(title)}</SModal.Header>}
      {children && (
        <SModal.Content className="modal-content">{children}</SModal.Content>
      )}
      {actions && (
        <SModal.Actions>
          {actions.map((element, i) =>
            React.createElement(element, { key: i })
          )}
        </SModal.Actions>
      )}
    </SModal>
  )
}

export default Modal
