import React, { useState } from 'react'

import DataTable from 'react-data-table-component'

import {
  UserTableRowFragment,
  useDocumentAccessesQuery,
  useUsersQuery,
  useModifyDocumentUserAccessesMutation,
} from 'generated/graphql'
import { createBoolCellWithSelector } from 'components/common/table/BoolCell'
import { Loader, Button } from 'semantic-ui-react'
import useTranslate from 'hooks/useTranslate'
import { hideModal } from 'store/modalStore'

interface UserAccessTableRowFragment extends UserTableRowFragment {
  isRead: boolean
  isWrite: boolean
  isControl: boolean
}

export default function UserPermissionTable({
  documentId,
  recursive,
}: {
  documentId: string
  recursive: boolean
}) {
  const translate = useTranslate()
  const [readIds, setReadIds] = useState([] as string[])
  const [writeIds, setWriteIds] = useState([] as string[])
  const [controlIds, setControlIds] = useState([] as string[])

  const { loading: accessesLoading } = useDocumentAccessesQuery({
    skip: recursive,
    variables: {
      documentId,
    },
    onCompleted(res) {
      if (recursive) {
        return
      }

      const { documentAccesses } = res

      const result = documentAccesses.reduce(
        (acc, curr) => {
          if (!curr.user) {
            return acc
          }

          return {
            readIds: [...acc.readIds, ...(curr.isRead ? [curr.user.id] : [])],
            writeIds: [
              ...acc.writeIds,
              ...(curr.isWrite ? [curr.user.id] : []),
            ],
            controlIds: [
              ...acc.controlIds,
              ...(curr.isControl ? [curr.user.id] : []),
            ],
          }
        },
        {
          readIds: [] as string[],
          writeIds: [] as string[],
          controlIds: [] as string[],
        }
      )

      setReadIds(result.readIds)
      setWriteIds(result.writeIds)
      setControlIds(result.controlIds)
    },
    fetchPolicy: 'network-only',
  })

  const {
    data: { users } = { users: [] },
    loading: usersLoading,
  } = useUsersQuery()

  const [submitAcion] = useModifyDocumentUserAccessesMutation({
    variables: {
      documentId,
      readIds,
      writeIds,
      controlIds,
      recursive,
    },
    onCompleted() {
      hideModal()
    },
  })

  const loading = accessesLoading || usersLoading

  if (loading) {
    return null
  }

  return (
    <>
      <DataTable
        data={
          users
            .filter((u) => !u.isAdmin)
            .map((x) => ({
              ...x,
              isRead: readIds.includes(x.id),
              isWrite: writeIds.includes(x.id),
              isControl: controlIds.includes(x.id),
            })) as UserAccessTableRowFragment[]
        }
        columns={[
          {
            name: translate('name'),
            selector: 'name',
            sortable: true,
            grow: 3,
          },
          {
            name: translate('isRead'),
            selector: 'isRead',
            sortable: true,
            format: createBoolCellWithSelector(
              'isRead',
              (row) => {
                if (row.isRead) {
                  setReadIds((ids) => ids.filter((id) => id !== row.id))
                } else {
                  setReadIds((ids) => ids.concat([row.id]))
                }
              },
              (row) => writeIds.includes(row.id) || controlIds.includes(row.id)
            ),
          },
          {
            name: translate('isWrite'),
            selector: 'isWrite',
            sortable: true,
            format: createBoolCellWithSelector('isWrite', (row) => {
              if (row.isWrite) {
                setWriteIds((ids) => ids.filter((id) => id !== row.id))
              } else {
                setWriteIds((ids) => ids.concat([row.id]))
                setReadIds((ids) => ids.concat([row.id]))
              }
            }),
          },
          {
            name: translate('isControl'),
            selector: 'isControl',
            sortable: true,
            format: createBoolCellWithSelector('isControl', (row) => {
              if (row.isControl) {
                setControlIds((ids) => ids.filter((id) => id !== row.id))
              } else {
                setControlIds((ids) => ids.concat([row.id]))
                setReadIds((ids) => ids.concat([row.id]))
              }
            }),
          },
        ]}
        progressPending={loading}
        progressComponent={Loader}
        noHeader
        highlightOnHover
        pagination
        paginationComponentOptions={{
          rowsPerPageText: translate('rows-per-page'),
          rangeSeparatorText: translate('of-operator-pagination'),
        }}
      />

      <Button color="blue" fluid size="big" onClick={() => submitAcion()}>
        {translate('apply-changes')}
      </Button>
    </>
  )
}
