import React from 'react'

import DataTable, { IDataTableColumn } from 'react-data-table-component'

import {
  useLocalizationsQuery,
  Language,
  LocalizationFragment,
  useChangeTranslationMutation,
} from 'generated/graphql'
import { Loader, Input, Form } from 'semantic-ui-react'
import { useForm, Controller } from 'react-hook-form'
import useTranslate from 'hooks/useTranslate'
import { pollInterval } from 'constants/pollInterval'

type LocalizationRow = {
  key: LocalizationFragment['key']
  russian?: LocalizationFragment['value']
  english?: LocalizationFragment['value']
}

function InputCell({
  row,
  language,
}: {
  row: LocalizationRow
  language: Language
}) {
  const { handleSubmit, control } = useForm({ mode: 'onBlur' })
  const [changeTranslation] = useChangeTranslationMutation()
  function onSend({ value }) {
    changeTranslation({ variables: { language, key: row.key, value } })
  }
  return (
    <Form
      style={{ width: '320px' }}
      onSubmit={handleSubmit(onSend)}
      onBlur={handleSubmit(onSend)}
    >
      <Form.Field>
        <Controller
          name="value"
          as={Input}
          control={control}
          rules={{ required: true }}
          defaultValue={language === Language.En ? row.english : row.russian}
        ></Controller>
      </Form.Field>
    </Form>
  )
}

function createInputCellWithLanguage(language: Language, row: LocalizationRow) {
  return <InputCell language={language} row={row}></InputCell>
}

const userColumns: (
  translate: Function
) => IDataTableColumn<LocalizationRow>[] = (translate) => [
  { name: translate('key'), selector: 'key' },
  {
    name: translate('english'),
    cell: (row) => createInputCellWithLanguage(Language.En, row),
  },
  {
    name: translate('russian'),
    cell: (row) => createInputCellWithLanguage(Language.Ru, row),
  },
]

export default function LocalizationTable() {
  const { data, loading } = useLocalizationsQuery({
    pollInterval: pollInterval,
  })
  const translate = useTranslate()

  if (loading) {
    return null
  }

  const localizationsByLanguages = Array.from(
    new Set((data?.localizations || []).map((localization) => localization.key))
  ).map((key) => ({
    key,
    english: (data?.localizations || []).find(
      (localization) =>
        localization.key === key && localization.language === Language.En
    )?.value,
    russian: (data?.localizations || []).find(
      (localization) =>
        localization.key === key && localization.language === Language.Ru
    )?.value,
  }))

  return (
    <DataTable
      data={localizationsByLanguages}
      columns={userColumns(translate)}
      progressPending={loading}
      progressComponent={Loader}
      noHeader
      highlightOnHover
      striped
      responsive
    />
  )
}
