import { useStore } from 'laco-react'
import React from 'react'
import { Button, Label, Progress, Segment } from 'semantic-ui-react'
import UploadStore from 'store/uploadStore'

import './UploadProgress.scss'

const UploadProgress = () => {
  const { files } = useStore(UploadStore)

  return (
    <div className="upload-progress">
      <Segment.Group>
        {files.map(({ name, progress, abort }) => (
          <Segment className="progress-item" key={name}>
            <Button as="div" labelPosition="left" size="tiny">
              <Label as="a" basic>
                <Progress
                  percent={progress === -1 ? 100 : progress}
                  size="medium"
                  indicating
                  error={progress === -1}
                />
                {name}
              </Label>
              <Button icon="cancel" content="Cancel" onClick={abort}></Button>
            </Button>
          </Segment>
        ))}
      </Segment.Group>
    </div>
  )
}

export default React.memo(UploadProgress)
