import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Container, Form, Input } from 'semantic-ui-react'
import { useUpdatePasswordMutation } from 'generated/graphql'
import { hideModal } from 'store/modalStore'
import { useStore } from 'laco-react'
import useTranslate from 'hooks/useTranslate'
import Error from 'components/common/Error'
import UsersStore, { LacoUsers } from 'store/usersStore'

export default function ResetPassword() {
  const translate = useTranslate()
  const [updatePassword, { error, loading }] = useUpdatePasswordMutation()
  const { selected }: LacoUsers = useStore(UsersStore)
  const { handleSubmit, control, formState } = useForm({
    mode: 'onChange',
  })

  async function onSubmit({ password }: { password: string }) {
    try {
      await updatePassword({
        variables: {
          password,
          id: selected as string,
        },
      })

      hideModal()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)} loading={loading}>
        <Form.Field>
          <Controller
            as={<Input placeholder={translate('password')} type="password" />}
            rules={{
              required: true,
            }}
            name="password"
            control={control}
            defaultValue=""
            autoFocus
          />
        </Form.Field>

        <Form.Button
          color="blue"
          disabled={!formState.isValid}
          fluid
          size="big"
        >
          {translate('change-password')}
        </Form.Button>
      </Form>
      <Error error={error}></Error>
    </Container>
  )
}
