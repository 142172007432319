import filesize from 'filesize'
import { format } from 'date-fns'
import './DocumentsTable.scss'

import React from 'react'
import { useStore } from 'laco-react'

import DataTable, { IDataTableColumn } from 'react-data-table-component'

import {
  DocumentTableRowFragment,
  useFolderQuery,
  DocumentType,
  useOpenDocumentMutation,
} from 'generated/graphql'
import { createBoolCellWithSelector } from 'components/common/table/BoolCell'
import { Input, Checkbox, Label, Breadcrumb, Button } from 'semantic-ui-react'
import DocumentStore, {
  LacoDocument,
  selectDocumentInFolder,
} from 'store/documentStore'
import { useHistory } from 'react-router-dom'
import { ROUTES } from 'components/Routes'
import { openModal, ModalType } from 'store/modalStore'
import useTranslate from 'hooks/useTranslate'
import { transformDownloadedDocumentName } from 'utils/downloadFile'
import Tag from 'components/common/Tag'
import convertToLatin from 'utils/convertToLatin'
import AuthStore, { LacoAuth } from 'store/authStore'
import MimeImage from 'components/common/MimeImage'
import { pollInterval } from 'constants/pollInterval'
import truncateString from 'utils/truncateString'

const documentColumns: (
  translate: Function
) => IDataTableColumn<DocumentTableRowFragment>[] = (translate) => [
  {
    name: translate('type'),
    selector: 'mimeType',
    // eslint-disable-next-line react/display-name
    format: (row) => <MimeImage row={row}></MimeImage>,
    sortable: true,
  },
  {
    name: translate('id'),
    selector: 'id',
    sortable: true,
    sortFunction: (a, b) => parseInt(a.id) - parseInt(b.id),
    grow: 3,
    maxWidth: '5%',
  },
  {
    name: translate('name'),
    selector: 'name',
    sortable: true,
    sortFunction(a, b) {
      return a.canonicalName.localeCompare(b.canonicalName)
    },
    grow: 3,
  },
  {
    name: translate('createdAt'),
    selector: 'createdAt',
    format: (row) => format(new Date(row.createdAt), 'yyyy-MM-dd HH:mm'),
    grow: 2,
    sortable: true,
    hide: 'lg',
  },
  {
    name: translate('updatedAt'),
    selector: 'updatedAt',
    format: (row) => format(new Date(row.updatedAt), 'yyyy-MM-dd HH:mm'),
    grow: 2,
    sortable: true,
    hide: 'lg',
  },
  {
    name: translate('owner'),
    selector: 'owner.name',
    sortable: true,
    grow: 2,
    hide: 'lg',
  },
  {
    name: translate('locked-by') || 'lockedBy',
    selector: 'lockedBy.name',
    format: (row) => (row.isLocked ? row.lockedBy?.name : ''),
    sortable: false,
    grow: 2,
    hide: 'lg',
  },
  {
    name: translate('size'),
    selector: 'size',
    format: (row) =>
      row.entry?.size ? filesize(parseInt(row.entry?.size)) : '',
    sortable: true,
    sortFunction: (a, b) =>
      parseInt(a.entry?.size || '0') - parseInt(b.entry?.size || '0'),
  },
  {
    name: translate('tags'),
    cell: function Tags(row) {
      return (
        <Label.Group>
          {row.tags.map((tag) => {
            return <Tag key={tag.id} tag={tag}></Tag>
          })}
        </Label.Group>
      )
    },
  },
  {
    name: translate('isLocked'),
    selector: 'isLocked',
    sortable: true,
    format: createBoolCellWithSelector('isLocked', (row) => {
      selectDocumentInFolder(row.id, row.parent?.id as string)
    }),
    center: true,
    compact: true,
  },
  {
    name: translate('isDeleted'),
    selector: 'isDeleted',
    sortable: true,
    format: createBoolCellWithSelector('isDeleted', (row) => {
      selectDocumentInFolder(row.id, row.parent?.id as string)
      if (row.isDeleted) {
        openModal(ModalType.DOCUMENT_RESTORE)
      } else {
        openModal(ModalType.DOCUMENT_DELETE)
      }
    }),
    center: true,
    compact: true,
    omit: !AuthStore.get().user?.isAdmin,
  },
]

type Props = {
  folderId: string
}

export default function DocumentsTable({ folderId }: Props) {
  const translate = useTranslate()
  const history = useHistory()
  const [showArchive, setShowArchive] = React.useState(false)
  const [showDeleted, setShowDeleted] = React.useState(false)
  const [filterText, setFilterText] = React.useState('')
  const { selectedDocumentInFolder }: LacoDocument = useStore(DocumentStore)
  const authState: LacoAuth = useStore(AuthStore)

  const {
    data: { listDocuments, breadcrumb } = { listDocuments: [], breadcrumb: [] },
    loading,
    refetch,
  } = useFolderQuery({
    variables: {
      id: folderId,
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: pollInterval,
  })
  const [openDocument] = useOpenDocumentMutation()

  const selected = selectedDocumentInFolder[folderId]

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="documents-table-subheader">
        <Breadcrumb>
          {breadcrumb.map(({ id, name, parent }, index) => {
            const onClick = () => {
              selectDocumentInFolder(id, parent as string)
              history.push(`${ROUTES.DOCUMENTS}/${id}`)
            }

            if (index === breadcrumb.length - 1) {
              return (
                <Breadcrumb.Section key={id} onClick={onClick} active as="div">
                  {truncateString(name, 30)}
                </Breadcrumb.Section>
              )
            }

            return (
              <React.Fragment key={id}>
                <Breadcrumb.Section link onClick={onClick}>
                  {truncateString(name, 9)}
                </Breadcrumb.Section>
                <Breadcrumb.Divider />
              </React.Fragment>
            )
          })}
        </Breadcrumb>
        <div className="checkboxes">
          <Button onClick={() => refetch()} color="blue">
            {translate('refresh')}
          </Button>
          <Checkbox
            label={translate('show-archived')}
            checked={showArchive}
            onClick={() => {
              setShowArchive((val) => !val)
              refetch()
            }}
          ></Checkbox>
          {authState.user?.isAdmin && (
            <Checkbox
              label={translate('show-deleted')}
              checked={showDeleted}
              onClick={() => {
                setShowDeleted((val) => {
                  refetch({
                    id: folderId,
                    isDeleted: !val,
                  })

                  return !val
                })
              }}
            ></Checkbox>
          )}
        </div>
        <Input
          onChange={(e) => setFilterText(e.target.value)}
          icon="search"
          placeholder={translate('search-in-folder')}
        />
      </div>
    )
  }, [
    translate,
    showArchive,
    showDeleted,
    refetch,
    setShowArchive,
    breadcrumb,
    authState.user,
    folderId,
    history,
  ])

  const conditionalRowStyles = [
    {
      when: (row) => row.isLocked,
      style: {
        backgroundColor: 'darkred',
        color: 'white',
        '&:hover': {
          backgroundColor: 'peru',
        },
      },
    },
    // You can also pass a callback to style for additional customization
  ]

  const filteredDocuments = (listDocuments || [])
    .filter(
      (item) =>
        item.canonicalName &&
        convertToLatin(item.canonicalName)
          .toLowerCase()
          .includes(filterText.toLowerCase())
    )
    .filter((item) => (showArchive ? true : !item.isArchived))
    .filter((item) => (showDeleted ? true : !item.isDeleted))

  // todo: handle unselect when clicking outside
  return (
    <DataTable
      data={filteredDocuments.slice() as DocumentTableRowFragment[]}
      // mapping is needed because of selectableRowSelected (https://github.com/jbetancur/react-data-table-component#row-selection)
      columns={documentColumns(translate)}
      progressPending={loading}
      noHeader
      style={{ cursor: 'pointer' }}
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
      highlightOnHover
      conditionalRowStyles={conditionalRowStyles}
      defaultSortField="canonicalName"
      onRowDoubleClicked={(row) => {
        if (row.type === DocumentType.Folder) {
          selectDocumentInFolder(row.id, row.parent?.id as string)
          history.push(`${ROUTES.DOCUMENTS}/${row.id}`)
        } else {
          selectDocumentInFolder(row.id, row.parent?.id as string)
          // @ts-ignore
          window.api.downloadFile(
            row?.entry?.id,
            transformDownloadedDocumentName(row?.name, row.id),
            // @ts-ignore
            `${window.api.HOME_DIR}/Documents/DS Documents`
          )
          openDocument({
            variables: { id: row.id as string },
          })
        }
      }}
      onRowClicked={(row) => {
        // idea: context menu could appear upon right clicks
        // https://github.com/jbetancur/react-data-table-component/issues/280
        if (selected === row.id) {
          // DONT DO IT!
          // selectDocumentInFolder(null, row.parent?.id as string)
        } else {
          selectDocumentInFolder(row.id, row.parent?.id as string)
        }
      }}
      selectableRowsHighlight
      selectableRowSelected={(row) => row.id === selected}
    />
  )
}
