import React from 'react'
import { useRemoveUsersFromGroupMutation } from 'generated/graphql'
import { useStore } from 'laco-react'
import UsersStore, { LacoUsers } from 'store/usersStore'
import useTranslate from 'hooks/useTranslate'
import { Button } from 'semantic-ui-react'
import { hideModal } from 'store/modalStore'
import { LacoUserGroups } from 'store/userGroupsStore'
import userGroupsStore from 'store/userGroupsStore'

export default function RemoveUserFromGroup() {
  const [removeUsersFromGroup] = useRemoveUsersFromGroupMutation()
  const { selected: selectedUser }: LacoUsers = useStore(UsersStore)
  const { selected: selectedGroup }: LacoUserGroups = useStore(userGroupsStore)
  const translate = useTranslate()

  return (
    <Button
      color="red"
      onClick={() => {
        removeUsersFromGroup({
          variables: {
            id: selectedGroup as string,
            userIds: [selectedUser as string],
          },
        })
        hideModal()
      }}
    >
      {translate('delete-user-from-usergroup')}
    </Button>
  )
}
