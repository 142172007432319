import React from 'react'
import { Message } from 'semantic-ui-react'

interface Props {
  error?: { name?: string; message: string }
}

export default function Error({ error }: Props) {
  if (!error) {
    return null
  }

  return (
    <Message negative>
      <Message.Header>{error.name}</Message.Header>
      {error.message}
    </Message>
  )
}
