import {
  useUpdateFileMutation,
  useDocumentQuery,
  useOpenDocumentMutation,
  useEditDocumentMutation,
  DocumentType,
} from 'generated/graphql'
import DocumentStore, { LacoDocument } from 'store/documentStore'
import { useStore } from 'laco-react'
import useTranslate from 'hooks/useTranslate'
import { openModal, ModalType } from 'store/modalStore'
import React from 'react'
import { Icon } from 'semantic-ui-react'
import AuthStore, { LacoAuth } from 'store/authStore'
import { transformDownloadedDocumentName } from 'utils/downloadFile'
import uploadToS3 from 'utils/uploadToS3'
import { toast } from 'react-toastify'
import { ApolloError } from 'apollo-client'

type ActionProps = {
  id: string
  action: () => void
  children: JSX.Element
  show?: boolean
}

function useDocumentActions() {
  const translate = useTranslate()
  const { selectedDocumentInFolder, currentFolder }: LacoDocument = useStore(
    DocumentStore
  )

  const documentId = selectedDocumentInFolder[currentFolder] || ''
  const { data: { document, access } = {}, loading } = useDocumentQuery({
    skip: !documentId,
    variables: { id: documentId },
    fetchPolicy: 'network-only',
  })

  const [updateFile] = useUpdateFileMutation()
  const [openDocument] = useOpenDocumentMutation()
  const [editDocument] = useEditDocumentMutation()
  const authState: LacoAuth = useStore(AuthStore)

  const inputElement = window.document.getElementById(
    'global-file-input'
  ) as HTMLInputElement
  inputElement.multiple = false
  inputElement.accept = document?.mimeType as string
  // @ts-ignore
  inputElement.onchange = async function handleFileUpdate({
    target,
  }: {
    target: HTMLInputElement
  }) {
    if (!target.files?.[0]) {
      return
    }

    const file = target.files[0]
    try {
      const url = await uploadToS3(file)

      await updateFile({
        variables: {
          documentId: document?.id as string,
          file: url,
          mimeType: file.type,
          size: file.size.toString(),
        },
      })

      toast(translate('upload-successful'), {
        type: 'success',
      })
    } catch (err) {
      if (err instanceof ApolloError) {
        return toast(translate(err.graphQLErrors[0].message), {
          type: 'error',
        })
      }

      console.error(err)

      toast(translate('upload-error'), {
        type: 'error',
      })
    }
  }

  function isSuperAdmin() {
    return authState.user?.isAdmin
  }

  if (!document) {
    return {
      actions: [],
      loading: true,
    }
  }

  const actions: ActionProps[] = [
    {
      id: 'rename-document',
      action: () => openModal(ModalType.DOCUMENT_RENAME),
      children: (
        <>
          {translate('rename-document')} <Icon name="pencil" />
        </>
      ),
      show: isSuperAdmin() || access?.control,
    },
    {
      id: 'move-document',
      action: () => openModal(ModalType.DOCUMENT_MOVE),
      children: (
        <>
          {translate('move-document')} <Icon name="move" />
        </>
      ),
      show: isSuperAdmin() || (access?.control && !document?.isLocked),
    },
    {
      id: 'copy-document',
      action: () => openModal(ModalType.DOCUMENT_COPY),
      children: (
        <>
          {translate('copy-document')} <Icon name="copy" />
        </>
      ),
      show: isSuperAdmin() || (access?.control && !document?.isLocked),
    },
    {
      id: 'edit-tags',
      action: () => openModal(ModalType.DOCUMENT_EDIT_TAGS),
      children: (
        <>
          {translate('edit-tags')} <Icon name="tags" />
        </>
      ),
      show: true,
    },
    {
      id: 'unlock-document',
      action: () => openModal(ModalType.DOCUMENT_UNLOCK),
      children: (
        <>
          {translate('unlock-document')} <Icon name="unlock" />
        </>
      ),
      show:
        document?.type === DocumentType.File &&
        document.isLocked &&
        (isSuperAdmin() || document.lockedBy?.id === authState.user?.id),
    },
    {
      id: 'lock-document',
      action: () => openModal(ModalType.DOCUMENT_LOCK),
      children: (
        <>
          {translate('lock-document')} <Icon name="lock" />
        </>
      ),
      show:
        document?.type === DocumentType.File &&
        !document.isLocked &&
        isSuperAdmin(),
    },
    {
      id: 'download-document',
      action: () => {
        // @ts-ignore
        window.api.downloadFile(
          document?.entry?.id,
          transformDownloadedDocumentName(document?.name, document.id),
          // @ts-ignore
          `${window.api.HOME_DIR}/Documents/DS Documents`
        )
        openDocument({
          variables: { id: document?.id as string },
        })
      },
      children: (
        <>
          {translate('download-document')} <Icon name="download" />
        </>
      ),
      show:
        document?.type === DocumentType.File &&
        (isSuperAdmin() || access?.read),
    },
    {
      id: 'edit-document',
      action: async () => {
        await editDocument({
          variables: { id: document?.id as string },
        })
        // @ts-ignore
        window.api.downloadFile(
          document?.entry?.id,
          transformDownloadedDocumentName(document?.name, document.id),
          // @ts-ignore
          `${window.api.HOME_DIR}/Documents/DS Documents`
        )
      },
      children: (
        <>
          {translate('edit-document')} <Icon name="edit" />
        </>
      ),
      show:
        document?.type === DocumentType.File &&
        !document.isLocked &&
        (isSuperAdmin() || access?.write),
    },
    {
      id: 'update-document',
      action: () => {
        inputElement.click()
      },
      children: (
        <>
          {translate('update-document')}
          <Icon name="upload"></Icon>
        </>
      ),
      show:
        document?.type === DocumentType.File &&
        (authState?.user?.id === document?.lockedBy?.id || isSuperAdmin()),
    },
    {
      id: 'change-users-permissions',
      action: () =>
        openModal(ModalType.DOCUMENT_CHANGE_USERS_PERMISSIONS, {
          documentId: document?.id as string,
        }),
      children: (
        <>
          {translate('change-users-permissions')} <Icon name="user" />
        </>
      ),
      show: isSuperAdmin() || access?.control,
    },
    {
      id: 'change-usergroups-permissions',
      action: () =>
        openModal(ModalType.DOCUMENT_CHANGE_USERGROUPS_PERMISSIONS, {
          documentId: document?.id as string,
        }),
      children: (
        <>
          {translate('change-usergroups-permissions')} <Icon name="users" />
        </>
      ),
      show: isSuperAdmin() || access?.control,
    },
    {
      id: 'change-users-permissions-recursive',
      action: () =>
        openModal(ModalType.DOCUMENT_CHANGE_USERS_PERMISSIONS, {
          documentId: document?.id as string,
          recursive: true,
        }),
      children: (
        <>
          {translate('change-users-permissions-recursive')} <Icon name="user" />
        </>
      ),
      show:
        document?.type === DocumentType.Folder &&
        (isSuperAdmin() || access?.control),
    },
    {
      id: 'change-usergroups-permissions-recursive',
      action: () =>
        openModal(ModalType.DOCUMENT_CHANGE_USERGROUPS_PERMISSIONS, {
          documentId: document?.id as string,
          recursive: true,
        }),
      children: (
        <>
          {translate('change-usergroups-permissions-recursive')}
          <Icon name="users" />
        </>
      ),
      show:
        document?.type === DocumentType.Folder &&
        (isSuperAdmin() || access?.control),
    },
    {
      id: 'change-owner',
      action: () => openModal(ModalType.DOCUMENT_CHANGE_OWNER),
      children: (
        <>
          {translate('change-owner')} <Icon name="address card" />
        </>
      ),
      show: isSuperAdmin(),
    },
    {
      id: 'restore-document',
      action: () => openModal(ModalType.DOCUMENT_RESTORE),
      children: (
        <>
          {translate(
            document?.type === 'File' ? 'restore-document' : 'restore-folder'
          )}
          <Icon color="green" name="triangle up" />
        </>
      ),
      show: document?.isDeleted && (isSuperAdmin() || access?.control),
    },
    {
      id: 'delete-document',
      action: () => openModal(ModalType.DOCUMENT_DELETE),
      children: (
        <>
          {translate(
            document?.type === 'File' ? 'delete-document' : 'delete-folder'
          )}
          <Icon color="red" name="trash" />
        </>
      ),
      show: !document?.isDeleted && (isSuperAdmin() || access?.control),
    },
    {
      id: 'show-history',
      action: () => openModal(ModalType.DOCUMENT_HISTORY),
      children: (
        <>
          {translate('show-history')} <Icon color="blue" name="list" />
        </>
      ),
      show: document?.type === DocumentType.File,
    },
    {
      id: 'show-full-history',
      action: () => openModal(ModalType.DOCUMENT_FULL_HISTORY),
      children: (
        <>
          {translate('show-full-history')} <Icon color="blue" name="history" />
        </>
      ),
      show: isSuperAdmin(),
    },
  ]

  return { actions: document ? actions : [], loading }
}

export default useDocumentActions
