import client from 'apolloClient'
import {
  CreateUploadUrlDocument,
  CreateUploadUrlMutation,
  CreateUploadUrlMutationVariables,
} from 'generated/graphql'
import { addNewFile, modifyFileProgress } from 'store/uploadStore'

export default async function uploadToS3(file: File) {
  const { data } = await client.mutate<
    CreateUploadUrlMutation,
    CreateUploadUrlMutationVariables
  >({
    mutation: CreateUploadUrlDocument,
    variables: {
      name: file.name,
      mimeType: file.type,
    },
  })

  if (!data) {
    throw new Error('Failed to generate signed url!')
  }

  return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest()

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(data.createUploadUrl.url)
        } else {
          reject(xhr.responseText)
        }
      }
    }

    xhr.onabort = () => {
      modifyFileProgress(file.name, -1, abort)
      reject('Upload aborted')
    }

    function abort() {
      xhr.abort()
    }

    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        const percentComplete = Math.floor((e.loaded / file.size) * 100)
        modifyFileProgress(file.name, percentComplete, abort)
      }
    }

    addNewFile(file.name, 0)

    xhr.open('PUT', data.createUploadUrl.signedUrl)
    xhr.send(file)
  })
}
