import './layout.scss'

import React from 'react'
import { Container } from 'semantic-ui-react'
import Modal from 'components/Modal'
import UploadProgress from 'components/UploadProgress'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

interface Props {
  children: React.ReactNode
  header?: React.ReactNode
  sideBar?: React.ReactNode
}

export default function Layout({ header, sideBar, children }: Props) {
  return (
    <Container fluid className="main-container">
      {header}
      <div className="flex-container">
        <section className="main">{children}</section>
        {sideBar && <aside className="sidebar">{sideBar}</aside>}
      </div>
      <Modal />
      <UploadProgress></UploadProgress>
      <ToastContainer hideProgressBar />
    </Container>
  )
}
