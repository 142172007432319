import React from 'react'
import { useDocumentQuery, useDeleteDocumentMutation } from 'generated/graphql'

import { useStore } from 'laco-react'
import useTranslate from 'hooks/useTranslate'
import { Button } from 'semantic-ui-react'
import { hideModal } from 'store/modalStore'
import DocumentStore, {
  LacoDocument,
  selectDocumentInFolder,
} from 'store/documentStore'

type Props = {
  isDelete: boolean
}

export default function DeleteDocumentButton({ isDelete }: Props) {
  const [deleteDocument] = useDeleteDocumentMutation()
  const { currentFolder, selectedDocumentInFolder }: LacoDocument = useStore(
    DocumentStore
  )
  const selected = selectedDocumentInFolder[currentFolder]
  const translate = useTranslate()
  const { data } = useDocumentQuery({
    variables: { id: selected as string },
  })

  const type = data?.document?.type === 'File' ? 'document' : 'folder'

  return (
    <Button
      color={isDelete ? 'red' : 'green'}
      onClick={() => {
        deleteDocument({
          variables: { id: selected as string },
        })
        if (isDelete) {
          selectDocumentInFolder(null, currentFolder)
        }
        hideModal()
      }}
    >
      {translate(isDelete ? `delete-${type}` : `restore-${type}`)}
    </Button>
  )
}
