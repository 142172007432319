import React from 'react'
import DataTable, { IDataTableColumn } from 'react-data-table-component'
import {
  useOwnTagsQuery,
  TagFragment,
  useDeleteTagMutation,
  OwnTagsDocument,
  OwnTagsQuery,
} from 'generated/graphql'
import Tag from 'components/common/Tag'
import useTranslate from 'hooks/useTranslate'
import { Loader, Button, Icon } from 'semantic-ui-react'

const tagColumns: (translate: Function) => IDataTableColumn<TagFragment>[] = (
  translate
) => [
  {
    name: translate('tag'),
    cell: function TagCell(row) {
      return <Tag label key={row.id} tag={row}></Tag>
    },
  },
  {
    name: translate('delete-tag'),
    button: true,
    cell: function DeleteTag(row) {
      const [deleteTag] = useDeleteTagMutation()

      if (row.color === 'Favorite') {
        return null
      }

      return (
        <Button
          icon
          onClick={() =>
            deleteTag({
              variables: { id: row.id },
              update(cache, { data }) {
                const ownTagsQueryData = cache.readQuery({
                  query: OwnTagsDocument,
                }) as OwnTagsQuery

                cache.writeQuery({
                  query: OwnTagsDocument,
                  data: {
                    userTags: ownTagsQueryData.userTags.filter(
                      (tag) => tag.id !== data?.deleteTag.id
                    ),
                  },
                })
              },
            })
          }
        >
          <Icon name="trash" color="red" />
        </Button>
      )
    },
  },
]

export default function OwnTagList() {
  const translate = useTranslate()
  const { data, loading } = useOwnTagsQuery({
    // todo: improve, use cache
    fetchPolicy: 'network-only',
  })

  return (
    <div>
      <DataTable
        data={data?.userTags as TagFragment[]}
        columns={tagColumns(translate)}
        progressPending={loading}
        progressComponent={Loader}
        noHeader
        highlightOnHover
      />
    </div>
  )
}
