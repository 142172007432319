import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { setContext } from 'apollo-link-context'
// import { onError } from 'apollo-link-error'
import { createUploadLink } from 'apollo-upload-client'

const uri =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000/gql'
    : `${process.env.REACT_APP_API_URL}/gql`

const cache = new InMemoryCache({
  freezeResults: true,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = sessionStorage.getItem('jwt')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const uploadLink = createUploadLink({
  uri: uri,
})

const client = new ApolloClient({
  link: authLink.concat(uploadLink),
  cache,
  resolvers: {},
  assumeImmutableResults: true,
})

export default client
