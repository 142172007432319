import React from 'react'
import { useStore } from 'laco-react'

import DataTable, { IDataTableColumn } from 'react-data-table-component'

import { UserTableRowFragment, useUsersQuery } from 'generated/graphql'
import UsersStore, { LacoUsers, selectUser } from 'store/usersStore'
import { createBoolCellWithSelector } from 'components/common/table/BoolCell'
import { openModal, ModalType } from 'store/modalStore'
import useTranslate from 'hooks/useTranslate'
import { pollInterval } from 'constants/pollInterval'
import AuthStore, { LacoAuth } from 'store/authStore'
import { Checkbox, Input } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'

const userColumns: (
  translate: Function,
  authState: LacoAuth
) => IDataTableColumn<UserTableRowFragment>[] = (translate, authState) => [
  { name: translate('id'), selector: 'id', grow: 3, hide: 'lg' },
  {
    name: translate('name'),
    selector: 'name',
    sortable: true,
    grow: 3,
    sortFunction(a, b) {
      return a.name.localeCompare(b.name)
    },
  },
  {
    name: translate('email'),
    selector: 'email',
    sortable: true,
    hide: 'lg',
    grow: 3,
  },
  {
    name: translate('isLocked'),
    selector: 'isLocked',
    sortable: true,
    format: createBoolCellWithSelector(
      'isLocked',
      (row) => {
        selectUser(row.id)
        if (row.isLocked) {
          openModal(ModalType.USER_UNLOCK)
        } else {
          openModal(ModalType.USER_LOCK)
        }
      },
      (row) => {
        return row.id === authState.user?.id && !row.isLocked
      }
    ),
  },
  {
    name: translate('isDeleted'),
    selector: 'isDeleted',
    sortable: true,
    format: createBoolCellWithSelector(
      'isDeleted',
      (row) => {
        selectUser(row.id)
        if (row.isDeleted) {
          openModal(ModalType.USER_RESTORE)
        } else {
          openModal(ModalType.USER_DELETE)
        }
      },
      (row) => {
        return row.id === authState.user?.id && !row.isDeleted
      }
    ),
  },
  {
    name: translate('isAdmin'),
    selector: 'isAdmin',
    button: true,
    sortable: true,
    format: createBoolCellWithSelector(
      'isAdmin',
      (row) => {
        selectUser(row.id)
        if (row.isAdmin) {
          openModal(ModalType.USER_SET_ADMIN)
        } else {
          openModal(ModalType.USER_SET_ADMIN)
        }
      },
      (row) => {
        return row.id === authState.user?.id && !row.isDeleted
      }
    ),
  },
]

export default function UsersTable() {
  const translate = useTranslate()
  const { selected }: LacoUsers = useStore(UsersStore)
  const authState: LacoAuth = useStore(AuthStore)
  const history = useHistory()
  const [showLocked, setShowLocked] = React.useState(false)
  const [showDeleted, setShowDeleted] = React.useState(false)
  const [filterText, setFilterText] = React.useState('')

  const { data, loading, refetch } = useUsersQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: pollInterval,
  })

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="users-table-subheader ">
        <div className="checkboxes">
          <Checkbox
            label={translate('show-locked')}
            defaultChecked
            checked={showLocked}
            onClick={() => {
              setShowLocked((val) => !val)
              refetch()
            }}
          ></Checkbox>
          {authState.user?.isAdmin && (
            <Checkbox
              label={translate('show-deleted')}
              defaultChecked
              checked={showDeleted}
              onClick={() => {
                setShowDeleted((val) => {
                  refetch()

                  return !val
                })
              }}
            ></Checkbox>
          )}
          <Input
            className="search-users"
            onChange={(e) => setFilterText(e.target.value)}
            icon="search"
            placeholder={translate('search-users')}
          />
        </div>
      </div>
    )
  }, [
    translate,
    showLocked,
    showDeleted,
    setShowLocked,
    authState.user,
    history,
  ])

  if (loading) {
    return null
  }

  const filteredUsers = (data?.users || [])
    .filter((item) =>
      item.name.toLowerCase().includes(filterText.toLowerCase())
    )
    .filter((item) => (showLocked ? true : !item.isLocked))
    .filter((item) => (showDeleted ? true : !item.isDeleted))

  return (
    <DataTable
      data={filteredUsers.slice() as UserTableRowFragment[]}
      // mapping is needed because of selectableRowSelected (https://github.com/jbetancur/react-data-table-component#row-selection)
      columns={userColumns(translate, authState)}
      progressPending={loading}
      // progressComponent={Loader}
      noHeader
      highlightOnHover
      striped
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
      onRowClicked={(row) => {
        // idea: context menu could appear upon right clicks
        // https://github.com/jbetancur/react-data-table-component/issues/280
        if (selected === row.id) {
          selectUser(null)
        } else {
          selectUser(row.id)
        }
      }}
      selectableRowsHighlight
      selectableRowSelected={(row) => row.id === selected}
    />
  )
}
