import React from 'react'
import { useUpdateUserMutation } from 'generated/graphql'
import { useStore } from 'laco-react'
import UsersStore, { LacoUsers } from 'store/usersStore'
import useTranslate from 'hooks/useTranslate'
import { Button } from 'semantic-ui-react'
import { hideModal } from 'store/modalStore'

type Props = {
  isLocked: boolean
}

export default function LockUserButton({ isLocked }: Props) {
  const [lockUser] = useUpdateUserMutation()
  const { selected }: LacoUsers = useStore(UsersStore)
  const translate = useTranslate()

  return (
    <Button
      color={isLocked ? 'red' : 'green'}
      onClick={() => {
        lockUser({
          variables: {
            id: selected as string,
            isLocked: isLocked,
          },
        })
        hideModal()
      }}
    >
      {translate(isLocked ? 'lock-user' : 'unlock-user')}
    </Button>
  )
}
