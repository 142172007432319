import React from 'react'
import { useStore } from 'laco-react'

import DataTable, { IDataTableColumn } from 'react-data-table-component'

import {
  UserTableRowFragment,
  UserGroupFragment,
  useUserGroupQuery,
} from 'generated/graphql'
import UsersStore, { LacoUsers, selectUser } from 'store/usersStore'
import useTranslate from 'hooks/useTranslate'

import { Loader } from 'semantic-ui-react'

const userColumns: (
  translate: Function
) => IDataTableColumn<UserTableRowFragment>[] = (translate) => [
  { name: translate('id'), selector: 'id', grow: 3 },
  { name: translate('name'), selector: 'name', sortable: true, grow: 3 },
  { name: translate('email'), selector: 'email', sortable: true, grow: 3 },
]

interface Props {
  groupId: UserGroupFragment['id']
}

export default function UsersInGroupTable({ groupId }: Props) {
  const translate = useTranslate()
  const { data, loading } = useUserGroupQuery({
    variables: { id: groupId },
  })
  const { selected }: LacoUsers = useStore(UsersStore)

  if (loading) {
    return null
  }

  return (
    <DataTable
      data={data?.userGroup?.users?.map((x) => x) as UserTableRowFragment[]}
      columns={userColumns(translate)}
      progressPending={loading}
      progressComponent={Loader}
      highlightOnHover
      selectableRows
      selectableRowsHighlight
      selectableRowsNoSelectAll={true}
      onRowClicked={(row) => {
        if (selected === row.id) {
          selectUser(null)
        } else {
          selectUser(row.id)
        }
      }}
      selectableRowDisabled={(row) => !!selected && row.id !== selected}
      onSelectedRowsChange={(selectedRows) => {
        selectUser(selectedRows.selectedRows[0]?.id)
      }}
      selectableRowSelected={(row) => row.id === selected}
      noHeader
    />
  )
}
