import React, { useRef } from 'react'
import { useStore } from 'laco-react'
import { toast } from 'react-toastify'

import HeaderBar from '../Header'
import Layout from './Layout'
import { MenuItem } from '../Header/HeaderBar'
import {
  useDocumentQuery,
  useUploadFileMutation,
  FolderDocument,
  DocumentTableRowFragment,
  FolderQuery,
} from 'generated/graphql'

import DocumentsTable from 'components/DocumentsTable'
import AuthStore, { LacoAuth } from 'store/authStore'
import { ROUTES } from 'components/Routes'
import { ROOT_DOCUMENT_ID } from 'constants/document'
import DocumentsSideBar from 'components/Layout/DocumentMenu'
import { openModal, ModalType } from 'store/modalStore'
import { Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import useTranslate from 'hooks/useTranslate'
import { pollInterval } from 'constants/pollInterval'
import { selectDocumentInFolder } from 'store/documentStore'
import truncateString from 'utils/truncateString'
import uploadToS3 from 'utils/uploadToS3'
import { ApolloError } from 'apollo-client'

const adminActions: (translate: Function) => MenuItem[] = (translate) => [
  {
    icon: 'user',
    to: ROUTES.USERS,
    text: translate('users'),
  },
  { icon: 'users', to: ROUTES.USERGROUPS, text: translate('usergroups') },
  { icon: 'globe', to: ROUTES.LOCALIZATION, text: translate('localization') },
  { icon: 'history', to: ROUTES.LOGS, text: translate('logs') },
]

export type DocumentLayoutProps = {
  folderId: string
}

export default function DocumentsLayout({
  folderId: currentFolder,
}: DocumentLayoutProps) {
  const translate = useTranslate()

  const authState: LacoAuth = useStore(AuthStore)
  const createFileInputRef = useRef<HTMLInputElement>(null)

  const [uploadFile] = useUploadFileMutation()

  const { data } = useDocumentQuery({
    skip: !currentFolder,
    variables: { id: currentFolder },
    fetchPolicy: 'cache-and-network',
    pollInterval: pollInterval,
  })

  async function handleFileChange({ target }: { target: HTMLInputElement }) {
    if (!target.files?.[0]) {
      return
    }

    const file = target.files[0]

    try {
      selectDocumentInFolder(null, currentFolder)

      const url = await uploadToS3(file)

      await uploadFile({
        variables: {
          file: url,
          folderId: currentFolder,
          mimeType: file.type,
          name: file.name,
          size: file.size.toString(),
        },
        update(cache, { data }) {
          const documentsQueryData = cache.readQuery({
            query: FolderDocument,
            variables: { id: currentFolder },
          }) as FolderQuery

          cache.writeQuery({
            query: FolderDocument,
            variables: { id: currentFolder },
            data: {
              listDocuments: documentsQueryData.listDocuments.concat([
                data?.createFile as DocumentTableRowFragment,
              ]),
            },
          })
        },
      })

      toast(translate('upload-successful'), {
        type: 'success',
      })
    } catch (err) {
      if (err instanceof ApolloError) {
        return toast(translate(err.graphQLErrors[0].message), {
          type: 'error',
        })
      }

      console.error(err)

      toast(translate('upload-error'), {
        type: 'error',
      })
    }
  }
  const docTitle = data?.document?.name

  return (
    <Layout
      header={
        <HeaderBar
          backButton={currentFolder !== ROOT_DOCUMENT_ID}
          backButtonComponent={
            <Menu.Item
              as={Link}
              to={`${ROUTES.DOCUMENTS}/${data?.document?.parent?.id}`}
              icon="arrow left"
            ></Menu.Item>
          }
          buttons={[
            {
              icon: 'arrow right',
              text: translate('jump-to-document'),
              action: () => {
                openModal(ModalType.DOCUMENT_JUMP_TO)
              },
            },
            {
              icon: 'search',
              text: translate('find-document'),
              action: () => {
                openModal(ModalType.FIND_DOCUMENT)
              },
            },
            {
              icon: 'folder',
              text: translate('add-folder'),
              action: () => {
                openModal(ModalType.FOLDER_ADD)
              },
              disabled: !data?.access.write,
            },
            {
              text: translate('add-file'),
              icon: 'file',
              action: () => {
                createFileInputRef.current?.click()
              },
              disabled: !data?.access.write,
            },
            ...(authState.user?.isAdmin ? adminActions(translate) : []),
            {
              text: translate('user-settings'),
              icon: 'cogs',
              action: () => {
                openModal(ModalType.USER_SETTINGS)
              },
            },
          ]}
          title={docTitle ? truncateString(docTitle) : ''}
          parentId={data?.document?.id}
        />
      }
      sideBar={<DocumentsSideBar />}
    >
      <>
        <DocumentsTable folderId={currentFolder} />
        <input
          ref={createFileInputRef}
          type="file"
          id="fileinput"
          hidden
          multiple={false}
          onChange={handleFileChange}
        />
      </>
    </Layout>
  )
}
