import React, { useState } from 'react'
import { Input, Grid, Select, Button } from 'semantic-ui-react'

import Error from 'components/common/Error'
import Tag from '../common/Tag'
import {
  TagColor,
  useCreateTagMutation,
  useOwnTagsQuery,
} from 'generated/graphql'
import useTranslate from 'hooks/useTranslate'
import tagColorToSemanticColors from 'utils/tagColorToSemanticColors'
import OwnTagList from 'components/OwnTags/OwnTagList'

const tagColorOptions = [
  {
    key: 'red',
    value: TagColor.Red,
    children: <Tag label tag={{ color: TagColor.Red }} />,
  },
  {
    key: 'orange',
    value: TagColor.Orange,
    children: <Tag label tag={{ color: TagColor.Orange }} />,
  },
  {
    key: 'yellow',
    value: TagColor.Yellow,
    children: <Tag label tag={{ color: TagColor.Yellow }} />,
  },
  {
    key: 'olive',
    value: TagColor.Olive,
    children: <Tag label tag={{ color: TagColor.Olive }} />,
  },
  {
    key: 'green',
    value: TagColor.Green,
    children: <Tag label tag={{ color: TagColor.Green }} />,
  },
  {
    key: 'teal',
    value: TagColor.Teal,
    children: <Tag label tag={{ color: TagColor.Teal }} />,
  },
  {
    key: 'blue',
    value: TagColor.Blue,
    children: <Tag label tag={{ color: TagColor.Blue }} />,
  },
  {
    key: 'violet',
    value: TagColor.Violet,
    children: <Tag label tag={{ color: TagColor.Violet }} />,
  },
  {
    key: 'pink',
    value: TagColor.Pink,
    children: <Tag label tag={{ color: TagColor.Pink }} />,
  },
  {
    key: 'brown',
    value: TagColor.Brown,
    children: <Tag label tag={{ color: TagColor.Brown }} />,
  },
  {
    key: 'grey',
    value: TagColor.Grey,
    children: <Tag label tag={{ color: TagColor.Grey }} />,
  },
  {
    key: 'black',
    value: TagColor.Black,
    children: <Tag label tag={{ color: TagColor.Black }} />,
  },
].map((option) => ({
  ...option,
  text: option.children,
}))

function OwnTags() {
  const translate = useTranslate()
  const [newTagText, setNewTagText] = useState('')
  const [newTagColor, setNewTagColor] = useState<TagColor | null>(null)
  const [createTag, { error: tagCreationError }] = useCreateTagMutation()
  const { refetch: refetchOwnTags } = useOwnTagsQuery({
    // todo: improve, use cache
    fetchPolicy: 'network-only',
  })

  return (
    <Grid columns="equal">
      <Grid.Row>
        <Grid.Column>
          <Select
            options={tagColorOptions}
            clearable
            value={newTagColor || false}
            onChange={(_, data) => setNewTagColor(data.value as TagColor)}
          ></Select>
        </Grid.Column>
        <Grid.Column>
          <Input
            onChange={(e) => setNewTagText(e.target.value)}
            value={newTagText}
            placeholder={translate('name')}
          />
        </Grid.Column>
        <Grid.Column>
          <Button
            color="blue"
            disabled={!newTagColor}
            onClick={async () => {
              if (!newTagColor) {
                return
              }
              try {
                await createTag({
                  variables: {
                    color: newTagColor,
                    name:
                      newTagText ||
                      (newTagColor !== TagColor.Favorite
                        ? translate(tagColorToSemanticColors(newTagColor))
                        : translate('favorite')),
                  },
                })
              } catch (err) {
                console.error(err)
              }
              // todo: improve, use cache
              setNewTagColor(null)
              setNewTagText('')
              refetchOwnTags()
            }}
          >
            {translate('add-new-tag')}
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Error error={tagCreationError}></Error>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width="16">
          <OwnTagList></OwnTagList>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default OwnTags
