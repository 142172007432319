import React from 'react'
import { Container, Form, Input } from 'semantic-ui-react'
import {
  useAddFolderMutation,
  FolderDocument,
  FolderQuery,
  DocumentTableRowFragment,
} from 'generated/graphql'
import { hideModal } from 'store/modalStore'
import useTranslate from 'hooks/useTranslate'
import Error from 'components/common/Error'
import { Controller, useForm } from 'react-hook-form'
import DocumentStore, { LacoDocument } from 'store/documentStore'
import { useStore } from 'laco-react'

export default function AddFolder() {
  const translate = useTranslate()
  const documentState: LacoDocument = useStore(DocumentStore)
  const [addFolder, { error, loading }] = useAddFolderMutation()
  const { handleSubmit, control, formState } = useForm({
    mode: 'onChange',
  })

  async function onSubmit({ name }: any) {
    try {
      await addFolder({
        variables: {
          name,
          folderId: documentState.currentFolder,
        },
        update(cache, { data }) {
          const documentsQueryData = cache.readQuery({
            query: FolderDocument,
            variables: { id: documentState.currentFolder },
          }) as FolderQuery
          cache.writeQuery({
            query: FolderDocument,
            variables: { id: documentState.currentFolder },
            data: {
              listDocuments: documentsQueryData.listDocuments.concat([
                data?.addFolder as DocumentTableRowFragment,
              ]),
            },
          })
        },
      })

      hideModal()
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)} loading={loading}>
        <Form.Field>
          <Controller
            as={<Input placeholder={translate('name')} />}
            rules={{
              required: true,
            }}
            name="name"
            control={control}
            defaultValue=""
            autoFocus
          />
        </Form.Field>

        <Form.Button
          color="blue"
          disabled={!formState.isValid}
          fluid
          size="big"
        >
          {translate('add-folder')}
        </Form.Button>
      </Form>
      <Error error={error}></Error>
    </Container>
  )
}
