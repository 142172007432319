import { useStore } from 'laco-react'
import React, { ComponentClass, FunctionComponent } from 'react'

import ModalStore, { ModalType, LacoModal } from 'store/modalStore'
import Modal from 'components/Modal/Modal'
import { hideModal } from '../../store/modalStore'
import AddUser from 'components/Modal/bodies/AddUser'
import EditUser from 'components/Modal/bodies/EditUser'
import AddUserGroup from './bodies/AddUserGroup'
import AddUsersToGroup from './bodies/AddUsersToGroup'
import CancelButton from 'components/Modal/actions/CancelButton'
import DeleteUserButton from 'components/Modal/actions/DeleteUserButton'
import LockUserButton from 'components/Modal/actions/LockUserButton'
import DeleteUserGroupButton from './actions/DeleteUserGroupButton'
import EditUserGroup from './bodies/EditUserGroup'
import RemoveUserFromGroup from 'components/Modal/actions/RemoveUserFromGroup'
import AddFolder from 'components/Modal/bodies/AddFolder'
import DeleteDocumentButton from 'components/Modal/actions/DeleteDocumentButton'
import RenameDocument from 'components/Modal/bodies/RenameDocument'
import MoveDocument from 'components/Modal/bodies/MoveDocument'
import LockDocumentButton from 'components/Modal/actions/LockDocumentButton'
import UnlockDocumentButton from 'components/Modal/actions/UnlockDocumentButton'
import ResetPassword from 'components/Modal/bodies/ResetPassword'
import JumpToDocument from 'components/Modal/bodies/JumpToDocument'
import ChangeOwner from 'components/Modal/bodies/ChangeOwner'
import UserSettings from 'components/Modal/bodies/UserSettings'
import UserPermissionTable from 'components/Modal/bodies/UserPermissionTable'
import UserGroupPermissionTable from 'components/Modal/bodies/UserGroupPermissionTable'
import CopyDocument from 'components/Modal/bodies/CopyDocument'
import DocumentHistoryTable from 'components/Modal/bodies/DocumentHistoryTable'
import SetTagsOnDocument from './bodies/SetTagsOnDocument'
import FindDocument from './bodies/FindDocument'
import SetAdminUserButton from 'components/Modal/actions/SetAdminUserButton'
import DocumentFullHistoryTable from 'components/Modal/bodies/DocumentFullHistoryTable'

type Element = string | FunctionComponent<any> | ComponentClass<any, any>
type Modal = {
  body: Element | null
  title?: string
  actions?: React.ComponentType<any>[]
}

type Modals = Record<ModalType, Modal>

const modals: Modals = {
  [ModalType.USER_ADD]: {
    body: AddUser,
    title: 'add-user',
    actions: [CancelButton],
  },
  [ModalType.USER_RESET_PASSWORD]: {
    body: ResetPassword,
    title: 'reset-password',
    actions: [CancelButton],
  },
  [ModalType.USER_EDIT]: {
    body: EditUser,
    title: 'edit-user',
    actions: [CancelButton],
  },
  [ModalType.USER_SET_ADMIN]: {
    body: null,
    title: 'set-admin-user',
    actions: [CancelButton, () => <SetAdminUserButton />],
  },
  [ModalType.USER_DELETE]: {
    body: null,
    title: 'delete-user',
    actions: [CancelButton, () => <DeleteUserButton isDelete />],
  },
  [ModalType.USER_RESTORE]: {
    body: null,
    title: 'restore-user',
    actions: [CancelButton, () => <DeleteUserButton isDelete={false} />],
  },
  [ModalType.USER_LOCK]: {
    body: null,
    title: 'lock-user',
    actions: [CancelButton, () => <LockUserButton isLocked />],
  },
  [ModalType.USER_UNLOCK]: {
    body: null,
    title: 'unlock-user',
    actions: [CancelButton, () => <LockUserButton isLocked={false} />],
  },
  [ModalType.USER_SETTINGS]: {
    body: UserSettings,
    title: 'user-settings',
    actions: [CancelButton],
  },
  [ModalType.USERGROUP_ADD]: {
    body: AddUserGroup,
    title: 'add-usergroup',
    actions: [CancelButton],
  },
  [ModalType.USERGROUP_ADD_USERS]: {
    body: AddUsersToGroup,
    title: 'add-users-to-group',
    actions: [CancelButton],
  },
  [ModalType.USERGROUP_REMOVE_USER]: {
    body: null,
    title: 'delete-user-from-usergroup',
    actions: [CancelButton, RemoveUserFromGroup],
  },
  [ModalType.USERGROUP_EDIT]: {
    body: EditUserGroup,
    title: 'edit-usergroup',
    actions: [CancelButton],
  },
  [ModalType.USERGROUP_DELETE]: {
    body: null,
    title: 'delete-usergroup',
    actions: [CancelButton, () => <DeleteUserGroupButton isDelete />],
  },
  [ModalType.USERGROUP_RESTORE]: {
    body: null,
    title: 'restore-user-group',
    actions: [CancelButton, () => <DeleteUserGroupButton isDelete={false} />],
  },
  [ModalType.DOCUMENT_DELETE]: {
    body: null,
    title: 'delete-document',
    actions: [CancelButton, () => <DeleteDocumentButton isDelete />],
  },
  [ModalType.DOCUMENT_RESTORE]: {
    body: null,
    title: 'restore-document',
    actions: [CancelButton, () => <DeleteDocumentButton isDelete={false} />],
  },
  [ModalType.DOCUMENT_RENAME]: {
    body: RenameDocument,
    title: 'rename-document',
    actions: [CancelButton],
  },
  [ModalType.DOCUMENT_MOVE]: {
    body: MoveDocument,
    title: 'move-document',
    actions: [CancelButton],
  },
  [ModalType.DOCUMENT_LOCK]: {
    body: null,
    title: 'lock-document',
    actions: [CancelButton, LockDocumentButton],
  },
  [ModalType.DOCUMENT_UNLOCK]: {
    body: null,
    title: 'unlock-document',
    actions: [CancelButton, UnlockDocumentButton],
  },
  [ModalType.DOCUMENT_COPY]: {
    body: CopyDocument,
    title: 'copy-document',
    actions: [CancelButton],
  },
  [ModalType.FOLDER_ADD]: {
    body: AddFolder,
    title: 'add-folder',
    actions: [CancelButton],
  },
  [ModalType.DOCUMENT_JUMP_TO]: {
    body: JumpToDocument,
    title: 'jump-to-document',
    actions: [CancelButton],
  },
  [ModalType.DOCUMENT_CHANGE_USERS_PERMISSIONS]: {
    body: UserPermissionTable,
    title: 'change-users-permissions',
    actions: [CancelButton],
  },
  [ModalType.DOCUMENT_CHANGE_USERS_PERMISSIONS_RECURSIVE]: {
    body: null, //todo
    title: 'change-users-permissions-recursive',
    actions: [CancelButton],
  },
  [ModalType.DOCUMENT_CHANGE_USERGROUPS_PERMISSIONS]: {
    body: UserGroupPermissionTable, //todo
    title: 'change-usergroups-permissions',
    actions: [CancelButton],
  },
  [ModalType.DOCUMENT_CHANGE_USERGROUPS_PERMISSIONS_RECURSIVE]: {
    body: null, //todo
    title: 'change-usergroups-permissions-recursive',
    actions: [CancelButton],
  },
  [ModalType.DOCUMENT_CHANGE_OWNER]: {
    body: ChangeOwner, //todo
    title: 'change-owner',
    actions: [CancelButton],
  },
  [ModalType.DOCUMENT_EDIT_TAGS]: {
    body: SetTagsOnDocument, //todo
    title: 'edit-tags',
    actions: [CancelButton],
  },
  [ModalType.DOCUMENT_HISTORY]: {
    body: DocumentHistoryTable,
    title: 'document-history',
    actions: [CancelButton],
  },
  [ModalType.DOCUMENT_FULL_HISTORY]: {
    body: DocumentFullHistoryTable,
    title: 'document-full-history',
    actions: [CancelButton],
  },
  [ModalType.FIND_DOCUMENT]: {
    body: FindDocument,
    title: 'find-document',
    actions: [CancelButton],
  },
}

const ModalHandler = () => {
  const { type, ...props }: LacoModal = useStore(ModalStore)

  if (type === null || type === undefined) {
    return null
  }

  const modal = modals[type]

  return (
    <Modal
      className={type}
      open={true}
      onClose={hideModal}
      title={modal.title}
      actions={modal.actions}
    >
      {modal.body && React.createElement(modal.body, props)}
    </Modal>
  )
}

export default ModalHandler
