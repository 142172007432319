import { Store } from 'laco'

type File = {
  name: string
  progress: number
  abort?: Function
}

export type UploadState = {
  files: File[]
}

const initialState: UploadState = {
  files: [],
}

const UploadStore = new Store(initialState, 'UsersStore')

export default UploadStore

export function addNewFile(name: string, progress: number) {
  UploadStore.set((state: UploadState) => {
    return {
      files: state.files.concat([{ name, progress }]),
    }
  })
}

export function modifyFileProgress(
  name: string,
  progress: number,
  abort: Function
) {
  if (progress === 100 || progress === -1) {
    setTimeout(() => {
      UploadStore.set((state: UploadState) => {
        return {
          files: state.files.filter((f) => f.name !== name),
        }
      })
    }, 1000)
  }

  UploadStore.set((state: UploadState) => {
    return {
      files: state.files.map((f) => {
        if (f.name !== name) {
          return f
        }

        return {
          name,
          progress,
          abort,
        }
      }),
    }
  })
}
