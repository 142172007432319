import React from 'react'
import HeaderBar from 'components/Header'
import Layout from './Layout'
import { MenuItem } from 'components/Header/HeaderBar'
import { Menu } from 'semantic-ui-react'
import { openModal, ModalType } from 'store/modalStore'
import UserGroupsTable from 'components/UserGroupsTable'
import ConditionalMenuItem from 'components/common/menu/ConditionalMenuItem'
import useUsergroupActions from 'hooks/useUsergroupActions'
import useTranslate from 'hooks/useTranslate'

function UserGroupsSideBar() {
  const { actions } = useUsergroupActions()

  return (
    <Menu vertical fluid secondary>
      {actions.map(({ id, show, action, ...rest }) => {
        return (
          <ConditionalMenuItem
            key={id}
            show={show}
            onClick={action}
            {...rest}
          ></ConditionalMenuItem>
        )
      })}
    </Menu>
  )
}

const usersHeaderActions: (translate: Function) => MenuItem[] = (translate) => [
  {
    icon: 'plus',
    text: translate('add-usergroup'),
    action: () => {
      openModal(ModalType.USERGROUP_ADD)
    },
  },
]

export default function UsersLayout() {
  const translate = useTranslate()
  return (
    <Layout
      header={
        <HeaderBar
          buttons={usersHeaderActions(translate)}
          backButton
          title="UserGroups"
        />
      }
      sideBar={<UserGroupsSideBar />}
    >
      <UserGroupsTable />
    </Layout>
  )
}
