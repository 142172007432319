import { Store } from 'laco'
import { ROOT_DOCUMENT_ID } from 'constants/document'
import { Document } from 'generated/graphql'

export type LacoDocument = {
  selectedDocumentInFolder: Record<Document['id'], Document['id'] | null>
  currentFolder: Document['id']
}

const initialState: LacoDocument = {
  selectedDocumentInFolder: { [ROOT_DOCUMENT_ID]: null },
  currentFolder: '',
}

const DocumentStore = new Store(initialState, 'DocumentStore')

export default DocumentStore

export const selectDocumentInFolder = (
  documentId: Document['id'] | null,
  folderId: Document['id']
) => {
  DocumentStore.set((state) => ({
    ...state,
    selectedDocumentInFolder: {
      ...state.selectedDocumentInFolder,
      [folderId]: documentId,
    },
  }))
}

export const setFolder = (folderId: Document['id']) =>
  DocumentStore.set((state) => ({
    ...state,
    currentFolder: folderId,
  }))
