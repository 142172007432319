import React from 'react'
import DataTable, { IDataTableColumn } from 'react-data-table-component'
import {
  DocumentHistoryFragment,
  useDocumentHistoryQuery,
} from 'generated/graphql'
import DocumentStore, { LacoDocument } from 'store/documentStore'
import { useStore } from 'laco-react'
import { format } from 'date-fns'
import { Loader } from 'semantic-ui-react'
import useTranslate from 'hooks/useTranslate'
import { pollInterval } from 'constants/pollInterval'

const logColumns: (
  translate: Function
) => IDataTableColumn<DocumentHistoryFragment>[] = (translate) => [
  {
    name: translate('operator-name'),
    selector: 'operator.name',
  },
  {
    name: translate('time'),
    selector: 'createdAt',
    format: (row) => format(new Date(row.createdAt), 'yyyy-MM-dd HH:mm:ss'),
    sortable: true,
  },
  {
    name: translate('event'),
    selector: 'event',
    sortable: true,
  },
  {
    name: translate('param1'),
    selector: 'param1',
    wrap: true,
  },
  {
    name: translate('param2'),
    selector: 'param2',
    wrap: true,
  },
]

const defaultPaginationPerPage = 15

export default function DocumentFullHistoryTable() {
  const { currentFolder, selectedDocumentInFolder }: LacoDocument = useStore(
    DocumentStore
  )
  const selected = selectedDocumentInFolder[currentFolder]
  const { data, loading, refetch, variables } = useDocumentHistoryQuery({
    variables: {
      documentId: selected as string,
      count: defaultPaginationPerPage,
      page: 0,
    },
    fetchPolicy: 'network-only',
    pollInterval: pollInterval,
  })

  const translate = useTranslate()

  const handleRowsPerPageChange = async (newPerPage, page) => {
    await refetch({ ...variables, count: newPerPage, page: page - 1 })
  }

  const handlePageChange = (page) => {
    refetch({ ...variables, page: page - 1 })
  }

  return (
    <DataTable
      data={data?.documentHistory.logs as DocumentHistoryFragment[]}
      pagination
      paginationServer
      onChangeRowsPerPage={handleRowsPerPageChange}
      onChangePage={handlePageChange}
      paginationPerPage={defaultPaginationPerPage}
      paginationTotalRows={data?.documentHistory.total}
      paginationRowsPerPageOptions={[15, 50, 100, 150, 200]}
      columns={logColumns(translate)}
      progressPending={loading}
      progressComponent={Loader}
      subHeader
      noHeader
      striped
      highlightOnHover
      paginationComponentOptions={{
        rowsPerPageText: translate('rows-per-page'),
        rangeSeparatorText: translate('of-operator-pagination'),
      }}
    />
  )
}
