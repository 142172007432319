import React from 'react'
import HeaderBar from 'components/Header'
import Layout from './Layout'
import { MenuItem } from 'components/Header/HeaderBar'
import LogsTable from 'components/LogsTable'

const logHeaderActions: MenuItem[] = []

export default function LogsLayout() {
  return (
    <Layout
      header={<HeaderBar buttons={logHeaderActions} backButton title="Logs" />}
    >
      <LogsTable />
    </Layout>
  )
}
