import React from 'react'
import HeaderBar from 'components/Header'
import Layout from './Layout'
import { MenuItem } from 'components/Header/HeaderBar'
import LocalizationTable from 'components/LocalizationTable'

const localizationHeaderActions: MenuItem[] = []

export default function LocalizationLayout() {
  return (
    <Layout
      header={
        <HeaderBar
          buttons={localizationHeaderActions}
          backButton
          title="Localization"
        />
      }
    >
      <LocalizationTable />
    </Layout>
  )
}
