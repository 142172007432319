import React, { useState } from 'react'
import { Container, Button, Loader } from 'semantic-ui-react'
import useTranslate from 'hooks/useTranslate'
import {
  UserTableRowFragment,
  useUsersQuery,
  UserGroupFragment,
  useAddUsersToGroupMutation,
  UserGroupDocument,
} from 'generated/graphql'
import UserGroupsStore, { LacoUserGroups } from 'store/userGroupsStore'
import { useStore } from 'laco-react'
import DataTable, { IDataTableColumn } from 'react-data-table-component'
import { hideModal } from 'store/modalStore'

const userColumns: (
  translate: Function
) => IDataTableColumn<UserTableRowFragment>[] = (translate) => [
  { name: translate('id'), selector: 'id', grow: 3 },
  { name: translate('name'), selector: 'name', grow: 3, wrap: true },
  { name: translate('email'), selector: 'email', grow: 3, wrap: true },
]

export default function AddUsersToGroup() {
  const translate = useTranslate()
  const { selected }: LacoUserGroups = useStore(UserGroupsStore)
  const [addUsers] = useAddUsersToGroupMutation({
    refetchQueries: () => [
      {
        query: UserGroupDocument,
        variables: { id: selected as string },
      },
    ],
  })
  const { data, loading } = useUsersQuery({
    variables: { where: { userGroups_none: { id: selected } } },
    // todo: improve, use cache
    fetchPolicy: 'network-only',
  })

  const [selectedUsers, setSelectedUsers] = useState<UserGroupFragment['id'][]>(
    []
  )

  function handleAddingUsers() {
    addUsers({ variables: { id: selected as string, userIds: selectedUsers } })
    hideModal()
  }

  return (
    <Container>
      <DataTable
        data={data?.users as UserTableRowFragment[]}
        columns={userColumns(translate)}
        progressPending={loading}
        progressComponent={Loader}
        noHeader
        highlightOnHover
        selectableRowsHighlight
        selectableRows
        onSelectedRowsChange={(selectedRows) => {
          setSelectedUsers(selectedRows.selectedRows.map((row) => row.id))
        }}
        pagination
        paginationComponentOptions={{
          rowsPerPageText: translate('rows-per-page'),
          rangeSeparatorText: translate('of-operator-pagination'),
        }}
      />
      <Button fluid primary onClick={handleAddingUsers}>
        {translate('add-users-to-group')}
      </Button>
    </Container>
  )
}
