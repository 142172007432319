import React from 'react'
import { Input, Form, Header, Grid, Segment } from 'semantic-ui-react'

import { useHistory } from 'react-router-dom'
import { ROUTES } from 'components/Routes'
import { setLoggedInUser } from 'store/authStore'
import { useForm, Controller } from 'react-hook-form'
import { LoginMutation, useLoginMutation } from 'generated/graphql'
import useTranslate from 'hooks/useTranslate'
import Error from 'components/common/Error'

function LoginForm() {
  const [loginMutation, { error, loading }] = useLoginMutation()
  const history = useHistory()
  const translate = useTranslate()
  const { handleSubmit, control, formState } = useForm({
    mode: 'onChange',
  })

  async function onSubmit(formValues: any) {
    try {
      const {
        data: { login },
      } = (await loginMutation({
        variables: formValues,
      })) as { data: LoginMutation }

      setLoggedInUser(login.token, login.user)
      history.push(ROUTES.HOME)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header textAlign="center" size="huge">
          {translate('login')}
        </Header>
        <Segment>
          <Form onSubmit={handleSubmit(onSubmit)} loading={loading}>
            {/* <Form.Field>
              <Controller
                as={<Input placeholder={translate('email')} />}
                rules={{
                  required: false,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'invalid email address',
                  },
                }}
                name="email"
                control={control}
                defaultValue=""
              />
            </Form.Field> */}
            <Form.Field>
              <Controller
                as={<Input placeholder={translate('name')} />}
                rules={{ required: true }}
                name="name"
                control={control}
                defaultValue=""
              />
            </Form.Field>

            <Form.Field>
              <Controller
                name="password"
                rules={{ required: true }}
                as={
                  <Input placeholder={translate('password')} type="password" />
                }
                defaultValue=""
                control={control}
              ></Controller>
            </Form.Field>

            <Form.Button
              color="blue"
              fluid
              size="big"
              disabled={!formState.isValid}
            >
              {translate('login')}
            </Form.Button>
          </Form>
          <Error error={error}></Error>
        </Segment>
        <div>
          Version:{' '}
          {
            // @ts-ignore
            (window.api.REGION || 'ru') + '.' + (window.api.VERSION || '1.0.2')
          }
        </div>
      </Grid.Column>
    </Grid>
  )
}

export default LoginForm
