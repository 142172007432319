import React from 'react'
import { Container, Form, Select, Tab, Input } from 'semantic-ui-react'
import { hideModal } from 'store/modalStore'
import useTranslate, { useSetLanguage } from 'hooks/useTranslate'
import Error from 'components/common/Error'
import { useForm, Controller } from 'react-hook-form'
import AuthStore, { LacoAuth, setUser } from 'store/authStore'
import { useStore } from 'laco-react'
import {
  useSetLanguageMutation,
  Language,
  UserFragment,
  useUpdatePasswordMutation,
} from 'generated/graphql'

const languageOptions = [
  { key: 'ru', value: Language.Ru, text: 'Pусский' },
  { key: 'en', value: Language.En, text: 'English' },
]

function LanguageForm() {
  const translate = useTranslate()
  const { onLanguageSelect } = useSetLanguage()
  const { handleSubmit, formState, setValue, register } = useForm({
    mode: 'onChange',
  })
  const [setLanguage] = useSetLanguageMutation()
  const { user }: LacoAuth = useStore(AuthStore)

  async function onSubmit({ language }: any) {
    try {
      onLanguageSelect(language)
      localStorage.setItem('language', language)
      const data = await setLanguage({ variables: { language } })

      setUser(data.data?.setLanguage as UserFragment)

      hideModal()
    } catch (err) {
      console.error(err)
    }
  }

  const handleLanguageChange = (_, data) => {
    setValue('language', data.value)
  }

  React.useEffect(() => {
    register('language') // custom register Antd input
  }, [register])

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Field>
        <label>{translate('language')}</label>

        <Select
          name="language"
          options={languageOptions}
          onChange={handleLanguageChange}
          defaultValue={user?.language}
          placeholder={translate('change-language')}
        ></Select>
      </Form.Field>

      <Form.Button color="blue" disabled={!formState.isValid} fluid size="big">
        {translate('save-settings')}
      </Form.Button>
      <Error error={undefined}></Error>
    </Form>
  )
}

function PasswordForm() {
  const translate = useTranslate()
  const [updatePassword, { error, loading }] = useUpdatePasswordMutation()
  const { user }: LacoAuth = useStore(AuthStore)
  const { handleSubmit, control, formState } = useForm({
    mode: 'onChange',
  })

  async function onSubmit({ password }: { password: string }) {
    try {
      await updatePassword({
        variables: {
          password,
          id: user?.id as string,
        },
      })

      hideModal()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} loading={loading}>
      <Form.Field>
        <Controller
          as={<Input placeholder={translate('password')} type="password" />}
          rules={{
            required: true,
          }}
          name="password"
          control={control}
          defaultValue=""
          autoFocus
        />
      </Form.Field>

      <Form.Button color="blue" disabled={!formState.isValid} fluid size="big">
        {translate('change-password')}
      </Form.Button>
      <Error error={error}></Error>
    </Form>
  )
}

export default function UserSettings() {
  const translate = useTranslate()

  return (
    <Container>
      <Tab
        menu={{ pointing: true, secondary: true }}
        panes={[
          {
            menuItem: translate('settings'),
            render: function WrappedLanguageForm() {
              return <LanguageForm />
            },
          },
          {
            menuItem: translate('password'),
            render: function WrappedPasswordForm() {
              return <PasswordForm />
            },
          },
        ]}
      ></Tab>
    </Container>
  )
}
