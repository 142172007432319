import React, { useEffect } from 'react'
import { useStore } from 'laco-react'
import HeaderBar from 'components/Header'
import Layout from './Layout'
import { MenuItem } from 'components/Header/HeaderBar'
import { Loader, Menu, Icon } from 'semantic-ui-react'
import {
  useUserLazyQuery,
  UserGroupFragment,
  useUserGroupQuery,
} from 'generated/graphql'
import useTranslate from 'hooks/useTranslate'
import UsersStore, { LacoUsers } from 'store/usersStore'
import { ModalType, openModal } from 'store/modalStore'
import UsersInGroupTable from 'components/UsersInGroupTable'
import { pollInterval } from 'constants/pollInterval'

type SideBarProps = {
  selectedUser: string | null
}

function UserGroupsSideBar({ selectedUser }: SideBarProps) {
  const [userQuery, { /* data,  */ loading }] = useUserLazyQuery({
    pollInterval: pollInterval,
  })
  const translate = useTranslate()

  useEffect(() => {
    if (!selectedUser) {
      return
    }
    userQuery({
      variables: { id: selectedUser as string },
    })
  }, [selectedUser, userQuery])

  if (loading) {
    return <Loader></Loader>
  }

  return (
    <Menu vertical fluid secondary>
      <Menu.Item onClick={() => openModal(ModalType.USERGROUP_REMOVE_USER)}>
        {translate('delete-user-from-usergroup')}
        <Icon color="red" name="user times" />
      </Menu.Item>
    </Menu>
  )
}

const usersInUsergroupHeaderActions: (translate: Function) => MenuItem[] = (
  translate
) => [
  {
    icon: 'user plus',
    text: translate('add-users-to-usergroup'),
    action: () => {
      openModal(ModalType.USERGROUP_ADD_USERS)
    },
  },
]

interface Props {
  groupId: UserGroupFragment['id']
}

export default function UsersInGroupLayout({ groupId }: Props) {
  const { data, loading } = useUserGroupQuery({ variables: { id: groupId } })
  const usersState: LacoUsers = useStore(UsersStore)
  const translate = useTranslate()

  if (loading) {
    return <Loader></Loader>
  }

  return (
    <Layout
      header={
        <HeaderBar
          buttons={usersInUsergroupHeaderActions(translate)}
          backButton
          title={data?.userGroup?.name}
        />
      }
      sideBar={
        usersState.selected ? (
          <UserGroupsSideBar selectedUser={usersState.selected} />
        ) : null
      }
    >
      <UsersInGroupTable groupId={groupId} />
    </Layout>
  )
}
