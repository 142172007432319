import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Container, Form, Input } from 'semantic-ui-react'
import {
  useUpdateUserGroupMutation,
  useUserGroupQuery,
} from 'generated/graphql'
import { hideModal } from 'store/modalStore'
import { useStore } from 'laco-react'
import useTranslate from 'hooks/useTranslate'
import UserGroupsStore, { LacoUserGroups } from 'store/userGroupsStore'
import Error from 'components/common/Error'

export default function EditUser() {
  const usersGroupsState: LacoUserGroups = useStore(UserGroupsStore)
  const translate = useTranslate()
  const [updateUserGroup] = useUpdateUserGroupMutation()
  const { data, loading, error } = useUserGroupQuery({
    variables: { id: usersGroupsState.selected as string },
  })
  const { handleSubmit, control, formState } = useForm({
    mode: 'onChange',
  })

  async function onSubmit(formValues: any) {
    try {
      await updateUserGroup({
        variables: { ...formValues, id: usersGroupsState.selected as string },
      })

      hideModal()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)} loading={loading}>
        <Form.Field>
          <Controller
            as={<Input placeholder={translate('name')} />}
            rules={{
              required: true,
            }}
            name="name"
            control={control}
            defaultValue={data?.userGroup?.name}
            autoFocus
          />
        </Form.Field>

        <Form.Button
          color="blue"
          disabled={!formState.isValid}
          fluid
          size="big"
        >
          {translate('edit-usergroup')}
        </Form.Button>
      </Form>
      <Error error={error}></Error>
    </Container>
  )
}
