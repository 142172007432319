import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Container, Form, Input } from 'semantic-ui-react'
import {
  FolderDocument,
  useCopyDocumentMutation,
  useDocumentQuery,
} from 'generated/graphql'
import { hideModal } from 'store/modalStore'
import useTranslate from 'hooks/useTranslate'
import Error from 'components/common/Error'
import DocumentStore, { LacoDocument } from 'store/documentStore'
import { useStore } from 'laco-react'

export default function CopyDocument() {
  const translate = useTranslate()

  const [copyDocument, { error, loading }] = useCopyDocumentMutation()
  const { handleSubmit, control, formState } = useForm({
    mode: 'onChange',
  })
  const { currentFolder, selectedDocumentInFolder }: LacoDocument = useStore(
    DocumentStore
  )
  const selected = selectedDocumentInFolder[currentFolder]

  const { data } = useDocumentQuery({
    variables: { id: selected as string },
  })

  async function onSubmit({ destinationId, name }: any) {
    try {
      await copyDocument({
        variables: {
          destinationFolderId: destinationId,
          documentId: selected as string,
          name: name,
        },
        refetchQueries: () => [
          {
            query: FolderDocument,
            variables: { id: data?.document?.parent?.id as string },
          },
        ],
      })

      hideModal()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)} loading={loading}>
        <Form.Field>
          <Controller
            as={<Input placeholder={translate('destination-folder-id')} />}
            rules={{
              required: true,
            }}
            type="number"
            name="destinationId"
            control={control}
            defaultValue={currentFolder}
          />
        </Form.Field>

        <Form.Field>
          <Controller
            as={<Input placeholder={translate('name')} />}
            rules={{
              required: true,
            }}
            name="name"
            control={control}
            defaultValue=""
            autoFocus
          />
        </Form.Field>

        <Form.Button
          color="blue"
          disabled={!formState.isValid}
          fluid
          size="big"
        >
          {translate('copy-document')}
        </Form.Button>
      </Form>
      <Error error={error}></Error>
    </Container>
  )
}
