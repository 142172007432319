import React from 'react'
import { Container, Form, Input } from 'semantic-ui-react'
import {
  useCreateUserMutation,
  UsersDocument,
  UsersQuery,
  UserTableRowFragment,
} from 'generated/graphql'
import { hideModal } from 'store/modalStore'
import useTranslate from 'hooks/useTranslate'
import Error from 'components/common/Error'
import { useForm, Controller } from 'react-hook-form'

export default function AddUser() {
  const translate = useTranslate()
  const { handleSubmit, control, formState } = useForm({
    mode: 'onChange',
  })
  const [createUserMutation, { error, loading }] = useCreateUserMutation()

  async function onSubmit(formValues: any) {
    try {
      await createUserMutation({
        variables: formValues,
        update(cache, { data }) {
          const usersQueryData = cache.readQuery({
            query: UsersDocument,
          }) as UsersQuery
          cache.writeQuery({
            query: UsersDocument,
            data: {
              users: usersQueryData.users.concat([
                data?.createUser.user as UserTableRowFragment,
              ]),
            },
          })
        },
      })

      hideModal()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)} loading={loading}>
        <Form.Field>
          <Controller
            as={<Input placeholder={translate('name')} />}
            rules={{
              required: true,
            }}
            name="name"
            control={control}
            defaultValue=""
            autoFocus
          />
        </Form.Field>

        <Form.Field>
          <Controller
            as={<Input placeholder={translate('email')} />}
            rules={{
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'invalid email address',
              },
            }}
            name="email"
            control={control}
            defaultValue=""
          />
        </Form.Field>

        <Form.Field>
          <Controller
            name="password"
            rules={{ required: true }}
            as={<Input placeholder={translate('password')} type="password" />}
            defaultValue=""
            control={control}
          ></Controller>
        </Form.Field>

        <Form.Button
          color="blue"
          disabled={!formState.isValid}
          fluid
          size="big"
        >
          {translate('add-user')}
        </Form.Button>
      </Form>
      <Error error={error}></Error>
    </Container>
  )
}
