import React from 'react'
import DataTable from 'react-data-table-component'
import { EntryFragment, useDocumentQuery } from 'generated/graphql'
import DocumentStore, { LacoDocument } from 'store/documentStore'
import { useStore } from 'laco-react'
import { format } from 'date-fns'
import { transformDownloadedDocumentName } from 'utils/downloadFile'
import { Button } from 'semantic-ui-react'
import useTranslate from 'hooks/useTranslate'
import fileSize from 'filesize'

export default function DocumentHistoryTable() {
  const translate = useTranslate()
  const columns = [
    {
      name: translate('size'),
      selector: 'size',
      format: (row) => (row.size ? fileSize(parseInt(row.size)) : ''),
    },
    { name: translate('modifier'), selector: (row) => row.createdBy.name },
    {
      name: translate('date'),
      selector: (row) => format(new Date(row.createdAt), 'yyyy-MM-dd HH:mm'),
    },
    {
      name: translate('download'),
      button: true,
      cell: function DownloadButton(row) {
        return (
          <Button
            color="blue"
            compact
            onClick={() => {
              // @ts-ignore
              window.api.downloadFile(
                row.id,
                transformDownloadedDocumentName(
                  data?.document.name as string,
                  data?.document.id as string,
                  row.createdAt
                ),
                // @ts-ignore
                `${window.api.HOME_DIR}/Documents/DS Documents`
              )
            }}
          >
            {translate('download')}
          </Button>
        )
      },
    },
  ]

  const { currentFolder, selectedDocumentInFolder }: LacoDocument = useStore(
    DocumentStore
  )
  const selected = selectedDocumentInFolder[currentFolder]

  const { data } = useDocumentQuery({ variables: { id: selected as string } })

  return (
    <DataTable
      highlightOnHover
      data={data?.document.versions as EntryFragment[]}
      onRowDoubleClicked={(row) => {
        // @ts-ignore
        window.api.downloadFile(
          row.id,
          transformDownloadedDocumentName(
            data?.document.name as string,
            data?.document.id as string,
            row.createdAt
          ),
          // @ts-ignore
          `${window.api.HOME_DIR}/Documents/DS Documents`
        )
      }}
      pagination
      paginationComponentOptions={{
        rowsPerPageText: translate('rows-per-page'),
        rangeSeparatorText: translate('of-operator-pagination'),
      }}
      columns={columns}
    ></DataTable>
  )
}
