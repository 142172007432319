import React from 'react'
import HeaderBar from '../Header'
import Layout from './Layout'
import UsersTable from '../UsersTable'
import { MenuItem } from '../Header/HeaderBar'
import { Loader, Menu } from 'semantic-ui-react'
import { openModal, ModalType } from 'store/modalStore'
import ConditionalMenuItem from 'components/common/menu/ConditionalMenuItem'
import useUserActions from 'hooks/useUserActions'
import useTranslate from 'hooks/useTranslate'

function UsersSideBar() {
  const { actions, loading } = useUserActions()

  if (loading) {
    return <Loader></Loader>
  }

  return (
    <Menu vertical fluid secondary>
      {actions.map(({ id, show, action, ...rest }) => {
        return (
          <ConditionalMenuItem
            key={id}
            show={show}
            onClick={action}
            {...rest}
          ></ConditionalMenuItem>
        )
      })}
    </Menu>
  )
}

const usersHeaderActions: (translate: Function) => MenuItem[] = (translate) => [
  {
    icon: 'user plus',
    text: translate('add-user'),
    action: () => {
      openModal(ModalType.USER_ADD)
    },
  },
]

export default function UsersLayout() {
  const translate = useTranslate()

  return (
    <Layout
      header={
        <HeaderBar
          buttons={usersHeaderActions(translate)}
          backButton
          title="Users"
        />
      }
      sideBar={<UsersSideBar />}
    >
      <UsersTable />
    </Layout>
  )
}
