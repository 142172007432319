import React, { useState } from 'react'

import DataTable, { IDataTableColumn } from 'react-data-table-component'

import { LogFragment, useLogsQuery, LogType } from 'generated/graphql'
import { Loader, Form, Select } from 'semantic-ui-react'
import useTranslate from 'hooks/useTranslate'
import { pollInterval } from 'constants/pollInterval'
import { format } from 'date-fns'
import { useForm } from 'react-hook-form'

const logTypeOptions = Object.keys(LogType).map((key) => ({
  value: key,
  text: key,
  key,
}))

const defaultPaginationPerPage = 15

const logColumns: (translate: Function) => IDataTableColumn<LogFragment>[] = (
  translate
) => [
  {
    name: translate('operator-name'),
    selector: 'operator.name',
    sortable: true,
  },
  {
    name: translate('time'),
    selector: 'createdAt',
    format: (row) => format(new Date(row.createdAt), 'yyyy-MM-dd HH:mm:ss'),
    sortable: true,
  },
  {
    name: translate('event'),
    selector: 'event',
    sortable: true,
  },
  {
    name: translate('param1'),
    selector: 'param1',
  },
  {
    name: translate('param2'),
    selector: 'param2',
  },
]

export default function LogsTable() {
  const { data, loading, refetch, variables } = useLogsQuery({
    fetchPolicy: 'network-only',
    pollInterval: pollInterval,
    variables: { type: LogType.User, count: defaultPaginationPerPage, page: 0 },
  })

  const { handleSubmit, register, setValue } = useForm({
    mode: 'onChange',
    defaultValues: { type: LogType.User },
  })

  const [paginationReset, setPaginationReset] = useState(false)

  const translate = useTranslate()

  const resetPagination = async () => {
    await refetch({ ...variables, page: 0 })
    setPaginationReset((val) => !val)
  }

  const handleRowsPerPageChange = async (newPerPage, page) => {
    await refetch({ ...variables, count: newPerPage, page: page - 1 })
  }

  const handlePageChange = (page) => refetch({ ...variables, page: page - 1 })

  React.useEffect(() => {
    register('type') // custom register Antd input
  }, [register])

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleTypeChange = (_, data) => {
      resetPagination()
      setValue('type', data.value)
      handleSubmit(onSubmit)()
    }

    async function onSubmit(formValues: any) {
      try {
        refetch({
          ...variables,
          type: formValues.type,
        })
      } catch (err) {
        console.error(err)
      }
    }
    return (
      <Form
        onSubmit={handleSubmit(onSubmit)}
        onBlur={handleSubmit(onSubmit)}
        className="filter-logs-form"
      >
        <Form.Field>
          <label>{translate('log-type')}</label>
          <Select
            name="type"
            options={logTypeOptions}
            onChange={handleTypeChange}
            defaultValue={LogType.User}
          ></Select>
        </Form.Field>
      </Form>
    )
  }, [translate, refetch, variables, handleSubmit, setValue, resetPagination])

  return (
    <DataTable
      style={{ overflowY: 'visible', overflowX: 'scroll', minHeight: '100%' }}
      data={data?.logs.logs as LogFragment[]}
      pagination
      paginationServer
      onChangeRowsPerPage={handleRowsPerPageChange}
      onChangePage={handlePageChange}
      paginationPerPage={defaultPaginationPerPage}
      paginationTotalRows={data?.logs.total}
      paginationRowsPerPageOptions={[15, 50, 100, 150, 200]}
      columns={logColumns(translate)}
      progressPending={loading}
      progressComponent={Loader}
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
      paginationResetDefaultPage={paginationReset}
      noHeader
      striped
      highlightOnHover
      paginationComponentOptions={{
        rowsPerPageText: translate('rows-per-page'),
        rangeSeparatorText: translate('of-operator-pagination'),
      }}
    />
  )
}
