import React from 'react'
import { useParams } from 'react-router-dom'

export default function withcurrentFolderFromParams(Component: Function) {
  return function WithFolderId(props) {
    const { folderId } = useParams()
    return (
      <Component
        {...props}
        folderId={folderId !== 'root' ? folderId : null}
      ></Component>
    )
  }
}
