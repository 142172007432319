import { useUserGroupQuery } from 'generated/graphql'
import { useStore } from 'laco-react'
import useTranslate from 'hooks/useTranslate'
import { openModal, ModalType } from 'store/modalStore'
import React from 'react'
import { Icon } from 'semantic-ui-react'
import AuthStore, { LacoAuth } from 'store/authStore'
import UserGroupsStore, { LacoUserGroups } from 'store/userGroupsStore'
import { Link } from 'react-router-dom'
import { ROUTES } from 'components/Routes'

type ActionProps = {
  id: string
  action: () => void
  children: JSX.Element
  show?: boolean
  as?: any
  to?: any
}

function useUsergroupActions() {
  const translate = useTranslate()
  const { selected }: LacoUserGroups = useStore(UserGroupsStore)

  const { data, loading } = useUserGroupQuery({
    skip: !selected,
    variables: { id: selected as string },
    fetchPolicy: 'network-only',
  })

  const authState: LacoAuth = useStore(AuthStore)

  function isSuperAdmin() {
    return authState.user?.isAdmin
  }

  const actions: ActionProps[] = [
    {
      id: 'update-usergroup',
      action: () => openModal(ModalType.USERGROUP_EDIT),
      children: (
        <>
          {translate('edit-usergroup')} <Icon name="edit" />
        </>
      ),
      show: isSuperAdmin(),
    },
    {
      id: 'manage-users',
      action: () => {},
      children: (
        <>
          {translate('manage-users')} <Icon name="users" />
        </>
      ),
      show: isSuperAdmin(),
      as: Link,
      to: `${ROUTES.USERGROUP}/${selected}`,
    },
    {
      id: 'restore-usergroup',
      action: () => openModal(ModalType.USERGROUP_RESTORE),
      children: (
        <>
          {translate('restore-usergroup')}
          <Icon color="green" name="triangle up" />
        </>
      ),
      show: data?.userGroup?.isDeleted && isSuperAdmin(),
    },
    {
      id: 'delete-usergroup',
      action: () => openModal(ModalType.USERGROUP_DELETE),
      children: (
        <>
          {translate('delete-usergroup')} <Icon color="red" name="trash" />
        </>
      ),
      show: !data?.userGroup?.isDeleted && isSuperAdmin(),
    },
  ]

  return { actions: data?.userGroup ? actions : [], loading }
}

export default useUsergroupActions
