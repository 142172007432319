import { Store } from 'laco'

const storage = window.sessionStorage

export default (defaultState: Object, name: string, onReady?: Function) => {
  const persistedState = storage.getItem(name)

  const store = new Store(
    persistedState ? JSON.parse(persistedState) : defaultState,
    name
  )

  store.subscribe(() => {
    storage.setItem(store.name, JSON.stringify(store.get()))
  })

  if (onReady) {
    onReady(store.get())
  }

  return store
}
