import React from 'react'
import apolloClient from './apolloClient'
import './App.css'
import { HashRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/react-hooks'
import Routes from 'components/Routes'
import { I18nContextProvider } from 'hooks/useTranslate'

function App() {
  return (
    <I18nContextProvider>
      <ApolloProvider client={apolloClient}>
        <HashRouter>
          <Routes />
        </HashRouter>
      </ApolloProvider>
    </I18nContextProvider>
  )
}

export default App
