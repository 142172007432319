import React from 'react'
import { DocumentLayoutProps } from 'components/Layout/DocumentsLayout'
import { setFolder } from '../store/documentStore'

export default function withFolderIdSetInLaco(Component: Function) {
  return function withFolderSet({ folderId, ...props }: DocumentLayoutProps) {
    setFolder(folderId)
    return (
      <Component
        {...props}
        folderId={folderId !== 'root' ? folderId : null}
      ></Component>
    )
  }
}
