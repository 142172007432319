import React from 'react'
import { Checkbox } from 'semantic-ui-react'

export function createBoolCellWithSelector<Fragment>(
  selector: keyof Fragment,
  onClick?: (row: Fragment) => void,
  isDisabled?: (row: Fragment) => boolean
) {
  return function BoolCell(row: Fragment) {
    const disabled = (isDisabled && isDisabled(row)) || false
    return (
      <Checkbox
        // toggle
        checked={!!row[selector]}
        onClick={() => !disabled && onClick && onClick(row)}
        disabled={disabled}
      ></Checkbox>
    )
  }
}
