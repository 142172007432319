import { format } from 'date-fns'
import { EntryFragment } from 'generated/graphql'

function transformDate(date: Date) {
  return format(date, 'yyyy-MM-dd_HH-mm-ss-SSS')
}

export function transformDownloadedDocumentName(
  documentName: string,
  id: string,
  date?: EntryFragment['createdAt']
) {
  return `${transformDate(
    date ? new Date(date) : new Date()
  )}_${id}_${documentName}`
}
