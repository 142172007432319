import { Store } from 'laco'
import { UserGroupFragment } from 'generated/graphql'

export type LacoUserGroups = {
  selected: UserGroupFragment['id'] | null
}

const initialState: LacoUserGroups = {
  selected: null,
}

const UserGroupsStore = new Store(initialState, 'UserGroupsStore')

export default UserGroupsStore

export const selectUserGroup = (userGroupId: UserGroupFragment['id'] | null) =>
  UserGroupsStore.set(() => ({
    selected: userGroupId,
  }))
