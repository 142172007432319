import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Container, Form, Input } from 'semantic-ui-react'
import {
  UserTableRowFragment,
  useCreateUserGroupMutation,
  UserGroupsQuery,
  UserGroupsDocument,
} from 'generated/graphql'
import { hideModal } from 'store/modalStore'
import useTranslate from 'hooks/useTranslate'
import Error from 'components/common/Error'

export default function AddUserGroup() {
  const [
    createUserGroupMutation,
    { error, loading },
  ] = useCreateUserGroupMutation()
  const translate = useTranslate()
  const { handleSubmit, control, formState } = useForm({
    mode: 'onChange',
  })

  async function onSubmit(formValues: any) {
    try {
      await createUserGroupMutation({
        variables: formValues,
        update(cache, { data }) {
          const userGroupsQueryData = cache.readQuery({
            query: UserGroupsDocument,
          }) as UserGroupsQuery
          cache.writeQuery({
            query: UserGroupsDocument,
            data: {
              userGroups: userGroupsQueryData.userGroups.concat([
                data?.createUserGroup as UserTableRowFragment,
              ]),
            },
          })
        },
      })

      hideModal()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)} loading={loading}>
        <Form.Field>
          <Controller
            as={<Input placeholder={translate('name')} />}
            rules={{
              required: true,
            }}
            name="name"
            control={control}
            defaultValue=""
            autoFocus
          />
        </Form.Field>

        <Form.Button
          color="blue"
          disabled={!formState.isValid}
          fluid
          size="big"
        >
          {translate('add-usergroup')}
        </Form.Button>
      </Form>
      <Error error={error}></Error>
    </Container>
  )
}
