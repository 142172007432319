import jwtDecode from 'jwt-decode'

import initPersistedStore from './initPersistedStore'
import apolloClient from '../apolloClient'
import { UserFragment } from '../generated/graphql'

export type LacoAuth = {
  loggedIn: boolean
  user?: UserFragment
  status?: any
  features?: any
}

const AuthStore = initPersistedStore(
  {
    loggedIn: false,
  },
  'AuthStore'
)

export default AuthStore

export const setLoggedInUser = (token: string, user: UserFragment) =>
  AuthStore.set(() => {
    window.sessionStorage.setItem('jwt', token)

    const data = jwtDecode(token) as any

    return {
      loggedIn: true,
      user,
      status: data?.status,
      features: data?.features,
    }
  }, 'login')

export const logout = () =>
  AuthStore.replace(() => {
    window.sessionStorage.clear()
    apolloClient.cache.reset()

    return {
      loggedIn: false,
    }
  }, 'logout')

export const setUser = (user: UserFragment) =>
  AuthStore.set((state) => {
    return {
      ...state,
      user,
    }
  }, 'setUser')
