import { useUserQuery } from 'generated/graphql'
import { useStore } from 'laco-react'
import useTranslate from 'hooks/useTranslate'
import { openModal, ModalType } from 'store/modalStore'
import React from 'react'
import { Icon } from 'semantic-ui-react'
import AuthStore, { LacoAuth } from 'store/authStore'
import UsersStore, { LacoUsers } from 'store/usersStore'

type ActionProps = {
  id: string
  action: () => void
  children: JSX.Element
  show?: boolean
}

function useUserActions() {
  const translate = useTranslate()
  const { selected }: LacoUsers = useStore(UsersStore)
  const { data, loading } = useUserQuery({
    skip: !selected,
    variables: { id: selected as string },

    fetchPolicy: 'network-only',
  })

  const authState: LacoAuth = useStore(AuthStore)

  function isSuperAdmin() {
    return authState.user?.isAdmin
  }

  const actions: ActionProps[] = [
    {
      id: 'edit-user',
      action: () => openModal(ModalType.USER_EDIT),
      children: (
        <>
          {translate('edit-user')} <Icon name="edit" />
        </>
      ),
      show: isSuperAdmin(),
    },
    {
      id: 'reset-password',
      action: () => openModal(ModalType.USER_RESET_PASSWORD),
      children: (
        <>
          {translate('reset-password')} <Icon name="refresh" />
        </>
      ),
      show: isSuperAdmin(),
    },
    {
      id: 'unlock-user',
      action: () => openModal(ModalType.USER_UNLOCK),
      children: (
        <>
          {translate('unlock-user')} <Icon name="unlock" />
        </>
      ),
      show: data?.user?.isLocked && isSuperAdmin(),
    },
    {
      id: 'lock-user',
      action: () => openModal(ModalType.USER_LOCK),
      children: (
        <>
          {translate('lock-user')} <Icon name="lock" />
        </>
      ),
      show:
        !data?.user?.isLocked &&
        isSuperAdmin() &&
        data?.user?.id !== authState.user?.id,
    },
    {
      id: 'restore-user',
      action: () => openModal(ModalType.USER_RESTORE),
      children: (
        <>
          {translate('restore-user')}
          <Icon color="green" name="triangle up" />
        </>
      ),
      show: data?.user?.isDeleted && isSuperAdmin(),
    },
    {
      id: 'delete-user',
      action: () => openModal(ModalType.USER_DELETE),
      children: (
        <>
          {translate('delete-user')} <Icon color="red" name="trash" />
        </>
      ),
      show:
        !data?.user?.isDeleted &&
        isSuperAdmin() &&
        data?.user?.id !== authState.user?.id,
    },
  ]

  return { actions: data?.user ? actions : [], loading }
}

export default useUserActions
