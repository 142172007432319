import React from 'react'
import { TagColor, TagFragment } from 'generated/graphql'
import { Icon, Label, Popup } from 'semantic-ui-react'
import tagColorToSemanticColors from 'utils/tagColorToSemanticColors'
import { Optional } from 'utils/optional'

type Props = {
  tag: Optional<TagFragment, 'name' | 'id'>
  label?: boolean
}

export default function Tag({ tag, label }: Props) {
  if (tag.color === TagColor.Favorite) {
    return <Icon size="small" name="favorite" color="yellow"></Icon>
  }
  return !label ? (
    <Popup
      size="mini"
      position="top center"
      content={tag.name}
      trigger={
        <Icon
          color={tagColorToSemanticColors(tag.color)}
          size="small"
          name="square full"
        ></Icon>
      }
    />
  ) : (
    <Label
      color={tagColorToSemanticColors(tag.color)}
      horizontal
      label
      size="medium"
    >
      {tag.name}
    </Label>
  )
}
