import React from 'react'
import { useDeleteUserMutation } from 'generated/graphql'
import { useStore } from 'laco-react'
import UsersStore, { LacoUsers } from 'store/usersStore'
import useTranslate from 'hooks/useTranslate'
import { Button } from 'semantic-ui-react'
import { hideModal } from 'store/modalStore'

type Props = {
  isDelete: boolean
}

export default function DeleteUserButton({ isDelete }: Props) {
  const [deleteUser] = useDeleteUserMutation()
  const { selected }: LacoUsers = useStore(UsersStore)
  const translate = useTranslate()

  return (
    <Button
      color={isDelete ? 'red' : 'green'}
      onClick={() => {
        deleteUser({
          variables: { id: selected as string },
        })
        hideModal()
      }}
    >
      {translate(isDelete ? 'delete-user' : 'restore-user')}
    </Button>
  )
}
