import React, { useState } from 'react'
import { Container, Button, Loader } from 'semantic-ui-react'
import useTranslate from 'hooks/useTranslate'
import {
  UserTableRowFragment,
  useUsersQuery,
  useChangeOwnerMutation,
} from 'generated/graphql'
import { useStore } from 'laco-react'
import DataTable, { IDataTableColumn } from 'react-data-table-component'
import { hideModal } from 'store/modalStore'
import DocumentStore, { LacoDocument } from 'store/documentStore'

const userColumns: (
  translate: Function
) => IDataTableColumn<UserTableRowFragment>[] = (translate) => [
  { name: translate('id'), selector: 'id', grow: 3 },
  { name: translate('name'), selector: 'name', grow: 3, wrap: true },
  { name: translate('email'), selector: 'email', grow: 3, wrap: true },
]

export default function ChangeOwner() {
  const translate = useTranslate()
  const { selectedDocumentInFolder, currentFolder }: LacoDocument = useStore(
    DocumentStore
  )
  const selectedDocument = selectedDocumentInFolder[currentFolder]
  const [changeOwner] = useChangeOwnerMutation()

  // todo: improve filter based on userGroups / superAdmin
  const { data, loading } = useUsersQuery({})

  const [selectedUser, setSelectedUser] = useState<
    UserTableRowFragment['id'] | null
  >(null)

  function handleOwnerChange() {
    changeOwner({
      variables: {
        userId: selectedUser as string,
        documentId: selectedDocument as string,
      },
    })
    hideModal()
  }

  return (
    <Container>
      <DataTable
        data={data?.users.slice() as UserTableRowFragment[]}
        columns={userColumns(translate)}
        progressPending={loading}
        progressComponent={Loader}
        noHeader
        striped
        highlightOnHover
        onRowClicked={(row) => {
          if (row.id === selectedUser) {
            setSelectedUser(null)
          }
          setSelectedUser(row.id)
        }}
        selectableRowsHighlight
        selectableRowSelected={(row) => row.id === selectedUser}
      />
      <Button fluid primary onClick={handleOwnerChange}>
        {translate('change-owner')}
      </Button>
    </Container>
  )
}
