import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Container, Form, Input } from 'semantic-ui-react'
import { useUserQuery, useUpdateUserMutation } from 'generated/graphql'
import { hideModal } from 'store/modalStore'
import UsersStore, { LacoUsers } from 'store/usersStore'
import { useStore } from 'laco-react'
import useTranslate from 'hooks/useTranslate'
import Error from 'components/common/Error'

export default function EditUser() {
  const usersState: LacoUsers = useStore(UsersStore)
  const translate = useTranslate()
  const [updateUser] = useUpdateUserMutation()
  const { data, loading, error } = useUserQuery({
    variables: { id: usersState.selected as string },
  })
  const { handleSubmit, control, formState } = useForm({
    mode: 'onChange',
  })

  async function onSubmit(formValues: any) {
    try {
      await updateUser({
        variables: { ...formValues, id: usersState.selected as string },
      })

      hideModal()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)} loading={loading}>
        <Form.Field>
          <Controller
            as={<Input placeholder={translate('name')} />}
            rules={{
              required: true,
            }}
            name="name"
            control={control}
            defaultValue={data?.user?.name}
            autoFocus
          />
        </Form.Field>

        <Form.Field>
          <Controller
            as={<Input placeholder={translate('email')} type="email" />}
            rules={{
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'invalid email address',
              },
              required: false,
            }}
            name="email"
            control={control}
            defaultValue={data?.user?.email}
          />
        </Form.Field>

        <Form.Button
          color="blue"
          disabled={!formState.isValid}
          fluid
          size="big"
        >
          {translate('edit-user')}
        </Form.Button>
      </Form>
      <Error error={error}></Error>
    </Container>
  )
}
