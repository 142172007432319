import { useState, useEffect } from 'react'

const uri =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : process.env.REACT_APP_API_URL

export default function useApi(path) {
  const [result, setResult] = useState<any>()
  const [error, setError] = useState<any>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    setError(null)
    setResult(null)

    async function job() {
      try {
        const resp = await fetch(`${uri}/${path}`)

        const json = await resp.json()

        setResult(json)
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }

    job()
  }, [path])

  return { result, loading, error }
}
