import React from 'react'
import { DocumentTableRowFragment } from 'generated/graphql'

const MimeImage = ({ row }: { row: DocumentTableRowFragment }) => {
  let type = row.mimeType.replace('/', '-')

  if (row.mimeType.includes('folder')) {
    type = 'folder'
  } else if (row.mimeType.includes('csv')) {
    type = 'application-vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  }

  return <img src={`/mime/${type}.svg`} height="30"></img>
}

export default MimeImage
