import PropTypes from 'prop-types'
import React, { useContext, useReducer } from 'react'
import useApi from 'hooks/useApi'

// To make it easier to read from JSON files
const translations = {
  en: null,
  ru: null,
}

function formatUnicorn(template: string, ...args: string[]): string {
  let str = template.toString()

  if (args.length) {
    for (const arg in args)
      str = str.replace(new RegExp(`\\{${arg}\\}`, 'gi'), args[arg])
  }

  return str
}

// This function will be used to create `translate` function for the context
const getTranslate = (langCode: string) => (key: string, ...args: string[]) => {
  const translateObject = translations[langCode]

  const translationTemplate: string = key
    .split('.')
    .reduce((acc, curr) => (acc ? acc[curr] : null), translateObject)

  if (!translationTemplate) {
    // console.warn(`Missing translation [${langCode}]: ${key}`)
  }

  return formatUnicorn(translationTemplate || key, ...args)
}

type State = {
  langCode: string
  translate: (key: string, ...args: string[]) => string
  dispatch?: React.Dispatch<{ type: string; payload: any }>
}

/* We will have two things in our context state,
langCode will be the current language of the page
and translate will be the method to translate keys
into meaningful texts. Default language will be English */
const initialState: State = {
  langCode: localStorage.getItem('language') || 'en',
  translate: getTranslate(localStorage.getItem('language') || 'en'),
}

const I18nContext = React.createContext(initialState)

export const I18nContextProvider = ({ children }) => {
  /* This is where magic starts to happen. We're creating
  a reducer to manage the global state which will sit in
  I18nContext. For now, the only action we will have
  is setting language */
  const reducer = (state: State, action: { type: string; payload: any }) => {
    switch (action.type) {
      case 'setLanguage':
        return {
          langCode: action.payload,
          translate: getTranslate(action.payload),
        }
      default:
        return { ...initialState }
    }
  }

  /* useReducer hook receives a reducer and an initialState to
  return the current state object with a dispatch method to
  dispatch actions. */
  const [state, dispatch] = useReducer(reducer, initialState)

  const { loading, result } = useApi(
    `lang/${state.langCode.toUpperCase()}.json`
  )

  translations[state.langCode] = result

  if (loading) {
    return null
  }

  /* We're Providing state object (langCode and translate method
  in this case) and also the dispatch for the children components */
  return (
    <I18nContext.Provider value={{ ...state, dispatch }}>
      {children}
    </I18nContext.Provider>
  )
}

I18nContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export function useSetLanguage() {
  const { dispatch } = useContext(I18nContext) as {
    dispatch: React.Dispatch<{
      type: string
      payload: any
    }>
  }

  /* We will dispatch an action to set the language with the
  value of <select /> component. This will also change the
  translate method in the context to translate keys into
  the language we select */
  const onLanguageSelect = (langCode: string) =>
    dispatch({ type: 'setLanguage', payload: langCode })

  return {
    onLanguageSelect,
    langCodes: Object.keys(translations),
  }
}

export default function useTranslate() {
  const { translate } = useContext(I18nContext)

  return translate
}

export let doTranslate: (key: string, ...args: string[]) => string
