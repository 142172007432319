import React from 'react'
import { Menu, MenuProps } from 'semantic-ui-react'

interface Props extends MenuProps {
  show?: boolean
}

export default function ConditionalMenuItem({ show, ...props }: Props) {
  if (!show) {
    return null
  }

  return <Menu.Item {...props}></Menu.Item>
}
