import gql from 'graphql-tag'
import * as ApolloReactCommon from '@apollo/react-common'
import * as ApolloReactHooks from '@apollo/react-hooks'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  DateTime: Date
  /** The `Upload` scalar type represents a file upload. */
  Upload: any
}

export type Query = {
  documentAccesses: Array<Access>
  myDocumentAccesses: UserDocumentAccess
  document: Document
  listDocuments: Array<Document>
  findDocuments: FindDocumentsResponse
  breadcrumb: Array<DocumentBreadcrumb>
  documentHistory: LogResponse
  localizations: Array<Localization>
  logs: LogResponse
  userTags: Array<Tag>
  user?: Maybe<User>
  users: Array<User>
  userGroup?: Maybe<UserGroup>
  userGroups: Array<UserGroup>
}

export type QueryDocumentAccessesArgs = {
  documentId: Scalars['ID']
}

export type QueryMyDocumentAccessesArgs = {
  documentId: Scalars['ID']
}

export type QueryDocumentArgs = {
  id: Scalars['ID']
}

export type QueryListDocumentsArgs = {
  folderId: Scalars['ID']
  isDeleted?: Maybe<Scalars['Boolean']>
}

export type QueryFindDocumentsArgs = {
  name?: Maybe<Scalars['String']>
  tagId?: Maybe<Scalars['ID']>
  isLocked?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  count?: Maybe<Scalars['Int']>
}

export type QueryBreadcrumbArgs = {
  folderId?: Maybe<Scalars['ID']>
}

export type QueryDocumentHistoryArgs = {
  documentId?: Maybe<Scalars['ID']>
  page?: Maybe<Scalars['Int']>
  count?: Maybe<Scalars['Int']>
}

export type QueryLogsArgs = {
  type: LogType
  operatorId?: Maybe<Scalars['ID']>
  createdBefore?: Maybe<Scalars['DateTime']>
  createdAfter?: Maybe<Scalars['DateTime']>
  userId?: Maybe<Scalars['ID']>
  userGroupId?: Maybe<Scalars['ID']>
  documentId?: Maybe<Scalars['ID']>
  page?: Maybe<Scalars['Int']>
  count?: Maybe<Scalars['Int']>
}

export type QueryUserArgs = {
  where: UserWhereUniqueInput
}

export type QueryUsersArgs = {
  where?: Maybe<UserWhereInput>
  orderBy?: Maybe<UserOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
}

export type QueryUserGroupArgs = {
  where: UserGroupWhereUniqueInput
}

export type QueryUserGroupsArgs = {
  where?: Maybe<UserGroupWhereInput>
  orderBy?: Maybe<UserGroupOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
}

export type Access = {
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  createdBy: User
  user?: Maybe<User>
  userGroup?: Maybe<UserGroup>
  combinationId: Scalars['String']
  document: Document
  isRead: Scalars['Boolean']
  isWrite: Scalars['Boolean']
  isControl: Scalars['Boolean']
}

export type User = {
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  email?: Maybe<Scalars['String']>
  name: Scalars['String']
  isAdmin: Scalars['Boolean']
  isLocked: Scalars['Boolean']
  language: Language
  userGroups?: Maybe<Array<UserGroup>>
  isDeleted: Scalars['Boolean']
  failCount: Scalars['Int']
}

export type UserUserGroupsArgs = {
  where?: Maybe<UserGroupWhereInput>
  orderBy?: Maybe<UserGroupOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
}

export enum Language {
  En = 'EN',
  Ru = 'RU',
}

export type UserGroupWhereInput = {
  id?: Maybe<Scalars['ID']>
  id_not?: Maybe<Scalars['ID']>
  id_in?: Maybe<Array<Scalars['ID']>>
  id_not_in?: Maybe<Array<Scalars['ID']>>
  id_lt?: Maybe<Scalars['ID']>
  id_lte?: Maybe<Scalars['ID']>
  id_gt?: Maybe<Scalars['ID']>
  id_gte?: Maybe<Scalars['ID']>
  id_contains?: Maybe<Scalars['ID']>
  id_not_contains?: Maybe<Scalars['ID']>
  id_starts_with?: Maybe<Scalars['ID']>
  id_not_starts_with?: Maybe<Scalars['ID']>
  id_ends_with?: Maybe<Scalars['ID']>
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedAt_not?: Maybe<Scalars['DateTime']>
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  createdBy?: Maybe<UserWhereInput>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Scalars['String']>>
  name_not_in?: Maybe<Array<Scalars['String']>>
  name_lt?: Maybe<Scalars['String']>
  name_lte?: Maybe<Scalars['String']>
  name_gt?: Maybe<Scalars['String']>
  name_gte?: Maybe<Scalars['String']>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  name_starts_with?: Maybe<Scalars['String']>
  name_not_starts_with?: Maybe<Scalars['String']>
  name_ends_with?: Maybe<Scalars['String']>
  name_not_ends_with?: Maybe<Scalars['String']>
  users_every?: Maybe<UserWhereInput>
  users_some?: Maybe<UserWhereInput>
  users_none?: Maybe<UserWhereInput>
  isDeleted?: Maybe<Scalars['Boolean']>
  isDeleted_not?: Maybe<Scalars['Boolean']>
  AND?: Maybe<Array<UserGroupWhereInput>>
  OR?: Maybe<Array<UserGroupWhereInput>>
  NOT?: Maybe<Array<UserGroupWhereInput>>
}

export type UserWhereInput = {
  id?: Maybe<Scalars['ID']>
  id_not?: Maybe<Scalars['ID']>
  id_in?: Maybe<Array<Scalars['ID']>>
  id_not_in?: Maybe<Array<Scalars['ID']>>
  id_lt?: Maybe<Scalars['ID']>
  id_lte?: Maybe<Scalars['ID']>
  id_gt?: Maybe<Scalars['ID']>
  id_gte?: Maybe<Scalars['ID']>
  id_contains?: Maybe<Scalars['ID']>
  id_not_contains?: Maybe<Scalars['ID']>
  id_starts_with?: Maybe<Scalars['ID']>
  id_not_starts_with?: Maybe<Scalars['ID']>
  id_ends_with?: Maybe<Scalars['ID']>
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedAt_not?: Maybe<Scalars['DateTime']>
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  email?: Maybe<Scalars['String']>
  email_not?: Maybe<Scalars['String']>
  email_in?: Maybe<Array<Scalars['String']>>
  email_not_in?: Maybe<Array<Scalars['String']>>
  email_lt?: Maybe<Scalars['String']>
  email_lte?: Maybe<Scalars['String']>
  email_gt?: Maybe<Scalars['String']>
  email_gte?: Maybe<Scalars['String']>
  email_contains?: Maybe<Scalars['String']>
  email_not_contains?: Maybe<Scalars['String']>
  email_starts_with?: Maybe<Scalars['String']>
  email_not_starts_with?: Maybe<Scalars['String']>
  email_ends_with?: Maybe<Scalars['String']>
  email_not_ends_with?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Scalars['String']>>
  name_not_in?: Maybe<Array<Scalars['String']>>
  name_lt?: Maybe<Scalars['String']>
  name_lte?: Maybe<Scalars['String']>
  name_gt?: Maybe<Scalars['String']>
  name_gte?: Maybe<Scalars['String']>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  name_starts_with?: Maybe<Scalars['String']>
  name_not_starts_with?: Maybe<Scalars['String']>
  name_ends_with?: Maybe<Scalars['String']>
  name_not_ends_with?: Maybe<Scalars['String']>
  isAdmin?: Maybe<Scalars['Boolean']>
  isAdmin_not?: Maybe<Scalars['Boolean']>
  password?: Maybe<Scalars['String']>
  password_not?: Maybe<Scalars['String']>
  password_in?: Maybe<Array<Scalars['String']>>
  password_not_in?: Maybe<Array<Scalars['String']>>
  password_lt?: Maybe<Scalars['String']>
  password_lte?: Maybe<Scalars['String']>
  password_gt?: Maybe<Scalars['String']>
  password_gte?: Maybe<Scalars['String']>
  password_contains?: Maybe<Scalars['String']>
  password_not_contains?: Maybe<Scalars['String']>
  password_starts_with?: Maybe<Scalars['String']>
  password_not_starts_with?: Maybe<Scalars['String']>
  password_ends_with?: Maybe<Scalars['String']>
  password_not_ends_with?: Maybe<Scalars['String']>
  isLocked?: Maybe<Scalars['Boolean']>
  isLocked_not?: Maybe<Scalars['Boolean']>
  language?: Maybe<Language>
  language_not?: Maybe<Language>
  language_in?: Maybe<Array<Language>>
  language_not_in?: Maybe<Array<Language>>
  userGroups_every?: Maybe<UserGroupWhereInput>
  userGroups_some?: Maybe<UserGroupWhereInput>
  userGroups_none?: Maybe<UserGroupWhereInput>
  isDeleted?: Maybe<Scalars['Boolean']>
  isDeleted_not?: Maybe<Scalars['Boolean']>
  failCount?: Maybe<Scalars['Int']>
  failCount_not?: Maybe<Scalars['Int']>
  failCount_in?: Maybe<Array<Scalars['Int']>>
  failCount_not_in?: Maybe<Array<Scalars['Int']>>
  failCount_lt?: Maybe<Scalars['Int']>
  failCount_lte?: Maybe<Scalars['Int']>
  failCount_gt?: Maybe<Scalars['Int']>
  failCount_gte?: Maybe<Scalars['Int']>
  AND?: Maybe<Array<UserWhereInput>>
  OR?: Maybe<Array<UserWhereInput>>
  NOT?: Maybe<Array<UserWhereInput>>
}

export enum UserGroupOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IsDeletedAsc = 'isDeleted_ASC',
  IsDeletedDesc = 'isDeleted_DESC',
}

export type UserGroup = {
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  createdBy: User
  name: Scalars['String']
  users?: Maybe<Array<User>>
  isDeleted: Scalars['Boolean']
}

export type UserGroupUsersArgs = {
  where?: Maybe<UserWhereInput>
  orderBy?: Maybe<UserOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
}

export enum UserOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IsAdminAsc = 'isAdmin_ASC',
  IsAdminDesc = 'isAdmin_DESC',
  PasswordAsc = 'password_ASC',
  PasswordDesc = 'password_DESC',
  IsLockedAsc = 'isLocked_ASC',
  IsLockedDesc = 'isLocked_DESC',
  LanguageAsc = 'language_ASC',
  LanguageDesc = 'language_DESC',
  IsDeletedAsc = 'isDeleted_ASC',
  IsDeletedDesc = 'isDeleted_DESC',
  FailCountAsc = 'failCount_ASC',
  FailCountDesc = 'failCount_DESC',
}

export type Document = {
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  createdBy: User
  name: Scalars['String']
  canonicalName: Scalars['String']
  mimeType: Scalars['String']
  type: DocumentType
  owner: User
  parent?: Maybe<Document>
  entry?: Maybe<Entry>
  versions?: Maybe<Array<Entry>>
  accesses?: Maybe<Array<Access>>
  isLocked: Scalars['Boolean']
  lockedBy?: Maybe<User>
  lockedAt?: Maybe<Scalars['DateTime']>
  lastOpenedBy?: Maybe<User>
  lastOpenedAt?: Maybe<Scalars['DateTime']>
  lastEditedBy?: Maybe<User>
  lastEditedAt?: Maybe<Scalars['DateTime']>
  lastDeletedBy?: Maybe<User>
  lastDeletedAt?: Maybe<Scalars['DateTime']>
  lastRestoredBy?: Maybe<User>
  lastRestoredAt?: Maybe<Scalars['DateTime']>
  isDeleted: Scalars['Boolean']
  isArchived: Scalars['Boolean']
  tags: Array<Tag>
}

export type DocumentVersionsArgs = {
  where?: Maybe<EntryWhereInput>
  orderBy?: Maybe<EntryOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
}

export type DocumentAccessesArgs = {
  where?: Maybe<AccessWhereInput>
  orderBy?: Maybe<AccessOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
}

export enum DocumentType {
  File = 'File',
  Folder = 'Folder',
}

export type Entry = {
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  createdBy: User
  size: Scalars['String']
}

export type EntryWhereInput = {
  id?: Maybe<Scalars['ID']>
  id_not?: Maybe<Scalars['ID']>
  id_in?: Maybe<Array<Scalars['ID']>>
  id_not_in?: Maybe<Array<Scalars['ID']>>
  id_lt?: Maybe<Scalars['ID']>
  id_lte?: Maybe<Scalars['ID']>
  id_gt?: Maybe<Scalars['ID']>
  id_gte?: Maybe<Scalars['ID']>
  id_contains?: Maybe<Scalars['ID']>
  id_not_contains?: Maybe<Scalars['ID']>
  id_starts_with?: Maybe<Scalars['ID']>
  id_not_starts_with?: Maybe<Scalars['ID']>
  id_ends_with?: Maybe<Scalars['ID']>
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  document?: Maybe<DocumentWhereInput>
  createdBy?: Maybe<UserWhereInput>
  url?: Maybe<Scalars['String']>
  url_not?: Maybe<Scalars['String']>
  url_in?: Maybe<Array<Scalars['String']>>
  url_not_in?: Maybe<Array<Scalars['String']>>
  url_lt?: Maybe<Scalars['String']>
  url_lte?: Maybe<Scalars['String']>
  url_gt?: Maybe<Scalars['String']>
  url_gte?: Maybe<Scalars['String']>
  url_contains?: Maybe<Scalars['String']>
  url_not_contains?: Maybe<Scalars['String']>
  url_starts_with?: Maybe<Scalars['String']>
  url_not_starts_with?: Maybe<Scalars['String']>
  url_ends_with?: Maybe<Scalars['String']>
  url_not_ends_with?: Maybe<Scalars['String']>
  size?: Maybe<Scalars['String']>
  size_not?: Maybe<Scalars['String']>
  size_in?: Maybe<Array<Scalars['String']>>
  size_not_in?: Maybe<Array<Scalars['String']>>
  size_lt?: Maybe<Scalars['String']>
  size_lte?: Maybe<Scalars['String']>
  size_gt?: Maybe<Scalars['String']>
  size_gte?: Maybe<Scalars['String']>
  size_contains?: Maybe<Scalars['String']>
  size_not_contains?: Maybe<Scalars['String']>
  size_starts_with?: Maybe<Scalars['String']>
  size_not_starts_with?: Maybe<Scalars['String']>
  size_ends_with?: Maybe<Scalars['String']>
  size_not_ends_with?: Maybe<Scalars['String']>
  AND?: Maybe<Array<EntryWhereInput>>
  OR?: Maybe<Array<EntryWhereInput>>
  NOT?: Maybe<Array<EntryWhereInput>>
}

export type DocumentWhereInput = {
  id?: Maybe<Scalars['ID']>
  id_not?: Maybe<Scalars['ID']>
  id_in?: Maybe<Array<Scalars['ID']>>
  id_not_in?: Maybe<Array<Scalars['ID']>>
  id_lt?: Maybe<Scalars['ID']>
  id_lte?: Maybe<Scalars['ID']>
  id_gt?: Maybe<Scalars['ID']>
  id_gte?: Maybe<Scalars['ID']>
  id_contains?: Maybe<Scalars['ID']>
  id_not_contains?: Maybe<Scalars['ID']>
  id_starts_with?: Maybe<Scalars['ID']>
  id_not_starts_with?: Maybe<Scalars['ID']>
  id_ends_with?: Maybe<Scalars['ID']>
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedAt_not?: Maybe<Scalars['DateTime']>
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  createdBy?: Maybe<UserWhereInput>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Scalars['String']>>
  name_not_in?: Maybe<Array<Scalars['String']>>
  name_lt?: Maybe<Scalars['String']>
  name_lte?: Maybe<Scalars['String']>
  name_gt?: Maybe<Scalars['String']>
  name_gte?: Maybe<Scalars['String']>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  name_starts_with?: Maybe<Scalars['String']>
  name_not_starts_with?: Maybe<Scalars['String']>
  name_ends_with?: Maybe<Scalars['String']>
  name_not_ends_with?: Maybe<Scalars['String']>
  canonicalName?: Maybe<Scalars['String']>
  canonicalName_not?: Maybe<Scalars['String']>
  canonicalName_in?: Maybe<Array<Scalars['String']>>
  canonicalName_not_in?: Maybe<Array<Scalars['String']>>
  canonicalName_lt?: Maybe<Scalars['String']>
  canonicalName_lte?: Maybe<Scalars['String']>
  canonicalName_gt?: Maybe<Scalars['String']>
  canonicalName_gte?: Maybe<Scalars['String']>
  canonicalName_contains?: Maybe<Scalars['String']>
  canonicalName_not_contains?: Maybe<Scalars['String']>
  canonicalName_starts_with?: Maybe<Scalars['String']>
  canonicalName_not_starts_with?: Maybe<Scalars['String']>
  canonicalName_ends_with?: Maybe<Scalars['String']>
  canonicalName_not_ends_with?: Maybe<Scalars['String']>
  mimeType?: Maybe<Scalars['String']>
  mimeType_not?: Maybe<Scalars['String']>
  mimeType_in?: Maybe<Array<Scalars['String']>>
  mimeType_not_in?: Maybe<Array<Scalars['String']>>
  mimeType_lt?: Maybe<Scalars['String']>
  mimeType_lte?: Maybe<Scalars['String']>
  mimeType_gt?: Maybe<Scalars['String']>
  mimeType_gte?: Maybe<Scalars['String']>
  mimeType_contains?: Maybe<Scalars['String']>
  mimeType_not_contains?: Maybe<Scalars['String']>
  mimeType_starts_with?: Maybe<Scalars['String']>
  mimeType_not_starts_with?: Maybe<Scalars['String']>
  mimeType_ends_with?: Maybe<Scalars['String']>
  mimeType_not_ends_with?: Maybe<Scalars['String']>
  type?: Maybe<DocumentType>
  type_not?: Maybe<DocumentType>
  type_in?: Maybe<Array<DocumentType>>
  type_not_in?: Maybe<Array<DocumentType>>
  owner?: Maybe<UserWhereInput>
  parent?: Maybe<DocumentWhereInput>
  entry?: Maybe<EntryWhereInput>
  versions_every?: Maybe<EntryWhereInput>
  versions_some?: Maybe<EntryWhereInput>
  versions_none?: Maybe<EntryWhereInput>
  accesses_every?: Maybe<AccessWhereInput>
  accesses_some?: Maybe<AccessWhereInput>
  accesses_none?: Maybe<AccessWhereInput>
  isLocked?: Maybe<Scalars['Boolean']>
  isLocked_not?: Maybe<Scalars['Boolean']>
  lockedBy?: Maybe<UserWhereInput>
  lockedAt?: Maybe<Scalars['DateTime']>
  lockedAt_not?: Maybe<Scalars['DateTime']>
  lockedAt_in?: Maybe<Array<Scalars['DateTime']>>
  lockedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  lockedAt_lt?: Maybe<Scalars['DateTime']>
  lockedAt_lte?: Maybe<Scalars['DateTime']>
  lockedAt_gt?: Maybe<Scalars['DateTime']>
  lockedAt_gte?: Maybe<Scalars['DateTime']>
  lastOpenedBy?: Maybe<UserWhereInput>
  lastOpenedAt?: Maybe<Scalars['DateTime']>
  lastOpenedAt_not?: Maybe<Scalars['DateTime']>
  lastOpenedAt_in?: Maybe<Array<Scalars['DateTime']>>
  lastOpenedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  lastOpenedAt_lt?: Maybe<Scalars['DateTime']>
  lastOpenedAt_lte?: Maybe<Scalars['DateTime']>
  lastOpenedAt_gt?: Maybe<Scalars['DateTime']>
  lastOpenedAt_gte?: Maybe<Scalars['DateTime']>
  lastEditedBy?: Maybe<UserWhereInput>
  lastEditedAt?: Maybe<Scalars['DateTime']>
  lastEditedAt_not?: Maybe<Scalars['DateTime']>
  lastEditedAt_in?: Maybe<Array<Scalars['DateTime']>>
  lastEditedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  lastEditedAt_lt?: Maybe<Scalars['DateTime']>
  lastEditedAt_lte?: Maybe<Scalars['DateTime']>
  lastEditedAt_gt?: Maybe<Scalars['DateTime']>
  lastEditedAt_gte?: Maybe<Scalars['DateTime']>
  lastDeletedBy?: Maybe<UserWhereInput>
  lastDeletedAt?: Maybe<Scalars['DateTime']>
  lastDeletedAt_not?: Maybe<Scalars['DateTime']>
  lastDeletedAt_in?: Maybe<Array<Scalars['DateTime']>>
  lastDeletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  lastDeletedAt_lt?: Maybe<Scalars['DateTime']>
  lastDeletedAt_lte?: Maybe<Scalars['DateTime']>
  lastDeletedAt_gt?: Maybe<Scalars['DateTime']>
  lastDeletedAt_gte?: Maybe<Scalars['DateTime']>
  lastRestoredBy?: Maybe<UserWhereInput>
  lastRestoredAt?: Maybe<Scalars['DateTime']>
  lastRestoredAt_not?: Maybe<Scalars['DateTime']>
  lastRestoredAt_in?: Maybe<Array<Scalars['DateTime']>>
  lastRestoredAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  lastRestoredAt_lt?: Maybe<Scalars['DateTime']>
  lastRestoredAt_lte?: Maybe<Scalars['DateTime']>
  lastRestoredAt_gt?: Maybe<Scalars['DateTime']>
  lastRestoredAt_gte?: Maybe<Scalars['DateTime']>
  isDeleted?: Maybe<Scalars['Boolean']>
  isDeleted_not?: Maybe<Scalars['Boolean']>
  tags_every?: Maybe<TagWhereInput>
  tags_some?: Maybe<TagWhereInput>
  tags_none?: Maybe<TagWhereInput>
  AND?: Maybe<Array<DocumentWhereInput>>
  OR?: Maybe<Array<DocumentWhereInput>>
  NOT?: Maybe<Array<DocumentWhereInput>>
}

export type AccessWhereInput = {
  id?: Maybe<Scalars['ID']>
  id_not?: Maybe<Scalars['ID']>
  id_in?: Maybe<Array<Scalars['ID']>>
  id_not_in?: Maybe<Array<Scalars['ID']>>
  id_lt?: Maybe<Scalars['ID']>
  id_lte?: Maybe<Scalars['ID']>
  id_gt?: Maybe<Scalars['ID']>
  id_gte?: Maybe<Scalars['ID']>
  id_contains?: Maybe<Scalars['ID']>
  id_not_contains?: Maybe<Scalars['ID']>
  id_starts_with?: Maybe<Scalars['ID']>
  id_not_starts_with?: Maybe<Scalars['ID']>
  id_ends_with?: Maybe<Scalars['ID']>
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedAt_not?: Maybe<Scalars['DateTime']>
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  updatedAt_lt?: Maybe<Scalars['DateTime']>
  updatedAt_lte?: Maybe<Scalars['DateTime']>
  updatedAt_gt?: Maybe<Scalars['DateTime']>
  updatedAt_gte?: Maybe<Scalars['DateTime']>
  createdBy?: Maybe<UserWhereInput>
  user?: Maybe<UserWhereInput>
  userGroup?: Maybe<UserGroupWhereInput>
  combinationId?: Maybe<Scalars['String']>
  combinationId_not?: Maybe<Scalars['String']>
  combinationId_in?: Maybe<Array<Scalars['String']>>
  combinationId_not_in?: Maybe<Array<Scalars['String']>>
  combinationId_lt?: Maybe<Scalars['String']>
  combinationId_lte?: Maybe<Scalars['String']>
  combinationId_gt?: Maybe<Scalars['String']>
  combinationId_gte?: Maybe<Scalars['String']>
  combinationId_contains?: Maybe<Scalars['String']>
  combinationId_not_contains?: Maybe<Scalars['String']>
  combinationId_starts_with?: Maybe<Scalars['String']>
  combinationId_not_starts_with?: Maybe<Scalars['String']>
  combinationId_ends_with?: Maybe<Scalars['String']>
  combinationId_not_ends_with?: Maybe<Scalars['String']>
  document?: Maybe<DocumentWhereInput>
  isRead?: Maybe<Scalars['Boolean']>
  isRead_not?: Maybe<Scalars['Boolean']>
  isWrite?: Maybe<Scalars['Boolean']>
  isWrite_not?: Maybe<Scalars['Boolean']>
  isControl?: Maybe<Scalars['Boolean']>
  isControl_not?: Maybe<Scalars['Boolean']>
  AND?: Maybe<Array<AccessWhereInput>>
  OR?: Maybe<Array<AccessWhereInput>>
  NOT?: Maybe<Array<AccessWhereInput>>
}

export type TagWhereInput = {
  id?: Maybe<Scalars['ID']>
  id_not?: Maybe<Scalars['ID']>
  id_in?: Maybe<Array<Scalars['ID']>>
  id_not_in?: Maybe<Array<Scalars['ID']>>
  id_lt?: Maybe<Scalars['ID']>
  id_lte?: Maybe<Scalars['ID']>
  id_gt?: Maybe<Scalars['ID']>
  id_gte?: Maybe<Scalars['ID']>
  id_contains?: Maybe<Scalars['ID']>
  id_not_contains?: Maybe<Scalars['ID']>
  id_starts_with?: Maybe<Scalars['ID']>
  id_not_starts_with?: Maybe<Scalars['ID']>
  id_ends_with?: Maybe<Scalars['ID']>
  id_not_ends_with?: Maybe<Scalars['ID']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdAt_not?: Maybe<Scalars['DateTime']>
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>
  createdAt_lt?: Maybe<Scalars['DateTime']>
  createdAt_lte?: Maybe<Scalars['DateTime']>
  createdAt_gt?: Maybe<Scalars['DateTime']>
  createdAt_gte?: Maybe<Scalars['DateTime']>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Scalars['String']>>
  name_not_in?: Maybe<Array<Scalars['String']>>
  name_lt?: Maybe<Scalars['String']>
  name_lte?: Maybe<Scalars['String']>
  name_gt?: Maybe<Scalars['String']>
  name_gte?: Maybe<Scalars['String']>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  name_starts_with?: Maybe<Scalars['String']>
  name_not_starts_with?: Maybe<Scalars['String']>
  name_ends_with?: Maybe<Scalars['String']>
  name_not_ends_with?: Maybe<Scalars['String']>
  color?: Maybe<TagColor>
  color_not?: Maybe<TagColor>
  color_in?: Maybe<Array<TagColor>>
  color_not_in?: Maybe<Array<TagColor>>
  combinationId?: Maybe<Scalars['String']>
  combinationId_not?: Maybe<Scalars['String']>
  combinationId_in?: Maybe<Array<Scalars['String']>>
  combinationId_not_in?: Maybe<Array<Scalars['String']>>
  combinationId_lt?: Maybe<Scalars['String']>
  combinationId_lte?: Maybe<Scalars['String']>
  combinationId_gt?: Maybe<Scalars['String']>
  combinationId_gte?: Maybe<Scalars['String']>
  combinationId_contains?: Maybe<Scalars['String']>
  combinationId_not_contains?: Maybe<Scalars['String']>
  combinationId_starts_with?: Maybe<Scalars['String']>
  combinationId_not_starts_with?: Maybe<Scalars['String']>
  combinationId_ends_with?: Maybe<Scalars['String']>
  combinationId_not_ends_with?: Maybe<Scalars['String']>
  user?: Maybe<UserWhereInput>
  documents_every?: Maybe<DocumentWhereInput>
  documents_some?: Maybe<DocumentWhereInput>
  documents_none?: Maybe<DocumentWhereInput>
  AND?: Maybe<Array<TagWhereInput>>
  OR?: Maybe<Array<TagWhereInput>>
  NOT?: Maybe<Array<TagWhereInput>>
}

export enum TagColor {
  Red = 'Red',
  Orange = 'Orange',
  Yellow = 'Yellow',
  Olive = 'Olive',
  Green = 'Green',
  Teal = 'Teal',
  Blue = 'Blue',
  Violet = 'Violet',
  Purple = 'Purple',
  Pink = 'Pink',
  Brown = 'Brown',
  Grey = 'Grey',
  Black = 'Black',
  Favorite = 'Favorite',
}

export enum EntryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export enum AccessOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CombinationIdAsc = 'combinationId_ASC',
  CombinationIdDesc = 'combinationId_DESC',
  IsReadAsc = 'isRead_ASC',
  IsReadDesc = 'isRead_DESC',
  IsWriteAsc = 'isWrite_ASC',
  IsWriteDesc = 'isWrite_DESC',
  IsControlAsc = 'isControl_ASC',
  IsControlDesc = 'isControl_DESC',
}

export type Tag = {
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  name: Scalars['String']
  color: TagColor
  combinationId: Scalars['String']
  user?: Maybe<User>
  documents?: Maybe<Array<Document>>
}

export type TagDocumentsArgs = {
  where?: Maybe<DocumentWhereInput>
  orderBy?: Maybe<DocumentOrderByInput>
  skip?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
}

export enum DocumentOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CanonicalNameAsc = 'canonicalName_ASC',
  CanonicalNameDesc = 'canonicalName_DESC',
  MimeTypeAsc = 'mimeType_ASC',
  MimeTypeDesc = 'mimeType_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  IsLockedAsc = 'isLocked_ASC',
  IsLockedDesc = 'isLocked_DESC',
  LockedAtAsc = 'lockedAt_ASC',
  LockedAtDesc = 'lockedAt_DESC',
  LastOpenedAtAsc = 'lastOpenedAt_ASC',
  LastOpenedAtDesc = 'lastOpenedAt_DESC',
  LastEditedAtAsc = 'lastEditedAt_ASC',
  LastEditedAtDesc = 'lastEditedAt_DESC',
  LastDeletedAtAsc = 'lastDeletedAt_ASC',
  LastDeletedAtDesc = 'lastDeletedAt_DESC',
  LastRestoredAtAsc = 'lastRestoredAt_ASC',
  LastRestoredAtDesc = 'lastRestoredAt_DESC',
  IsDeletedAsc = 'isDeleted_ASC',
  IsDeletedDesc = 'isDeleted_DESC',
}

export type UserDocumentAccess = {
  read: Scalars['Boolean']
  write: Scalars['Boolean']
  control: Scalars['Boolean']
  owner: Scalars['Boolean']
}

export type FindDocumentsResponse = {
  documents: Array<Document>
  total: Scalars['Int']
}

export type DocumentBreadcrumb = {
  id: Scalars['ID']
  name: Scalars['String']
  parent?: Maybe<Scalars['ID']>
}

export type LogResponse = {
  logs: Array<Log>
  total: Scalars['Int']
}

export type Log = {
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  event: Scalars['String']
  operator: User
  document?: Maybe<Document>
  user?: Maybe<User>
  userGroup?: Maybe<UserGroup>
  param1: Scalars['String']
  param2: Scalars['String']
}

export type Localization = {
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  language: Language
  key: Scalars['String']
  value: Scalars['String']
  ukey: Scalars['String']
}

export enum LogType {
  User = 'User',
  UserGroup = 'UserGroup',
  Document = 'Document',
  Access = 'Access',
}

export type UserWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
}

export type UserGroupWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
}

export type Mutation = {
  modifyDocumentAccesses: Array<Access>
  createUser: AuthPayload
  login: AuthPayload
  renameDocument: Document
  deleteDocument: Document
  setOwner: Document
  moveDocument: Document
  openDocument: Document
  editDocument: Document
  setTags: Document
  copyDocument: Document
  lockFile: Document
  unlockFile: Document
  addFolder: Document
  createUploadUrl: PresignResponse
  createFile: Document
  updateFile: Document
  setTranslation: Localization
  createTag: Tag
  editTag: Tag
  deleteTag: Tag
  updatePassword: User
  deleteUser: User
  setUser: User
  setLanguage: User
  recordLogout: Scalars['Boolean']
  createUserGroup: UserGroup
  deleteUserGroup: UserGroup
  addUsers: UserGroup
  removeUsers: UserGroup
  updateUserGroup: UserGroup
}

export type MutationModifyDocumentAccessesArgs = {
  type: AccessType
  documentId: Scalars['ID']
  readIds?: Maybe<Array<Scalars['ID']>>
  writeIds?: Maybe<Array<Scalars['ID']>>
  controlIds?: Maybe<Array<Scalars['ID']>>
  recursive?: Maybe<Scalars['Boolean']>
}

export type MutationCreateUserArgs = {
  name: Scalars['String']
  email?: Maybe<Scalars['String']>
  password: Scalars['String']
}

export type MutationLoginArgs = {
  name?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
}

export type MutationRenameDocumentArgs = {
  name: Scalars['String']
  id: Scalars['ID']
}

export type MutationDeleteDocumentArgs = {
  id: Scalars['ID']
}

export type MutationSetOwnerArgs = {
  id: Scalars['ID']
  ownerId: Scalars['ID']
}

export type MutationMoveDocumentArgs = {
  id: Scalars['ID']
  parentId: Scalars['ID']
}

export type MutationOpenDocumentArgs = {
  id: Scalars['ID']
}

export type MutationEditDocumentArgs = {
  id: Scalars['ID']
}

export type MutationSetTagsArgs = {
  documentId: Scalars['ID']
  tagIds?: Maybe<Array<Scalars['ID']>>
}

export type MutationCopyDocumentArgs = {
  documentId: Scalars['ID']
  destinationFolderId: Scalars['ID']
  name: Scalars['String']
}

export type MutationLockFileArgs = {
  id: Scalars['ID']
}

export type MutationUnlockFileArgs = {
  id: Scalars['ID']
}

export type MutationAddFolderArgs = {
  name: Scalars['String']
  parentId?: Maybe<Scalars['ID']>
}

export type MutationCreateUploadUrlArgs = {
  name: Scalars['String']
  mimeType: Scalars['String']
}

export type MutationCreateFileArgs = {
  file: Scalars['Upload']
  mimeType: Scalars['String']
  size: Scalars['String']
  name: Scalars['String']
  parentId: Scalars['ID']
}

export type MutationUpdateFileArgs = {
  file: Scalars['Upload']
  size: Scalars['String']
  mimeType: Scalars['String']
  documentId: Scalars['ID']
}

export type MutationSetTranslationArgs = {
  language: Language
  key: Scalars['String']
  value: Scalars['String']
}

export type MutationCreateTagArgs = {
  documentId?: Maybe<Scalars['ID']>
  name: Scalars['String']
  color: TagColor
}

export type MutationEditTagArgs = {
  tagId: Scalars['ID']
  name: Scalars['String']
  color: TagColor
}

export type MutationDeleteTagArgs = {
  id: Scalars['ID']
}

export type MutationUpdatePasswordArgs = {
  userId: Scalars['ID']
  password: Scalars['String']
}

export type MutationDeleteUserArgs = {
  userId: Scalars['ID']
}

export type MutationSetUserArgs = {
  email?: Maybe<Scalars['String']>
  userId: Scalars['ID']
  isDeleted?: Maybe<Scalars['Boolean']>
  isLocked?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  isAdmin?: Maybe<Scalars['Boolean']>
}

export type MutationSetLanguageArgs = {
  language: Language
}

export type MutationCreateUserGroupArgs = {
  name: Scalars['String']
}

export type MutationDeleteUserGroupArgs = {
  id: Scalars['ID']
}

export type MutationAddUsersArgs = {
  userIds: Array<Scalars['ID']>
  id: Scalars['ID']
}

export type MutationRemoveUsersArgs = {
  userIds: Array<Scalars['ID']>
  id: Scalars['ID']
}

export type MutationUpdateUserGroupArgs = {
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
}

export enum AccessType {
  User = 'USER',
  UserGroup = 'USER_GROUP',
}

export type AuthPayload = {
  token: Scalars['String']
  user: User
}

export type PresignResponse = {
  url: Scalars['String']
  signedUrl: Scalars['String']
}

export type DocumentConnection = {
  pageInfo: PageInfo
  edges: Array<DocumentEdge>
  aggregate: AggregateDocument
}

export type PageInfo = {
  hasNextPage: Scalars['Boolean']
  hasPreviousPage: Scalars['Boolean']
  startCursor?: Maybe<Scalars['String']>
  endCursor?: Maybe<Scalars['String']>
}

export type DocumentEdge = {
  node: Document
  cursor: Scalars['String']
}

export type AggregateDocument = {
  count: Scalars['Int']
}

export type EntryConnection = {
  pageInfo: PageInfo
  edges: Array<EntryEdge>
  aggregate: AggregateEntry
}

export type EntryEdge = {
  node: Entry
  cursor: Scalars['String']
}

export type AggregateEntry = {
  count: Scalars['Int']
}

export enum UserLogEvent {
  CreateUser = 'CreateUser',
  UpdateUser = 'UpdateUser',
  DeleteUser = 'DeleteUser',
  RestoreUser = 'RestoreUser',
  LockUser = 'LockUser',
  UnlockUser = 'UnlockUser',
  LogIn = 'LogIn',
  LogOut = 'LogOut',
}

export enum DocumentLogEvent {
  CreateDocument = 'CreateDocument',
  CopyDocument = 'CopyDocument',
  MoveDocument = 'MoveDocument',
  DeleteDocument = 'DeleteDocument',
  OpenDocument = 'OpenDocument',
  EditDocument = 'EditDocument',
  UpdateDocument = 'UpdateDocument',
  RestoreDocument = 'RestoreDocument',
  RenameDocument = 'RenameDocument',
  SetDocumentOwner = 'SetDocumentOwner',
  LockFile = 'LockFile',
  UnlockFile = 'UnlockFile',
  AddAccess = 'AddAccess',
  RemoveAccess = 'RemoveAccess',
  AddRecAccess = 'AddRecAccess',
  RemoveRecAccess = 'RemoveRecAccess',
}

export enum UserGroupLogEvent {
  CreateUserGroup = 'CreateUserGroup',
  UpdateUserGroup = 'UpdateUserGroup',
  DeleteUserGroup = 'DeleteUserGroup',
  RestoreUserGroup = 'RestoreUserGroup',
  AddUserToUserGroup = 'AddUserToUserGroup',
  RemoveUserFromUserGroup = 'RemoveUserFromUserGroup',
}

export type UserConnection = {
  pageInfo: PageInfo
  edges: Array<UserEdge>
  aggregate: AggregateUser
}

export type UserEdge = {
  node: User
  cursor: Scalars['String']
}

export type AggregateUser = {
  count: Scalars['Int']
}

export type DocumentTableRowFragment = {
  id: string
  name: string
  canonicalName: string
  isLocked: boolean
  lockedAt?: Maybe<Date>
  mimeType: string
  type: DocumentType
  isDeleted: boolean
  isArchived: boolean
  lastOpenedAt?: Maybe<Date>
  lastEditedAt?: Maybe<Date>
  createdAt: Date
  updatedAt: Date
  lockedBy?: Maybe<{ id: string; name: string } & UserTableRowFragment>
  entry?: Maybe<{ id: string; size: string }>
  versions?: Maybe<
    Array<
      {
        id: string
        createdAt: Date
        size: string
        createdBy: { id: string; name: string }
      } & EntryFragment
    >
  >
  parent?: Maybe<{ id: string }>
  lastOpenedBy?: Maybe<{ id: string; name: string }>
  lastEditedBy?: Maybe<{ id: string; name: string }>
  owner: { id: string; name: string }
  tags: Array<TagFragment>
}

export type EntryFragment = {
  id: string
  size: string
  createdAt: Date
  createdBy: { id: string; name: string; email?: Maybe<string> }
}

export type DocumentAccessFragment = {
  isControl: boolean
  isRead: boolean
  isWrite: boolean
  user?: Maybe<{ id: string; name: string }>
  userGroup?: Maybe<{ id: string; name: string }>
}

export type DocumentHistoryFragment = {
  id: string
  createdAt: Date
  event: string
  param1: string
  param2: string
  operator: { id: string; name: string; email?: Maybe<string> }
}

export type AddFolderMutationVariables = Exact<{
  folderId?: Maybe<Scalars['ID']>
  name: Scalars['String']
}>

export type AddFolderMutation = { addFolder: DocumentTableRowFragment }

export type RenameDocumentMutationVariables = Exact<{
  id: Scalars['ID']
  name: Scalars['String']
}>

export type RenameDocumentMutation = {
  renameDocument: DocumentTableRowFragment
}

export type MoveDocumentMutationVariables = Exact<{
  documentId: Scalars['ID']
  parentId: Scalars['ID']
}>

export type MoveDocumentMutation = { moveDocument: DocumentTableRowFragment }

export type CopyDocumentMutationVariables = Exact<{
  documentId: Scalars['ID']
  destinationFolderId: Scalars['ID']
  name: Scalars['String']
}>

export type CopyDocumentMutation = { copyDocument: DocumentTableRowFragment }

export type DeleteDocumentMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteDocumentMutation = {
  deleteDocument: DocumentTableRowFragment
}

export type LockFileMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type LockFileMutation = { lockFile: DocumentTableRowFragment }

export type UnlockFileMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type UnlockFileMutation = { unlockFile: DocumentTableRowFragment }

export type OpenDocumentMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type OpenDocumentMutation = { openDocument: DocumentTableRowFragment }

export type EditDocumentMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type EditDocumentMutation = { editDocument: DocumentTableRowFragment }

export type CreateUploadUrlMutationVariables = Exact<{
  name: Scalars['String']
  mimeType: Scalars['String']
}>

export type CreateUploadUrlMutation = {
  createUploadUrl: { url: string; signedUrl: string }
}

export type UploadFileMutationVariables = Exact<{
  file: Scalars['Upload']
  mimeType: Scalars['String']
  size: Scalars['String']
  name: Scalars['String']
  folderId: Scalars['ID']
}>

export type UploadFileMutation = { createFile: DocumentTableRowFragment }

export type UpdateFileMutationVariables = Exact<{
  file: Scalars['Upload']
  mimeType: Scalars['String']
  size: Scalars['String']
  documentId: Scalars['ID']
}>

export type UpdateFileMutation = { updateFile: DocumentTableRowFragment }

export type ChangeOwnerMutationVariables = Exact<{
  documentId: Scalars['ID']
  userId: Scalars['ID']
}>

export type ChangeOwnerMutation = { setOwner: DocumentTableRowFragment }

export type SetTagsOnDocumentMutationVariables = Exact<{
  documentId: Scalars['ID']
  tagIds?: Maybe<Array<Scalars['ID']>>
}>

export type SetTagsOnDocumentMutation = { setTags: DocumentTableRowFragment }

export type ModifyDocumentUserAccessesMutationVariables = Exact<{
  documentId: Scalars['ID']
  readIds?: Maybe<Array<Scalars['ID']>>
  writeIds?: Maybe<Array<Scalars['ID']>>
  controlIds?: Maybe<Array<Scalars['ID']>>
  recursive?: Maybe<Scalars['Boolean']>
}>

export type ModifyDocumentUserAccessesMutation = {
  modifyDocumentAccesses: Array<{ id: string }>
}

export type ModifyDocumentUserGroupAccessesMutationVariables = Exact<{
  documentId: Scalars['ID']
  readIds?: Maybe<Array<Scalars['ID']>>
  writeIds?: Maybe<Array<Scalars['ID']>>
  controlIds?: Maybe<Array<Scalars['ID']>>
  recursive?: Maybe<Scalars['Boolean']>
}>

export type ModifyDocumentUserGroupAccessesMutation = {
  modifyDocumentAccesses: Array<{ id: string }>
}

export type FolderQueryVariables = Exact<{
  id: Scalars['ID']
  isDeleted?: Maybe<Scalars['Boolean']>
}>

export type FolderQuery = {
  listDocuments: Array<DocumentTableRowFragment>
  breadcrumb: Array<{ id: string; name: string; parent?: Maybe<string> }>
}

export type FindDocumentsQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>
  tagId?: Maybe<Scalars['ID']>
  isLocked?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  count?: Maybe<Scalars['Int']>
}>

export type FindDocumentsQuery = {
  findDocuments: { total: number; documents: Array<DocumentTableRowFragment> }
}

export type DocumentQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type DocumentQuery = {
  document: DocumentTableRowFragment
  access: { read: boolean; write: boolean; control: boolean; owner: boolean }
}

export type DocumentAccessesQueryVariables = Exact<{
  documentId: Scalars['ID']
}>

export type DocumentAccessesQuery = {
  documentAccesses: Array<DocumentAccessFragment>
}

export type DocumentHistoryQueryVariables = Exact<{
  documentId: Scalars['ID']
  page?: Maybe<Scalars['Int']>
  count?: Maybe<Scalars['Int']>
}>

export type DocumentHistoryQuery = {
  documentHistory: { total: number; logs: Array<DocumentHistoryFragment> }
}

export type LocalizationFragment = {
  id: string
  key: string
  value: string
  language: Language
}

export type ChangeTranslationMutationVariables = Exact<{
  language: Language
  key: Scalars['String']
  value: Scalars['String']
}>

export type ChangeTranslationMutation = { setTranslation: { id: string } }

export type LocalizationsQueryVariables = Exact<{ [key: string]: never }>

export type LocalizationsQuery = { localizations: Array<LocalizationFragment> }

export type LogFragment = {
  id: string
  createdAt: Date
  event: string
  param1: string
  param2: string
  operator: { id: string; name: string; email?: Maybe<string> }
  document?: Maybe<{ id: string; name: string }>
  user?: Maybe<{ id: string; name: string; email?: Maybe<string> }>
  userGroup?: Maybe<{ id: string; name: string }>
}

export type LogsQueryVariables = Exact<{
  type: LogType
  operatorId?: Maybe<Scalars['ID']>
  createdBefore?: Maybe<Scalars['DateTime']>
  createdAfter?: Maybe<Scalars['DateTime']>
  userId?: Maybe<Scalars['ID']>
  userGroupId?: Maybe<Scalars['ID']>
  documentId?: Maybe<Scalars['ID']>
  page?: Maybe<Scalars['Int']>
  count?: Maybe<Scalars['Int']>
}>

export type LogsQuery = { logs: { total: number; logs: Array<LogFragment> } }

export type TagFragment = { id: string; name: string; color: TagColor }

export type CreateTagMutationVariables = Exact<{
  documentId?: Maybe<Scalars['ID']>
  name: Scalars['String']
  color: TagColor
}>

export type CreateTagMutation = { createTag: TagFragment }

export type EditTagMutationVariables = Exact<{
  tagId: Scalars['ID']
  name: Scalars['String']
  color: TagColor
}>

export type EditTagMutation = { editTag: TagFragment }

export type DeleteTagMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteTagMutation = { deleteTag: TagFragment }

export type OwnTagsQueryVariables = Exact<{ [key: string]: never }>

export type OwnTagsQuery = {
  userTags: Array<{
    id: string
    name: string
    color: TagColor
    user?: Maybe<{ name: string }>
  }>
}

export type UserGroupTableRowFragment = {
  id: string
  name: string
  isDeleted: boolean
}

export type UserGroupFragment = {
  createdBy: { id: string; name: string; email?: Maybe<string> }
  users?: Maybe<Array<{ id: string; name: string; email?: Maybe<string> }>>
} & UserGroupTableRowFragment

export type CreateUserGroupMutationVariables = Exact<{
  name: Scalars['String']
}>

export type CreateUserGroupMutation = {
  createUserGroup: UserGroupTableRowFragment
}

export type DeleteUserGroupMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteUserGroupMutation = {
  deleteUserGroup: UserGroupTableRowFragment
}

export type UpdateUserGroupMutationVariables = Exact<{
  id: Scalars['ID']
  name: Scalars['String']
}>

export type UpdateUserGroupMutation = {
  updateUserGroup: UserGroupTableRowFragment
}

export type AddUsersToGroupMutationVariables = Exact<{
  id: Scalars['ID']
  userIds: Array<Scalars['ID']>
}>

export type AddUsersToGroupMutation = { addUsers: UserGroupFragment }

export type RemoveUsersFromGroupMutationVariables = Exact<{
  id: Scalars['ID']
  userIds: Array<Scalars['ID']>
}>

export type RemoveUsersFromGroupMutation = { removeUsers: UserGroupFragment }

export type UserGroupsQueryVariables = Exact<{ [key: string]: never }>

export type UserGroupsQuery = { userGroups: Array<UserGroupTableRowFragment> }

export type UserGroupQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type UserGroupQuery = { userGroup?: Maybe<UserGroupFragment> }

export type UserTableRowFragment = {
  id: string
  name: string
  email?: Maybe<string>
  isAdmin: boolean
  isLocked: boolean
  isDeleted: boolean
}

export type UserFragment = {
  language: Language
  createdAt: Date
  userGroups?: Maybe<Array<{ id: string }>>
} & UserTableRowFragment

export type LoginMutationVariables = Exact<{
  name: Scalars['String']
  password: Scalars['String']
}>

export type LoginMutation = { login: { token: string; user: UserFragment } }

export type CreateUserMutationVariables = Exact<{
  name: Scalars['String']
  email?: Maybe<Scalars['String']>
  password: Scalars['String']
}>

export type CreateUserMutation = { createUser: { user: UserFragment } }

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID']
  email?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  isDeleted?: Maybe<Scalars['Boolean']>
  isAdmin?: Maybe<Scalars['Boolean']>
  isLocked?: Maybe<Scalars['Boolean']>
}>

export type UpdateUserMutation = { setUser: UserTableRowFragment }

export type SetLanguageMutationVariables = Exact<{
  language: Language
}>

export type SetLanguageMutation = { setLanguage: UserFragment }

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteUserMutation = { deleteUser: UserFragment }

export type UpdatePasswordMutationVariables = Exact<{
  id: Scalars['ID']
  password: Scalars['String']
}>

export type UpdatePasswordMutation = { updatePassword: UserFragment }

export type RecordLogoutMutationVariables = Exact<{ [key: string]: never }>

export type RecordLogoutMutation = { recordLogout: boolean }

export type UsersQueryVariables = Exact<{
  where?: Maybe<UserWhereInput>
}>

export type UsersQuery = { users: Array<UserTableRowFragment> }

export type UserQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type UserQuery = { user?: Maybe<UserFragment> }

export const UserTableRowFragmentDoc = gql`
  fragment UserTableRow on User {
    id
    name
    email
    isAdmin
    isLocked
    isDeleted
  }
`
export const EntryFragmentDoc = gql`
  fragment Entry on Entry {
    id
    size
    createdAt
    createdBy {
      id
      name
      email
    }
  }
`
export const TagFragmentDoc = gql`
  fragment Tag on Tag {
    id
    name
    color
  }
`
export const DocumentTableRowFragmentDoc = gql`
  fragment DocumentTableRow on Document {
    id
    name
    canonicalName
    lockedBy {
      ...UserTableRow
      id
      name
    }
    isLocked
    lockedAt
    mimeType
    entry {
      id
      size
    }
    versions {
      id
      createdAt
      createdBy {
        id
        name
      }
      size
      ...Entry
    }
    type
    isDeleted
    isArchived
    parent {
      id
    }
    lastOpenedAt
    lastOpenedBy {
      id
      name
    }
    lastEditedAt
    lastEditedBy {
      id
      name
    }
    createdAt
    updatedAt
    owner {
      id
      name
    }
    lockedBy {
      id
      name
    }
    tags {
      ...Tag
    }
    versions {
      ...Entry
    }
  }
  ${UserTableRowFragmentDoc}
  ${EntryFragmentDoc}
  ${TagFragmentDoc}
`
export const DocumentAccessFragmentDoc = gql`
  fragment DocumentAccess on Access {
    isControl
    isRead
    isWrite
    user {
      id
      name
    }
    userGroup {
      id
      name
    }
  }
`
export const DocumentHistoryFragmentDoc = gql`
  fragment DocumentHistory on Log {
    id
    createdAt
    operator {
      id
      name
      email
    }
    event
    param1
    param2
  }
`
export const LocalizationFragmentDoc = gql`
  fragment Localization on Localization {
    id
    key
    value
    language
  }
`
export const LogFragmentDoc = gql`
  fragment Log on Log {
    id
    createdAt
    operator {
      id
      name
      email
    }
    document {
      id
      name
    }
    user {
      id
      name
      email
    }
    userGroup {
      id
      name
    }
    event
    param1
    param2
  }
`
export const UserGroupTableRowFragmentDoc = gql`
  fragment UserGroupTableRow on UserGroup {
    id
    name
    isDeleted
  }
`
export const UserGroupFragmentDoc = gql`
  fragment UserGroup on UserGroup {
    ...UserGroupTableRow
    createdBy {
      id
      name
      email
    }
    users {
      id
      name
      email
    }
  }
  ${UserGroupTableRowFragmentDoc}
`
export const UserFragmentDoc = gql`
  fragment User on User {
    ...UserTableRow
    userGroups {
      id
    }
    language
    createdAt
  }
  ${UserTableRowFragmentDoc}
`
export const AddFolderDocument = gql`
  mutation addFolder($folderId: ID, $name: String!) {
    addFolder(name: $name, parentId: $folderId) {
      ...DocumentTableRow
    }
  }
  ${DocumentTableRowFragmentDoc}
`
export type AddFolderMutationFn = ApolloReactCommon.MutationFunction<
  AddFolderMutation,
  AddFolderMutationVariables
>

/**
 * __useAddFolderMutation__
 *
 * To run a mutation, you first call `useAddFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFolderMutation, { data, loading, error }] = useAddFolderMutation({
 *   variables: {
 *      folderId: // value for 'folderId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddFolderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddFolderMutation,
    AddFolderMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddFolderMutation,
    AddFolderMutationVariables
  >(AddFolderDocument, baseOptions)
}
export type AddFolderMutationHookResult = ReturnType<
  typeof useAddFolderMutation
>
export type AddFolderMutationResult = ApolloReactCommon.MutationResult<
  AddFolderMutation
>
export type AddFolderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddFolderMutation,
  AddFolderMutationVariables
>
export const RenameDocumentDocument = gql`
  mutation renameDocument($id: ID!, $name: String!) {
    renameDocument(name: $name, id: $id) {
      ...DocumentTableRow
    }
  }
  ${DocumentTableRowFragmentDoc}
`
export type RenameDocumentMutationFn = ApolloReactCommon.MutationFunction<
  RenameDocumentMutation,
  RenameDocumentMutationVariables
>

/**
 * __useRenameDocumentMutation__
 *
 * To run a mutation, you first call `useRenameDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameDocumentMutation, { data, loading, error }] = useRenameDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRenameDocumentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RenameDocumentMutation,
    RenameDocumentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RenameDocumentMutation,
    RenameDocumentMutationVariables
  >(RenameDocumentDocument, baseOptions)
}
export type RenameDocumentMutationHookResult = ReturnType<
  typeof useRenameDocumentMutation
>
export type RenameDocumentMutationResult = ApolloReactCommon.MutationResult<
  RenameDocumentMutation
>
export type RenameDocumentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RenameDocumentMutation,
  RenameDocumentMutationVariables
>
export const MoveDocumentDocument = gql`
  mutation moveDocument($documentId: ID!, $parentId: ID!) {
    moveDocument(id: $documentId, parentId: $parentId) {
      ...DocumentTableRow
    }
  }
  ${DocumentTableRowFragmentDoc}
`
export type MoveDocumentMutationFn = ApolloReactCommon.MutationFunction<
  MoveDocumentMutation,
  MoveDocumentMutationVariables
>

/**
 * __useMoveDocumentMutation__
 *
 * To run a mutation, you first call `useMoveDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveDocumentMutation, { data, loading, error }] = useMoveDocumentMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useMoveDocumentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MoveDocumentMutation,
    MoveDocumentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    MoveDocumentMutation,
    MoveDocumentMutationVariables
  >(MoveDocumentDocument, baseOptions)
}
export type MoveDocumentMutationHookResult = ReturnType<
  typeof useMoveDocumentMutation
>
export type MoveDocumentMutationResult = ApolloReactCommon.MutationResult<
  MoveDocumentMutation
>
export type MoveDocumentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MoveDocumentMutation,
  MoveDocumentMutationVariables
>
export const CopyDocumentDocument = gql`
  mutation copyDocument(
    $documentId: ID!
    $destinationFolderId: ID!
    $name: String!
  ) {
    copyDocument(
      documentId: $documentId
      destinationFolderId: $destinationFolderId
      name: $name
    ) {
      ...DocumentTableRow
    }
  }
  ${DocumentTableRowFragmentDoc}
`
export type CopyDocumentMutationFn = ApolloReactCommon.MutationFunction<
  CopyDocumentMutation,
  CopyDocumentMutationVariables
>

/**
 * __useCopyDocumentMutation__
 *
 * To run a mutation, you first call `useCopyDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyDocumentMutation, { data, loading, error }] = useCopyDocumentMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      destinationFolderId: // value for 'destinationFolderId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCopyDocumentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CopyDocumentMutation,
    CopyDocumentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CopyDocumentMutation,
    CopyDocumentMutationVariables
  >(CopyDocumentDocument, baseOptions)
}
export type CopyDocumentMutationHookResult = ReturnType<
  typeof useCopyDocumentMutation
>
export type CopyDocumentMutationResult = ApolloReactCommon.MutationResult<
  CopyDocumentMutation
>
export type CopyDocumentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CopyDocumentMutation,
  CopyDocumentMutationVariables
>
export const DeleteDocumentDocument = gql`
  mutation deleteDocument($id: ID!) {
    deleteDocument(id: $id) {
      ...DocumentTableRow
    }
  }
  ${DocumentTableRowFragmentDoc}
`
export type DeleteDocumentMutationFn = ApolloReactCommon.MutationFunction<
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables
>

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocumentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables
  >(DeleteDocumentDocument, baseOptions)
}
export type DeleteDocumentMutationHookResult = ReturnType<
  typeof useDeleteDocumentMutation
>
export type DeleteDocumentMutationResult = ApolloReactCommon.MutationResult<
  DeleteDocumentMutation
>
export type DeleteDocumentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables
>
export const LockFileDocument = gql`
  mutation lockFile($id: ID!) {
    lockFile(id: $id) {
      ...DocumentTableRow
    }
  }
  ${DocumentTableRowFragmentDoc}
`
export type LockFileMutationFn = ApolloReactCommon.MutationFunction<
  LockFileMutation,
  LockFileMutationVariables
>

/**
 * __useLockFileMutation__
 *
 * To run a mutation, you first call `useLockFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLockFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lockFileMutation, { data, loading, error }] = useLockFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLockFileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LockFileMutation,
    LockFileMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    LockFileMutation,
    LockFileMutationVariables
  >(LockFileDocument, baseOptions)
}
export type LockFileMutationHookResult = ReturnType<typeof useLockFileMutation>
export type LockFileMutationResult = ApolloReactCommon.MutationResult<
  LockFileMutation
>
export type LockFileMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LockFileMutation,
  LockFileMutationVariables
>
export const UnlockFileDocument = gql`
  mutation unlockFile($id: ID!) {
    unlockFile(id: $id) {
      ...DocumentTableRow
    }
  }
  ${DocumentTableRowFragmentDoc}
`
export type UnlockFileMutationFn = ApolloReactCommon.MutationFunction<
  UnlockFileMutation,
  UnlockFileMutationVariables
>

/**
 * __useUnlockFileMutation__
 *
 * To run a mutation, you first call `useUnlockFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlockFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlockFileMutation, { data, loading, error }] = useUnlockFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnlockFileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UnlockFileMutation,
    UnlockFileMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UnlockFileMutation,
    UnlockFileMutationVariables
  >(UnlockFileDocument, baseOptions)
}
export type UnlockFileMutationHookResult = ReturnType<
  typeof useUnlockFileMutation
>
export type UnlockFileMutationResult = ApolloReactCommon.MutationResult<
  UnlockFileMutation
>
export type UnlockFileMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UnlockFileMutation,
  UnlockFileMutationVariables
>
export const OpenDocumentDocument = gql`
  mutation openDocument($id: ID!) {
    openDocument(id: $id) {
      ...DocumentTableRow
    }
  }
  ${DocumentTableRowFragmentDoc}
`
export type OpenDocumentMutationFn = ApolloReactCommon.MutationFunction<
  OpenDocumentMutation,
  OpenDocumentMutationVariables
>

/**
 * __useOpenDocumentMutation__
 *
 * To run a mutation, you first call `useOpenDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openDocumentMutation, { data, loading, error }] = useOpenDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOpenDocumentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OpenDocumentMutation,
    OpenDocumentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    OpenDocumentMutation,
    OpenDocumentMutationVariables
  >(OpenDocumentDocument, baseOptions)
}
export type OpenDocumentMutationHookResult = ReturnType<
  typeof useOpenDocumentMutation
>
export type OpenDocumentMutationResult = ApolloReactCommon.MutationResult<
  OpenDocumentMutation
>
export type OpenDocumentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  OpenDocumentMutation,
  OpenDocumentMutationVariables
>
export const EditDocumentDocument = gql`
  mutation editDocument($id: ID!) {
    editDocument(id: $id) {
      ...DocumentTableRow
    }
  }
  ${DocumentTableRowFragmentDoc}
`
export type EditDocumentMutationFn = ApolloReactCommon.MutationFunction<
  EditDocumentMutation,
  EditDocumentMutationVariables
>

/**
 * __useEditDocumentMutation__
 *
 * To run a mutation, you first call `useEditDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDocumentMutation, { data, loading, error }] = useEditDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditDocumentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    EditDocumentMutation,
    EditDocumentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    EditDocumentMutation,
    EditDocumentMutationVariables
  >(EditDocumentDocument, baseOptions)
}
export type EditDocumentMutationHookResult = ReturnType<
  typeof useEditDocumentMutation
>
export type EditDocumentMutationResult = ApolloReactCommon.MutationResult<
  EditDocumentMutation
>
export type EditDocumentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  EditDocumentMutation,
  EditDocumentMutationVariables
>
export const CreateUploadUrlDocument = gql`
  mutation createUploadUrl($name: String!, $mimeType: String!) {
    createUploadUrl(name: $name, mimeType: $mimeType) {
      url
      signedUrl
    }
  }
`
export type CreateUploadUrlMutationFn = ApolloReactCommon.MutationFunction<
  CreateUploadUrlMutation,
  CreateUploadUrlMutationVariables
>

/**
 * __useCreateUploadUrlMutation__
 *
 * To run a mutation, you first call `useCreateUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUploadUrlMutation, { data, loading, error }] = useCreateUploadUrlMutation({
 *   variables: {
 *      name: // value for 'name'
 *      mimeType: // value for 'mimeType'
 *   },
 * });
 */
export function useCreateUploadUrlMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateUploadUrlMutation,
    CreateUploadUrlMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateUploadUrlMutation,
    CreateUploadUrlMutationVariables
  >(CreateUploadUrlDocument, baseOptions)
}
export type CreateUploadUrlMutationHookResult = ReturnType<
  typeof useCreateUploadUrlMutation
>
export type CreateUploadUrlMutationResult = ApolloReactCommon.MutationResult<
  CreateUploadUrlMutation
>
export type CreateUploadUrlMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateUploadUrlMutation,
  CreateUploadUrlMutationVariables
>
export const UploadFileDocument = gql`
  mutation uploadFile(
    $file: Upload!
    $mimeType: String!
    $size: String!
    $name: String!
    $folderId: ID!
  ) {
    createFile(
      file: $file
      mimeType: $mimeType
      size: $size
      name: $name
      parentId: $folderId
    ) {
      ...DocumentTableRow
    }
  }
  ${DocumentTableRowFragmentDoc}
`
export type UploadFileMutationFn = ApolloReactCommon.MutationFunction<
  UploadFileMutation,
  UploadFileMutationVariables
>

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *      mimeType: // value for 'mimeType'
 *      size: // value for 'size'
 *      name: // value for 'name'
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function useUploadFileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UploadFileMutation,
    UploadFileMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UploadFileMutation,
    UploadFileMutationVariables
  >(UploadFileDocument, baseOptions)
}
export type UploadFileMutationHookResult = ReturnType<
  typeof useUploadFileMutation
>
export type UploadFileMutationResult = ApolloReactCommon.MutationResult<
  UploadFileMutation
>
export type UploadFileMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UploadFileMutation,
  UploadFileMutationVariables
>
export const UpdateFileDocument = gql`
  mutation updateFile(
    $file: Upload!
    $mimeType: String!
    $size: String!
    $documentId: ID!
  ) {
    updateFile(
      file: $file
      mimeType: $mimeType
      size: $size
      documentId: $documentId
    ) {
      ...DocumentTableRow
    }
  }
  ${DocumentTableRowFragmentDoc}
`
export type UpdateFileMutationFn = ApolloReactCommon.MutationFunction<
  UpdateFileMutation,
  UpdateFileMutationVariables
>

/**
 * __useUpdateFileMutation__
 *
 * To run a mutation, you first call `useUpdateFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFileMutation, { data, loading, error }] = useUpdateFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *      mimeType: // value for 'mimeType'
 *      size: // value for 'size'
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useUpdateFileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateFileMutation,
    UpdateFileMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateFileMutation,
    UpdateFileMutationVariables
  >(UpdateFileDocument, baseOptions)
}
export type UpdateFileMutationHookResult = ReturnType<
  typeof useUpdateFileMutation
>
export type UpdateFileMutationResult = ApolloReactCommon.MutationResult<
  UpdateFileMutation
>
export type UpdateFileMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateFileMutation,
  UpdateFileMutationVariables
>
export const ChangeOwnerDocument = gql`
  mutation changeOwner($documentId: ID!, $userId: ID!) {
    setOwner(id: $documentId, ownerId: $userId) {
      ...DocumentTableRow
    }
  }
  ${DocumentTableRowFragmentDoc}
`
export type ChangeOwnerMutationFn = ApolloReactCommon.MutationFunction<
  ChangeOwnerMutation,
  ChangeOwnerMutationVariables
>

/**
 * __useChangeOwnerMutation__
 *
 * To run a mutation, you first call `useChangeOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeOwnerMutation, { data, loading, error }] = useChangeOwnerMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useChangeOwnerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ChangeOwnerMutation,
    ChangeOwnerMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ChangeOwnerMutation,
    ChangeOwnerMutationVariables
  >(ChangeOwnerDocument, baseOptions)
}
export type ChangeOwnerMutationHookResult = ReturnType<
  typeof useChangeOwnerMutation
>
export type ChangeOwnerMutationResult = ApolloReactCommon.MutationResult<
  ChangeOwnerMutation
>
export type ChangeOwnerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ChangeOwnerMutation,
  ChangeOwnerMutationVariables
>
export const SetTagsOnDocumentDocument = gql`
  mutation setTagsOnDocument($documentId: ID!, $tagIds: [ID!]) {
    setTags(documentId: $documentId, tagIds: $tagIds) {
      ...DocumentTableRow
    }
  }
  ${DocumentTableRowFragmentDoc}
`
export type SetTagsOnDocumentMutationFn = ApolloReactCommon.MutationFunction<
  SetTagsOnDocumentMutation,
  SetTagsOnDocumentMutationVariables
>

/**
 * __useSetTagsOnDocumentMutation__
 *
 * To run a mutation, you first call `useSetTagsOnDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTagsOnDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTagsOnDocumentMutation, { data, loading, error }] = useSetTagsOnDocumentMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useSetTagsOnDocumentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetTagsOnDocumentMutation,
    SetTagsOnDocumentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SetTagsOnDocumentMutation,
    SetTagsOnDocumentMutationVariables
  >(SetTagsOnDocumentDocument, baseOptions)
}
export type SetTagsOnDocumentMutationHookResult = ReturnType<
  typeof useSetTagsOnDocumentMutation
>
export type SetTagsOnDocumentMutationResult = ApolloReactCommon.MutationResult<
  SetTagsOnDocumentMutation
>
export type SetTagsOnDocumentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetTagsOnDocumentMutation,
  SetTagsOnDocumentMutationVariables
>
export const ModifyDocumentUserAccessesDocument = gql`
  mutation modifyDocumentUserAccesses(
    $documentId: ID!
    $readIds: [ID!]
    $writeIds: [ID!]
    $controlIds: [ID!]
    $recursive: Boolean
  ) {
    modifyDocumentAccesses(
      type: USER
      documentId: $documentId
      readIds: $readIds
      writeIds: $writeIds
      controlIds: $controlIds
      recursive: $recursive
    ) {
      id
    }
  }
`
export type ModifyDocumentUserAccessesMutationFn = ApolloReactCommon.MutationFunction<
  ModifyDocumentUserAccessesMutation,
  ModifyDocumentUserAccessesMutationVariables
>

/**
 * __useModifyDocumentUserAccessesMutation__
 *
 * To run a mutation, you first call `useModifyDocumentUserAccessesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyDocumentUserAccessesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyDocumentUserAccessesMutation, { data, loading, error }] = useModifyDocumentUserAccessesMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      readIds: // value for 'readIds'
 *      writeIds: // value for 'writeIds'
 *      controlIds: // value for 'controlIds'
 *      recursive: // value for 'recursive'
 *   },
 * });
 */
export function useModifyDocumentUserAccessesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ModifyDocumentUserAccessesMutation,
    ModifyDocumentUserAccessesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ModifyDocumentUserAccessesMutation,
    ModifyDocumentUserAccessesMutationVariables
  >(ModifyDocumentUserAccessesDocument, baseOptions)
}
export type ModifyDocumentUserAccessesMutationHookResult = ReturnType<
  typeof useModifyDocumentUserAccessesMutation
>
export type ModifyDocumentUserAccessesMutationResult = ApolloReactCommon.MutationResult<
  ModifyDocumentUserAccessesMutation
>
export type ModifyDocumentUserAccessesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ModifyDocumentUserAccessesMutation,
  ModifyDocumentUserAccessesMutationVariables
>
export const ModifyDocumentUserGroupAccessesDocument = gql`
  mutation modifyDocumentUserGroupAccesses(
    $documentId: ID!
    $readIds: [ID!]
    $writeIds: [ID!]
    $controlIds: [ID!]
    $recursive: Boolean
  ) {
    modifyDocumentAccesses(
      type: USER_GROUP
      documentId: $documentId
      readIds: $readIds
      writeIds: $writeIds
      controlIds: $controlIds
      recursive: $recursive
    ) {
      id
    }
  }
`
export type ModifyDocumentUserGroupAccessesMutationFn = ApolloReactCommon.MutationFunction<
  ModifyDocumentUserGroupAccessesMutation,
  ModifyDocumentUserGroupAccessesMutationVariables
>

/**
 * __useModifyDocumentUserGroupAccessesMutation__
 *
 * To run a mutation, you first call `useModifyDocumentUserGroupAccessesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyDocumentUserGroupAccessesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyDocumentUserGroupAccessesMutation, { data, loading, error }] = useModifyDocumentUserGroupAccessesMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      readIds: // value for 'readIds'
 *      writeIds: // value for 'writeIds'
 *      controlIds: // value for 'controlIds'
 *      recursive: // value for 'recursive'
 *   },
 * });
 */
export function useModifyDocumentUserGroupAccessesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ModifyDocumentUserGroupAccessesMutation,
    ModifyDocumentUserGroupAccessesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ModifyDocumentUserGroupAccessesMutation,
    ModifyDocumentUserGroupAccessesMutationVariables
  >(ModifyDocumentUserGroupAccessesDocument, baseOptions)
}
export type ModifyDocumentUserGroupAccessesMutationHookResult = ReturnType<
  typeof useModifyDocumentUserGroupAccessesMutation
>
export type ModifyDocumentUserGroupAccessesMutationResult = ApolloReactCommon.MutationResult<
  ModifyDocumentUserGroupAccessesMutation
>
export type ModifyDocumentUserGroupAccessesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ModifyDocumentUserGroupAccessesMutation,
  ModifyDocumentUserGroupAccessesMutationVariables
>
export const FolderDocument = gql`
  query folder($id: ID!, $isDeleted: Boolean) {
    listDocuments(folderId: $id, isDeleted: $isDeleted) {
      ...DocumentTableRow
    }
    breadcrumb(folderId: $id) {
      id
      name
      parent
    }
  }
  ${DocumentTableRowFragmentDoc}
`

/**
 * __useFolderQuery__
 *
 * To run a query within a React component, call `useFolderQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolderQuery({
 *   variables: {
 *      id: // value for 'id'
 *      isDeleted: // value for 'isDeleted'
 *   },
 * });
 */
export function useFolderQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FolderQuery,
    FolderQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<FolderQuery, FolderQueryVariables>(
    FolderDocument,
    baseOptions
  )
}
export function useFolderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FolderQuery,
    FolderQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<FolderQuery, FolderQueryVariables>(
    FolderDocument,
    baseOptions
  )
}
export type FolderQueryHookResult = ReturnType<typeof useFolderQuery>
export type FolderLazyQueryHookResult = ReturnType<typeof useFolderLazyQuery>
export type FolderQueryResult = ApolloReactCommon.QueryResult<
  FolderQuery,
  FolderQueryVariables
>
export const FindDocumentsDocument = gql`
  query findDocuments(
    $name: String
    $tagId: ID
    $isLocked: Boolean
    $page: Int
    $count: Int
  ) {
    findDocuments(
      name: $name
      tagId: $tagId
      isLocked: $isLocked
      page: $page
      count: $count
    ) {
      documents {
        ...DocumentTableRow
      }
      total
    }
  }
  ${DocumentTableRowFragmentDoc}
`

/**
 * __useFindDocumentsQuery__
 *
 * To run a query within a React component, call `useFindDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindDocumentsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      tagId: // value for 'tagId'
 *      isLocked: // value for 'isLocked'
 *      page: // value for 'page'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useFindDocumentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FindDocumentsQuery,
    FindDocumentsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    FindDocumentsQuery,
    FindDocumentsQueryVariables
  >(FindDocumentsDocument, baseOptions)
}
export function useFindDocumentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindDocumentsQuery,
    FindDocumentsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    FindDocumentsQuery,
    FindDocumentsQueryVariables
  >(FindDocumentsDocument, baseOptions)
}
export type FindDocumentsQueryHookResult = ReturnType<
  typeof useFindDocumentsQuery
>
export type FindDocumentsLazyQueryHookResult = ReturnType<
  typeof useFindDocumentsLazyQuery
>
export type FindDocumentsQueryResult = ApolloReactCommon.QueryResult<
  FindDocumentsQuery,
  FindDocumentsQueryVariables
>
export const DocumentDocument = gql`
  query document($id: ID!) {
    document(id: $id) {
      ...DocumentTableRow
    }
    access: myDocumentAccesses(documentId: $id) {
      read
      write
      control
      owner
    }
  }
  ${DocumentTableRowFragmentDoc}
`

/**
 * __useDocumentQuery__
 *
 * To run a query within a React component, call `useDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    DocumentQuery,
    DocumentQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<DocumentQuery, DocumentQueryVariables>(
    DocumentDocument,
    baseOptions
  )
}
export function useDocumentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DocumentQuery,
    DocumentQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<DocumentQuery, DocumentQueryVariables>(
    DocumentDocument,
    baseOptions
  )
}
export type DocumentQueryHookResult = ReturnType<typeof useDocumentQuery>
export type DocumentLazyQueryHookResult = ReturnType<
  typeof useDocumentLazyQuery
>
export type DocumentQueryResult = ApolloReactCommon.QueryResult<
  DocumentQuery,
  DocumentQueryVariables
>
export const DocumentAccessesDocument = gql`
  query documentAccesses($documentId: ID!) {
    documentAccesses(documentId: $documentId) {
      ...DocumentAccess
    }
  }
  ${DocumentAccessFragmentDoc}
`

/**
 * __useDocumentAccessesQuery__
 *
 * To run a query within a React component, call `useDocumentAccessesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentAccessesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentAccessesQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDocumentAccessesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    DocumentAccessesQuery,
    DocumentAccessesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    DocumentAccessesQuery,
    DocumentAccessesQueryVariables
  >(DocumentAccessesDocument, baseOptions)
}
export function useDocumentAccessesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DocumentAccessesQuery,
    DocumentAccessesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    DocumentAccessesQuery,
    DocumentAccessesQueryVariables
  >(DocumentAccessesDocument, baseOptions)
}
export type DocumentAccessesQueryHookResult = ReturnType<
  typeof useDocumentAccessesQuery
>
export type DocumentAccessesLazyQueryHookResult = ReturnType<
  typeof useDocumentAccessesLazyQuery
>
export type DocumentAccessesQueryResult = ApolloReactCommon.QueryResult<
  DocumentAccessesQuery,
  DocumentAccessesQueryVariables
>
export const DocumentHistoryDocument = gql`
  query documentHistory($documentId: ID!, $page: Int, $count: Int) {
    documentHistory(documentId: $documentId, page: $page, count: $count) {
      logs {
        ...DocumentHistory
      }
      total
    }
  }
  ${DocumentHistoryFragmentDoc}
`

/**
 * __useDocumentHistoryQuery__
 *
 * To run a query within a React component, call `useDocumentHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentHistoryQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      page: // value for 'page'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useDocumentHistoryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    DocumentHistoryQuery,
    DocumentHistoryQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    DocumentHistoryQuery,
    DocumentHistoryQueryVariables
  >(DocumentHistoryDocument, baseOptions)
}
export function useDocumentHistoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DocumentHistoryQuery,
    DocumentHistoryQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    DocumentHistoryQuery,
    DocumentHistoryQueryVariables
  >(DocumentHistoryDocument, baseOptions)
}
export type DocumentHistoryQueryHookResult = ReturnType<
  typeof useDocumentHistoryQuery
>
export type DocumentHistoryLazyQueryHookResult = ReturnType<
  typeof useDocumentHistoryLazyQuery
>
export type DocumentHistoryQueryResult = ApolloReactCommon.QueryResult<
  DocumentHistoryQuery,
  DocumentHistoryQueryVariables
>
export const ChangeTranslationDocument = gql`
  mutation changeTranslation(
    $language: Language!
    $key: String!
    $value: String!
  ) {
    setTranslation(language: $language, key: $key, value: $value) {
      id
    }
  }
`
export type ChangeTranslationMutationFn = ApolloReactCommon.MutationFunction<
  ChangeTranslationMutation,
  ChangeTranslationMutationVariables
>

/**
 * __useChangeTranslationMutation__
 *
 * To run a mutation, you first call `useChangeTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTranslationMutation, { data, loading, error }] = useChangeTranslationMutation({
 *   variables: {
 *      language: // value for 'language'
 *      key: // value for 'key'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useChangeTranslationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ChangeTranslationMutation,
    ChangeTranslationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ChangeTranslationMutation,
    ChangeTranslationMutationVariables
  >(ChangeTranslationDocument, baseOptions)
}
export type ChangeTranslationMutationHookResult = ReturnType<
  typeof useChangeTranslationMutation
>
export type ChangeTranslationMutationResult = ApolloReactCommon.MutationResult<
  ChangeTranslationMutation
>
export type ChangeTranslationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ChangeTranslationMutation,
  ChangeTranslationMutationVariables
>
export const LocalizationsDocument = gql`
  query localizations {
    localizations {
      ...Localization
    }
  }
  ${LocalizationFragmentDoc}
`

/**
 * __useLocalizationsQuery__
 *
 * To run a query within a React component, call `useLocalizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocalizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocalizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocalizationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LocalizationsQuery,
    LocalizationsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    LocalizationsQuery,
    LocalizationsQueryVariables
  >(LocalizationsDocument, baseOptions)
}
export function useLocalizationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LocalizationsQuery,
    LocalizationsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    LocalizationsQuery,
    LocalizationsQueryVariables
  >(LocalizationsDocument, baseOptions)
}
export type LocalizationsQueryHookResult = ReturnType<
  typeof useLocalizationsQuery
>
export type LocalizationsLazyQueryHookResult = ReturnType<
  typeof useLocalizationsLazyQuery
>
export type LocalizationsQueryResult = ApolloReactCommon.QueryResult<
  LocalizationsQuery,
  LocalizationsQueryVariables
>
export const LogsDocument = gql`
  query logs(
    $type: LogType!
    $operatorId: ID
    $createdBefore: DateTime
    $createdAfter: DateTime
    $userId: ID
    $userGroupId: ID
    $documentId: ID
    $page: Int
    $count: Int
  ) {
    logs(
      type: $type
      operatorId: $operatorId
      createdBefore: $createdBefore
      createdAfter: $createdAfter
      userId: $userId
      userGroupId: $userGroupId
      documentId: $documentId
      page: $page
      count: $count
    ) {
      logs {
        ...Log
      }
      total
    }
  }
  ${LogFragmentDoc}
`

/**
 * __useLogsQuery__
 *
 * To run a query within a React component, call `useLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogsQuery({
 *   variables: {
 *      type: // value for 'type'
 *      operatorId: // value for 'operatorId'
 *      createdBefore: // value for 'createdBefore'
 *      createdAfter: // value for 'createdAfter'
 *      userId: // value for 'userId'
 *      userGroupId: // value for 'userGroupId'
 *      documentId: // value for 'documentId'
 *      page: // value for 'page'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useLogsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LogsQuery, LogsQueryVariables>
) {
  return ApolloReactHooks.useQuery<LogsQuery, LogsQueryVariables>(
    LogsDocument,
    baseOptions
  )
}
export function useLogsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LogsQuery,
    LogsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<LogsQuery, LogsQueryVariables>(
    LogsDocument,
    baseOptions
  )
}
export type LogsQueryHookResult = ReturnType<typeof useLogsQuery>
export type LogsLazyQueryHookResult = ReturnType<typeof useLogsLazyQuery>
export type LogsQueryResult = ApolloReactCommon.QueryResult<
  LogsQuery,
  LogsQueryVariables
>
export const CreateTagDocument = gql`
  mutation createTag($documentId: ID, $name: String!, $color: TagColor!) {
    createTag(documentId: $documentId, name: $name, color: $color) {
      ...Tag
    }
  }
  ${TagFragmentDoc}
`
export type CreateTagMutationFn = ApolloReactCommon.MutationFunction<
  CreateTagMutation,
  CreateTagMutationVariables
>

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      name: // value for 'name'
 *      color: // value for 'color'
 *   },
 * });
 */
export function useCreateTagMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTagMutation,
    CreateTagMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateTagMutation,
    CreateTagMutationVariables
  >(CreateTagDocument, baseOptions)
}
export type CreateTagMutationHookResult = ReturnType<
  typeof useCreateTagMutation
>
export type CreateTagMutationResult = ApolloReactCommon.MutationResult<
  CreateTagMutation
>
export type CreateTagMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTagMutation,
  CreateTagMutationVariables
>
export const EditTagDocument = gql`
  mutation editTag($tagId: ID!, $name: String!, $color: TagColor!) {
    editTag(tagId: $tagId, name: $name, color: $color) {
      ...Tag
    }
  }
  ${TagFragmentDoc}
`
export type EditTagMutationFn = ApolloReactCommon.MutationFunction<
  EditTagMutation,
  EditTagMutationVariables
>

/**
 * __useEditTagMutation__
 *
 * To run a mutation, you first call `useEditTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTagMutation, { data, loading, error }] = useEditTagMutation({
 *   variables: {
 *      tagId: // value for 'tagId'
 *      name: // value for 'name'
 *      color: // value for 'color'
 *   },
 * });
 */
export function useEditTagMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    EditTagMutation,
    EditTagMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    EditTagMutation,
    EditTagMutationVariables
  >(EditTagDocument, baseOptions)
}
export type EditTagMutationHookResult = ReturnType<typeof useEditTagMutation>
export type EditTagMutationResult = ApolloReactCommon.MutationResult<
  EditTagMutation
>
export type EditTagMutationOptions = ApolloReactCommon.BaseMutationOptions<
  EditTagMutation,
  EditTagMutationVariables
>
export const DeleteTagDocument = gql`
  mutation deleteTag($id: ID!) {
    deleteTag(id: $id) {
      ...Tag
    }
  }
  ${TagFragmentDoc}
`
export type DeleteTagMutationFn = ApolloReactCommon.MutationFunction<
  DeleteTagMutation,
  DeleteTagMutationVariables
>

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTagMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTagMutation,
    DeleteTagMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteTagMutation,
    DeleteTagMutationVariables
  >(DeleteTagDocument, baseOptions)
}
export type DeleteTagMutationHookResult = ReturnType<
  typeof useDeleteTagMutation
>
export type DeleteTagMutationResult = ApolloReactCommon.MutationResult<
  DeleteTagMutation
>
export type DeleteTagMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteTagMutation,
  DeleteTagMutationVariables
>
export const OwnTagsDocument = gql`
  query ownTags {
    userTags {
      id
      name
      color
      user {
        name
      }
    }
  }
`

/**
 * __useOwnTagsQuery__
 *
 * To run a query within a React component, call `useOwnTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOwnTagsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OwnTagsQuery,
    OwnTagsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<OwnTagsQuery, OwnTagsQueryVariables>(
    OwnTagsDocument,
    baseOptions
  )
}
export function useOwnTagsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OwnTagsQuery,
    OwnTagsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<OwnTagsQuery, OwnTagsQueryVariables>(
    OwnTagsDocument,
    baseOptions
  )
}
export type OwnTagsQueryHookResult = ReturnType<typeof useOwnTagsQuery>
export type OwnTagsLazyQueryHookResult = ReturnType<typeof useOwnTagsLazyQuery>
export type OwnTagsQueryResult = ApolloReactCommon.QueryResult<
  OwnTagsQuery,
  OwnTagsQueryVariables
>
export const CreateUserGroupDocument = gql`
  mutation createUserGroup($name: String!) {
    createUserGroup(name: $name) {
      ...UserGroupTableRow
    }
  }
  ${UserGroupTableRowFragmentDoc}
`
export type CreateUserGroupMutationFn = ApolloReactCommon.MutationFunction<
  CreateUserGroupMutation,
  CreateUserGroupMutationVariables
>

/**
 * __useCreateUserGroupMutation__
 *
 * To run a mutation, you first call `useCreateUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserGroupMutation, { data, loading, error }] = useCreateUserGroupMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateUserGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateUserGroupMutation,
    CreateUserGroupMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateUserGroupMutation,
    CreateUserGroupMutationVariables
  >(CreateUserGroupDocument, baseOptions)
}
export type CreateUserGroupMutationHookResult = ReturnType<
  typeof useCreateUserGroupMutation
>
export type CreateUserGroupMutationResult = ApolloReactCommon.MutationResult<
  CreateUserGroupMutation
>
export type CreateUserGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateUserGroupMutation,
  CreateUserGroupMutationVariables
>
export const DeleteUserGroupDocument = gql`
  mutation deleteUserGroup($id: ID!) {
    deleteUserGroup(id: $id) {
      ...UserGroupTableRow
    }
  }
  ${UserGroupTableRowFragmentDoc}
`
export type DeleteUserGroupMutationFn = ApolloReactCommon.MutationFunction<
  DeleteUserGroupMutation,
  DeleteUserGroupMutationVariables
>

/**
 * __useDeleteUserGroupMutation__
 *
 * To run a mutation, you first call `useDeleteUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserGroupMutation, { data, loading, error }] = useDeleteUserGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteUserGroupMutation,
    DeleteUserGroupMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteUserGroupMutation,
    DeleteUserGroupMutationVariables
  >(DeleteUserGroupDocument, baseOptions)
}
export type DeleteUserGroupMutationHookResult = ReturnType<
  typeof useDeleteUserGroupMutation
>
export type DeleteUserGroupMutationResult = ApolloReactCommon.MutationResult<
  DeleteUserGroupMutation
>
export type DeleteUserGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteUserGroupMutation,
  DeleteUserGroupMutationVariables
>
export const UpdateUserGroupDocument = gql`
  mutation updateUserGroup($id: ID!, $name: String!) {
    updateUserGroup(id: $id, name: $name) {
      ...UserGroupTableRow
    }
  }
  ${UserGroupTableRowFragmentDoc}
`
export type UpdateUserGroupMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserGroupMutation,
  UpdateUserGroupMutationVariables
>

/**
 * __useUpdateUserGroupMutation__
 *
 * To run a mutation, you first call `useUpdateUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserGroupMutation, { data, loading, error }] = useUpdateUserGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateUserGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserGroupMutation,
    UpdateUserGroupMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateUserGroupMutation,
    UpdateUserGroupMutationVariables
  >(UpdateUserGroupDocument, baseOptions)
}
export type UpdateUserGroupMutationHookResult = ReturnType<
  typeof useUpdateUserGroupMutation
>
export type UpdateUserGroupMutationResult = ApolloReactCommon.MutationResult<
  UpdateUserGroupMutation
>
export type UpdateUserGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserGroupMutation,
  UpdateUserGroupMutationVariables
>
export const AddUsersToGroupDocument = gql`
  mutation addUsersToGroup($id: ID!, $userIds: [ID!]!) {
    addUsers(id: $id, userIds: $userIds) {
      ...UserGroup
    }
  }
  ${UserGroupFragmentDoc}
`
export type AddUsersToGroupMutationFn = ApolloReactCommon.MutationFunction<
  AddUsersToGroupMutation,
  AddUsersToGroupMutationVariables
>

/**
 * __useAddUsersToGroupMutation__
 *
 * To run a mutation, you first call `useAddUsersToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUsersToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUsersToGroupMutation, { data, loading, error }] = useAddUsersToGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useAddUsersToGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddUsersToGroupMutation,
    AddUsersToGroupMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddUsersToGroupMutation,
    AddUsersToGroupMutationVariables
  >(AddUsersToGroupDocument, baseOptions)
}
export type AddUsersToGroupMutationHookResult = ReturnType<
  typeof useAddUsersToGroupMutation
>
export type AddUsersToGroupMutationResult = ApolloReactCommon.MutationResult<
  AddUsersToGroupMutation
>
export type AddUsersToGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddUsersToGroupMutation,
  AddUsersToGroupMutationVariables
>
export const RemoveUsersFromGroupDocument = gql`
  mutation removeUsersFromGroup($id: ID!, $userIds: [ID!]!) {
    removeUsers(id: $id, userIds: $userIds) {
      ...UserGroup
    }
  }
  ${UserGroupFragmentDoc}
`
export type RemoveUsersFromGroupMutationFn = ApolloReactCommon.MutationFunction<
  RemoveUsersFromGroupMutation,
  RemoveUsersFromGroupMutationVariables
>

/**
 * __useRemoveUsersFromGroupMutation__
 *
 * To run a mutation, you first call `useRemoveUsersFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUsersFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUsersFromGroupMutation, { data, loading, error }] = useRemoveUsersFromGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useRemoveUsersFromGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveUsersFromGroupMutation,
    RemoveUsersFromGroupMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RemoveUsersFromGroupMutation,
    RemoveUsersFromGroupMutationVariables
  >(RemoveUsersFromGroupDocument, baseOptions)
}
export type RemoveUsersFromGroupMutationHookResult = ReturnType<
  typeof useRemoveUsersFromGroupMutation
>
export type RemoveUsersFromGroupMutationResult = ApolloReactCommon.MutationResult<
  RemoveUsersFromGroupMutation
>
export type RemoveUsersFromGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveUsersFromGroupMutation,
  RemoveUsersFromGroupMutationVariables
>
export const UserGroupsDocument = gql`
  query userGroups {
    userGroups {
      ...UserGroupTableRow
    }
  }
  ${UserGroupTableRowFragmentDoc}
`

/**
 * __useUserGroupsQuery__
 *
 * To run a query within a React component, call `useUserGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserGroupsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserGroupsQuery,
    UserGroupsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<UserGroupsQuery, UserGroupsQueryVariables>(
    UserGroupsDocument,
    baseOptions
  )
}
export function useUserGroupsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserGroupsQuery,
    UserGroupsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    UserGroupsQuery,
    UserGroupsQueryVariables
  >(UserGroupsDocument, baseOptions)
}
export type UserGroupsQueryHookResult = ReturnType<typeof useUserGroupsQuery>
export type UserGroupsLazyQueryHookResult = ReturnType<
  typeof useUserGroupsLazyQuery
>
export type UserGroupsQueryResult = ApolloReactCommon.QueryResult<
  UserGroupsQuery,
  UserGroupsQueryVariables
>
export const UserGroupDocument = gql`
  query userGroup($id: ID!) {
    userGroup(where: { id: $id }) {
      ...UserGroup
    }
  }
  ${UserGroupFragmentDoc}
`

/**
 * __useUserGroupQuery__
 *
 * To run a query within a React component, call `useUserGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserGroupQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserGroupQuery,
    UserGroupQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<UserGroupQuery, UserGroupQueryVariables>(
    UserGroupDocument,
    baseOptions
  )
}
export function useUserGroupLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserGroupQuery,
    UserGroupQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<UserGroupQuery, UserGroupQueryVariables>(
    UserGroupDocument,
    baseOptions
  )
}
export type UserGroupQueryHookResult = ReturnType<typeof useUserGroupQuery>
export type UserGroupLazyQueryHookResult = ReturnType<
  typeof useUserGroupLazyQuery
>
export type UserGroupQueryResult = ApolloReactCommon.QueryResult<
  UserGroupQuery,
  UserGroupQueryVariables
>
export const LoginDocument = gql`
  mutation login($name: String!, $password: String!) {
    login(name: $name, password: $password) {
      token
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`
export type LoginMutationFn = ApolloReactCommon.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      name: // value for 'name'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    baseOptions
  )
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>
export type LoginMutationResult = ApolloReactCommon.MutationResult<
  LoginMutation
>
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>
export const CreateUserDocument = gql`
  mutation createUser($name: String!, $email: String, $password: String!) {
    createUser(name: $name, email: $email, password: $password) {
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`
export type CreateUserMutationFn = ApolloReactCommon.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateUserMutation,
    CreateUserMutationVariables
  >(CreateUserDocument, baseOptions)
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>
export type CreateUserMutationResult = ApolloReactCommon.MutationResult<
  CreateUserMutation
>
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>
export const UpdateUserDocument = gql`
  mutation updateUser(
    $id: ID!
    $email: String
    $name: String
    $isDeleted: Boolean
    $isAdmin: Boolean
    $isLocked: Boolean
  ) {
    setUser(
      userId: $id
      email: $email
      isDeleted: $isDeleted
      isLocked: $isLocked
      name: $name
      isAdmin: $isAdmin
    ) {
      ...UserTableRow
    }
  }
  ${UserTableRowFragmentDoc}
`
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      isDeleted: // value for 'isDeleted'
 *      isAdmin: // value for 'isAdmin'
 *      isLocked: // value for 'isLocked'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >(UpdateUserDocument, baseOptions)
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<
  UpdateUserMutation
>
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>
export const SetLanguageDocument = gql`
  mutation setLanguage($language: Language!) {
    setLanguage(language: $language) {
      ...User
    }
  }
  ${UserFragmentDoc}
`
export type SetLanguageMutationFn = ApolloReactCommon.MutationFunction<
  SetLanguageMutation,
  SetLanguageMutationVariables
>

/**
 * __useSetLanguageMutation__
 *
 * To run a mutation, you first call `useSetLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLanguageMutation, { data, loading, error }] = useSetLanguageMutation({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useSetLanguageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetLanguageMutation,
    SetLanguageMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SetLanguageMutation,
    SetLanguageMutationVariables
  >(SetLanguageDocument, baseOptions)
}
export type SetLanguageMutationHookResult = ReturnType<
  typeof useSetLanguageMutation
>
export type SetLanguageMutationResult = ApolloReactCommon.MutationResult<
  SetLanguageMutation
>
export type SetLanguageMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetLanguageMutation,
  SetLanguageMutationVariables
>
export const DeleteUserDocument = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(userId: $id) {
      ...User
    }
  }
  ${UserFragmentDoc}
`
export type DeleteUserMutationFn = ApolloReactCommon.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >(DeleteUserDocument, baseOptions)
}
export type DeleteUserMutationHookResult = ReturnType<
  typeof useDeleteUserMutation
>
export type DeleteUserMutationResult = ApolloReactCommon.MutationResult<
  DeleteUserMutation
>
export type DeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>
export const UpdatePasswordDocument = gql`
  mutation updatePassword($id: ID!, $password: String!) {
    updatePassword(userId: $id, password: $password) {
      ...User
    }
  }
  ${UserFragmentDoc}
`
export type UpdatePasswordMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables
>

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdatePasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePasswordMutation,
    UpdatePasswordMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdatePasswordMutation,
    UpdatePasswordMutationVariables
  >(UpdatePasswordDocument, baseOptions)
}
export type UpdatePasswordMutationHookResult = ReturnType<
  typeof useUpdatePasswordMutation
>
export type UpdatePasswordMutationResult = ApolloReactCommon.MutationResult<
  UpdatePasswordMutation
>
export type UpdatePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables
>
export const RecordLogoutDocument = gql`
  mutation recordLogout {
    recordLogout
  }
`
export type RecordLogoutMutationFn = ApolloReactCommon.MutationFunction<
  RecordLogoutMutation,
  RecordLogoutMutationVariables
>

/**
 * __useRecordLogoutMutation__
 *
 * To run a mutation, you first call `useRecordLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordLogoutMutation, { data, loading, error }] = useRecordLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useRecordLogoutMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RecordLogoutMutation,
    RecordLogoutMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RecordLogoutMutation,
    RecordLogoutMutationVariables
  >(RecordLogoutDocument, baseOptions)
}
export type RecordLogoutMutationHookResult = ReturnType<
  typeof useRecordLogoutMutation
>
export type RecordLogoutMutationResult = ApolloReactCommon.MutationResult<
  RecordLogoutMutation
>
export type RecordLogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RecordLogoutMutation,
  RecordLogoutMutationVariables
>
export const UsersDocument = gql`
  query users($where: UserWhereInput) {
    users(where: $where) {
      ...UserTableRow
    }
  }
  ${UserTableRowFragmentDoc}
`

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UsersQuery,
    UsersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    baseOptions
  )
}
export function useUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UsersQuery,
    UsersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    baseOptions
  )
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>
export type UsersQueryResult = ApolloReactCommon.QueryResult<
  UsersQuery,
  UsersQueryVariables
>
export const UserDocument = gql`
  query user($id: ID!) {
    user(where: { id: $id }) {
      ...User
    }
  }
  ${UserFragmentDoc}
`

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>
) {
  return ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    baseOptions
  )
}
export function useUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserQuery,
    UserQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    baseOptions
  )
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>
export type UserQueryResult = ApolloReactCommon.QueryResult<
  UserQuery,
  UserQueryVariables
>
