import { TagColor } from 'generated/graphql'
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic'

type ValidColor = Exclude<TagColor, TagColor.Favorite>

const mapping: Record<ValidColor, SemanticCOLORS> = {
  [TagColor.Black]: 'black',
  [TagColor.Red]: 'red',
  [TagColor.Yellow]: 'yellow',
  [TagColor.Orange]: 'orange',
  [TagColor.Olive]: 'olive',
  [TagColor.Green]: 'green',
  [TagColor.Teal]: 'teal',
  [TagColor.Blue]: 'blue',
  [TagColor.Violet]: 'violet',
  [TagColor.Purple]: 'purple',
  [TagColor.Pink]: 'pink',
  [TagColor.Brown]: 'brown',
  [TagColor.Grey]: 'grey',
}

export default function tagColorToSemanticColors(
  color: ValidColor
): SemanticCOLORS {
  return mapping[color]
}
