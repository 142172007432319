import React from 'react'
import { Container, Form, Input } from 'semantic-ui-react'
import {
  useMoveDocumentMutation,
  FolderDocument,
  useDocumentQuery,
} from 'generated/graphql'
import { hideModal } from 'store/modalStore'
import useTranslate from 'hooks/useTranslate'
import Error from 'components/common/Error'
import { Controller, useForm } from 'react-hook-form'
import { useStore } from 'laco-react'
import DocumentStore, { LacoDocument } from 'store/documentStore'

export default function MoveDocument() {
  const translate = useTranslate()
  const [moveDocument, { error, loading }] = useMoveDocumentMutation()
  const { handleSubmit, control, formState } = useForm({
    mode: 'onChange',
  })
  const { currentFolder, selectedDocumentInFolder }: LacoDocument = useStore(
    DocumentStore
  )
  const selected = selectedDocumentInFolder[currentFolder]
  const { data } = useDocumentQuery({
    variables: { id: selected as string },
  })

  async function onSubmit({ id }: any) {
    try {
      await moveDocument({
        variables: {
          parentId: id,
          documentId: selected as string,
        },
        refetchQueries: () => [
          {
            query: FolderDocument,
            variables: { id: data?.document?.parent?.id as string },
          },
        ],
      })

      hideModal()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)} loading={loading}>
        <Form.Field>
          <Controller
            as={<Input placeholder={translate('destination-folder-id')} />}
            rules={{
              required: true,
            }}
            name="id"
            type="number"
            control={control}
            defaultValue=""
            autoFocuss
          />
        </Form.Field>

        <Form.Button
          color="blue"
          disabled={!formState.isValid}
          fluid
          size="big"
        >
          {translate('move-document')}
        </Form.Button>
      </Form>
      <Error error={error}></Error>
    </Container>
  )
}
