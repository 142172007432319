import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useStore } from 'laco-react'
import AuthStore, { LacoAuth } from 'store/authStore'
import LoginForm from 'components/LoginForm'
import { ROUTES } from 'components/Routes/routes.const'
import UsersLayout from 'components/Layout/UsersLayout'
import UserGroupsLayout from 'components/Layout/UserGroupsLayout'
import UsersInGroupLayout from 'components/Layout/UsersInGroupLayout'
import withGroupIdFromParams from 'hoc/withGroupIdFromParams'
import withCurrentFolderFromParams from 'hoc/withParentFolderFromParams'
import DocumentsLayout from 'components/Layout/DocumentsLayout'
import withFolderIdSetInLaco from 'hoc/withFolderIdSetInLaco'
import LocalizationLayout from 'components/Layout/LocalizationLayout'
import LogsLayout from 'components/Layout/LogsLayout'

const WrappedUsersInGroupLayout = withGroupIdFromParams(UsersInGroupLayout)
const WrappedFolderLayout = withCurrentFolderFromParams(
  withFolderIdSetInLaco(DocumentsLayout)
)

export default function Routes() {
  const authState = useStore(AuthStore) as LacoAuth
  return (
    <Switch>
      {authState.loggedIn ? (
        <>
          <Route path={`${ROUTES.DOCUMENTS}/:folderId`}>
            <WrappedFolderLayout />
          </Route>
          <Route path={ROUTES.USERS}>
            <UsersLayout></UsersLayout>
          </Route>
          <Route path={ROUTES.USERGROUPS}>
            <UserGroupsLayout></UserGroupsLayout>
          </Route>
          <Route path={`${ROUTES.USERGROUP}/:groupId`}>
            <WrappedUsersInGroupLayout />
          </Route>
          <Route path={ROUTES.LOCALIZATION}>
            <LocalizationLayout></LocalizationLayout>
          </Route>
          <Route path={ROUTES.LOGS}>
            <LogsLayout></LogsLayout>
          </Route>
          <Redirect to={ROUTES.HOME}></Redirect>
        </>
      ) : (
        <>
          <Route path={ROUTES.LOGIN}>
            <LoginForm />
          </Route>
          <Redirect to={ROUTES.LOGIN}></Redirect>
        </>
      )}
    </Switch>
  )
}
