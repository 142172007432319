import { Store } from 'laco'

export enum ModalType {
  // ***************** USERS *****************
  USER_ADD,
  USER_EDIT,
  USER_SET_ADMIN,
  USER_DELETE,
  USER_RESTORE,
  USER_LOCK,
  USER_UNLOCK,
  USER_RESET_PASSWORD,

  // ***************** USERGROUPS *****************
  USERGROUP_ADD,
  USERGROUP_EDIT,
  USERGROUP_ADD_USERS,
  USERGROUP_REMOVE_USER,
  USERGROUP_DELETE,
  USERGROUP_RESTORE,

  // ***************** PROFILE *****************
  USER_SETTINGS,

  // ***************** DOCUMENT *****************
  FOLDER_ADD,
  DOCUMENT_DELETE,
  DOCUMENT_RESTORE,
  DOCUMENT_RENAME,
  DOCUMENT_LOCK,
  DOCUMENT_UNLOCK,
  DOCUMENT_COPY,
  DOCUMENT_MOVE,
  DOCUMENT_JUMP_TO,
  DOCUMENT_CHANGE_USERS_PERMISSIONS,
  DOCUMENT_CHANGE_USERS_PERMISSIONS_RECURSIVE,
  DOCUMENT_CHANGE_USERGROUPS_PERMISSIONS,
  DOCUMENT_CHANGE_USERGROUPS_PERMISSIONS_RECURSIVE,
  DOCUMENT_CHANGE_OWNER,
  DOCUMENT_EDIT_TAGS,
  DOCUMENT_HISTORY,
  DOCUMENT_FULL_HISTORY,

  FIND_DOCUMENT,
}

export type LacoModal = {
  type: ModalType | null
}

const initialState: LacoModal = {
  type: null,
}

const ModalStore = new Store(initialState, 'ModalStore')

export default ModalStore

export function openModal(type: ModalType, props?: any) {
  ModalStore.set(() => ({
    type,
    ...props,
  }))
}

export const hideModal = () =>
  ModalStore.replace(() => ({
    ...initialState,
  }))
