import React from 'react'
import { useLockFileMutation } from 'generated/graphql'
import { useStore } from 'laco-react'
import useTranslate from 'hooks/useTranslate'
import { Button } from 'semantic-ui-react'
import { hideModal } from 'store/modalStore'
import DocumentStore, { LacoDocument } from 'store/documentStore'

export default function LockDocumentButton() {
  const [lockDocument] = useLockFileMutation()
  const { currentFolder, selectedDocumentInFolder }: LacoDocument = useStore(
    DocumentStore
  )
  const selected = selectedDocumentInFolder[currentFolder]
  const translate = useTranslate()

  return (
    <Button
      color="red"
      onClick={() => {
        lockDocument({
          variables: { id: selected as string },
        })
        hideModal()
      }}
    >
      {translate('lock-document')}
    </Button>
  )
}
