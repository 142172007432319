import React from 'react'

import { Loader, Menu } from 'semantic-ui-react'
import ConditionalMenuItem from 'components/common/menu/ConditionalMenuItem'
import useDocumentActions from 'hooks/useDocumentActions'

function DocumentsSideBar() {
  const { loading, actions } = useDocumentActions()

  if (loading) {
    return <Loader></Loader>
  }

  return (
    <Menu vertical fluid secondary>
      {actions.map(({ id, show, action, ...rest }) => {
        return (
          <ConditionalMenuItem
            key={id}
            show={show}
            onClick={action}
            {...rest}
          ></ConditionalMenuItem>
        )
      })}
    </Menu>
  )
}

export default DocumentsSideBar
