import React from 'react'
import { useStore } from 'laco-react'

import DataTable, { IDataTableColumn } from 'react-data-table-component'

import {
  UserTableRowFragment,
  UserGroupTableRowFragment,
  useUserGroupsQuery,
} from 'generated/graphql'
import { createBoolCellWithSelector } from 'components/common/table/BoolCell'
import UserGroupsStore, {
  LacoUserGroups,
  selectUserGroup,
} from 'store/userGroupsStore'
import { ModalType, openModal } from 'store/modalStore'
import { useHistory } from 'react-router-dom'
import { ROUTES } from 'components/Routes'
import useTranslate from 'hooks/useTranslate'
import { pollInterval } from 'constants/pollInterval'

const userGroupColumns: (
  translate: Function
) => IDataTableColumn<UserGroupTableRowFragment>[] = (translate) => [
  { name: translate('id'), selector: 'id', grow: 3, hide: 'lg' },
  { name: translate('name'), selector: 'name', sortable: true, grow: 3 },
  {
    name: translate('isDeleted'),
    selector: 'isDeleted',
    sortable: true,
    format: createBoolCellWithSelector('isDeleted', (row) => {
      selectUserGroup(row.id)
      if (row.isDeleted) {
        openModal(ModalType.USERGROUP_RESTORE)
      } else {
        openModal(ModalType.USERGROUP_DELETE)
      }
    }),
    grow: 1,
  },
]

export default function UserGroupsTable() {
  const translate = useTranslate()
  const { data, loading } = useUserGroupsQuery({ pollInterval: pollInterval })
  const history = useHistory()
  const userGroupsState: LacoUserGroups = useStore(UserGroupsStore)

  return (
    <DataTable
      data={data?.userGroups.map((x) => x) as UserTableRowFragment[]}
      columns={userGroupColumns(translate)}
      progressPending={loading}
      // progressComponent={Loader}
      noHeader
      highlightOnHover
      striped
      onRowClicked={(row) => {
        if (userGroupsState.selected === row.id) {
          selectUserGroup(null)
        } else {
          selectUserGroup(row.id)
        }
      }}
      onRowDoubleClicked={(row) => {
        history.push(`${ROUTES.USERGROUP}/${row.id}`)
      }}
      selectableRowsHighlight
      selectableRowSelected={(row) => row.id === userGroupsState.selected}
    />
  )
}
