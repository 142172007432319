import React from 'react'
import { useUpdateUserMutation, useUserQuery } from 'generated/graphql'
import { useStore } from 'laco-react'
import UsersStore, { LacoUsers } from 'store/usersStore'
import useTranslate from 'hooks/useTranslate'
import { Button } from 'semantic-ui-react'
import { hideModal } from 'store/modalStore'

type Props = {
  isDelete: boolean
}

export default function SetAdminUserButton() {
  const [setAdmin] = useUpdateUserMutation()
  const { selected }: LacoUsers = useStore(UsersStore)
  const translate = useTranslate()
  const { data } = useUserQuery({
    variables: { id: selected as string },
  })

  return (
    <Button
      color={data?.user?.isAdmin ? 'red' : 'green'}
      onClick={() => {
        setAdmin({
          variables: { id: selected as string, isAdmin: !data?.user?.isAdmin },
        })
        hideModal()
      }}
    >
      {translate(data?.user?.isAdmin ? 'unset-admin-user' : 'set-admin-user')}
    </Button>
  )
}
