import React from 'react'
import { Menu, Icon, Header } from 'semantic-ui-react'
import { useHistory, Link } from 'react-router-dom'
import {
  SemanticWIDTHSNUMBER,
  SemanticICONS,
} from 'semantic-ui-react/dist/commonjs/generic'

export type MenuItem = {
  text: string
  icon?: SemanticICONS
  action?: (e: React.MouseEvent) => void
  to?: string
  disabled?: boolean
}

interface Props {
  title?: string
  parentId?: string
  buttons: MenuItem[]
  backButton?: Boolean
  backButtonComponent?: React.ReactNode
}

function calculateItemCount({
  title,
  buttons,
  backButton,
}: Props): SemanticWIDTHSNUMBER {
  let count = buttons.length
  if (title) {
    count += 1
  }
  if (backButton) {
    count += 1
  }

  return count as SemanticWIDTHSNUMBER
}

export default function HeaderBar({
  title,
  buttons,
  backButton,
  backButtonComponent,
  parentId,
}: Props) {
  const itemCount = calculateItemCount({ title, buttons, backButton })
  const history = useHistory()

  return (
    <Menu icon="labeled" fixed="top" fluid widths={itemCount} id="header">
      {backButton &&
        (backButtonComponent ? (
          backButtonComponent
        ) : (
          <Menu.Item
            onClick={() => {
              history.goBack()
            }}
            icon="arrow left"
          ></Menu.Item>
        ))}

      {title && (
        <Menu.Item name={title}>
          <Header>{title}</Header>
          <div>{parentId}</div>
        </Menu.Item>
      )}

      {buttons.map((button) =>
        button.to ? (
          <Menu.Item
            as={Link}
            to={button.to}
            key={button.text}
            name={button.text}
            disabled={!!button.disabled}
          >
            {button.icon && <Icon name={button.icon} />}
            {button.text}
          </Menu.Item>
        ) : (
          <Menu.Item
            key={button.text}
            name={button.text}
            onClick={(e) => button.action && button.action(e)}
            disabled={!!button.disabled}
          >
            {button.icon && <Icon name={button.icon} />}
            {button.text}
          </Menu.Item>
        )
      )}
    </Menu>
  )
}
